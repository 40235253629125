import { ActionWithPayload } from 'types/global';
import {
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_GLOBAL_SEARCH_FAIL,
  RESET_GLOBAL_SEARCH_STATUS
} from './constants';
import { GlobalSearchResponse } from './types';

export function getGlobalSearchAction(query: string): ActionWithPayload<{ query: string }> {
  return {
    type: GET_GLOBAL_SEARCH,
    payload: {
      query
    }
  };
}

export function getGlobalSearchSuccessAction(globalSearch: GlobalSearchResponse) {
  return {
    type: GET_GLOBAL_SEARCH_SUCCESS,
    payload: {
      globalSearch
    }
  };
}

export function getGlobalSearchErrorAction(error: Error) {
  return {
    type: GET_GLOBAL_SEARCH_FAIL,
    payload: {
      error
    }
  };
}

export function resetGlobalSearchStatusAction() {
  return {
    type: RESET_GLOBAL_SEARCH_STATUS
  };
}
