import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import styled from 'styled-components';
import './style.less';

function MenuItem({
  id,
  name,
  path,
  icon: Icon,
  isActive,
  onClick,
  isOpen,
  foldedName,
  disabled
}) {
  const buttonClassNames = classnames({
    MenuItem: true,
    active: isActive,
    folded: false
  });

  const textClassNames = classnames({
    MenuItem__name: true,
    'MenuItem__name--disabled': disabled
  });
  return (
    <button
      key={id + name}
      type="button"
      onClick={() => onClick({ id, path })}
      className={buttonClassNames}
      disabled={disabled}
      data-testid={`Sidebar-button-${id}`}
    >
      {Icon && (
        <div className="Icon">
          <Icon />
        </div>
      )}
      <span className={textClassNames}>
        {!foldedName || isOpen ? name : foldedName}
      </span>
    </button>
  );
}

MenuItem.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  foldedName: PropTypes.string,
  icon: PropTypes.any,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default React.memo(MenuItem);
