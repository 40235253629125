import { ApiResponse, create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import paramsSerializer from 'utils/paramsSerializer';
import { ServerResponseWithError } from 'types';
import { Products } from './types';
import { APP_CONFIG } from '../../config';
import { getProductsActionPayloadType } from './slice/types';

const productsApi = create({
  baseURL: APP_CONFIG.baseApiAdminUrl,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getProductsApi = async (productCategory: getProductsActionPayloadType) => {
  const resource = productCategory
    ? `/products/with-subscription?category=${productCategory}`
    : `/products/with-subscription`;

  const response: ApiResponse<Products[], ServerResponseWithError> = await productsApi.get(resource);

  return response.data;
};

productsApi.addAsyncRequestTransform(requestTransform);
productsApi.addAsyncResponseTransform(responseTransform);
