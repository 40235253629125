/*
 *
 * Settings constants
 *
 */

export const CONTAINER_KEY = 'settings';

export const GET_SETTINGS = 'app/settings/GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'app/settings/GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'app/settings/GET_SETTINGS_FAIL';
export const RESET_SETTINGS_STATUS = 'app/settings/RESET_SETTINGS_STATUS';

export enum SettingsFormNames {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  Confirmation = 'confirmation'
}

export const PATCH_PASSWORD = 'app/settings/PATCH_PASSWORD';
export const PATCH_PASSWORD_SUCCESS = 'app/settings/PATCH_PASSWORD_SUCCESS';
export const PATCH_PASSWORD_FAIL = 'app/settings/PATCH_PASSWORD_FAIL';
export const RESET_PATCH_PASSWORD_STATUS = 'app/settings/RESET_PATCH_PASSWORD_STATUS';

export const UPDATE_BASE_ROLE = 'app/settings/UPDATE_BASE_ROLE';
export const UPDATE_BASE_ROLE_SUCCESS = 'app/settings/UPDATE_BASE_ROLE_SUCCESS';
export const UPDATE_BASE_ROLE_FAIL = 'app/settings/UPDATE_BASE_ROLE_FAIL';
export const RESET_UPDATE_BASE_ROLE_STATUS = 'app/settings/RESET_UPDATE_BASE_ROLE_STATUS';
