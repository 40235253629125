import React from 'react';
import { takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { SHOW_TOAST_ACTION } from './constants';
import { SuccessToast } from './components/SuccessToast';

export function* showToast({ payload }) {
  const { type, callback } = payload;
  yield toast(<SuccessToast {...payload} />, {
    type,
    closeButton: !callback
  });
}

export default function* toastSaga() {
  yield takeLatest(SHOW_TOAST_ACTION, showToast);
}
