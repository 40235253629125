import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';
import { showToastAction } from '../Toast/actions';
import { getStatusesApi } from './api';
import { getStatusesAction, getStatusesErrorAction, getStatusesSuccessAction } from './actions';
import { GET_STATUSES } from './constants';

export function* getStatusesSaga({ payload = {} } = {}) {
  const { something } = payload;
  try {
    const result = yield call(getStatusesApi, something);
    yield put(getStatusesSuccessAction(result));
  } catch (err) {
    yield put(getStatusesErrorAction(err));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: err.message
      })
    );
  }
}

export default function* statusesSaga() {
  yield takeLatest(GET_STATUSES, getStatusesSaga);
}
