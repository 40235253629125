import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  RESET_SETTINGS_STATUS,
  PATCH_PASSWORD,
  PATCH_PASSWORD_SUCCESS,
  PATCH_PASSWORD_FAIL,
  RESET_PATCH_PASSWORD_STATUS,
  UPDATE_BASE_ROLE,
  UPDATE_BASE_ROLE_SUCCESS,
  UPDATE_BASE_ROLE_FAIL,
  RESET_UPDATE_BASE_ROLE_STATUS
} from './constants';

import { ChangePasswordArgs } from './types';

export function getSettingsAction(someId) {
  return {
    type: GET_SETTINGS,
    payload: {
      someId
    }
  };
}

export function getSettingsSuccessAction(settings) {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  };
}

export function getSettingsErrorAction(error) {
  return {
    type: GET_SETTINGS_FAIL,
    payload: {
      error
    }
  };
}

export function resetSettingsStatusAction() {
  return {
    type: RESET_SETTINGS_STATUS
  };
}

export function patchPasswordAction(data: ChangePasswordArgs) {
  return {
    type: PATCH_PASSWORD,
    payload: {
      ...data
    }
  };
}

export function patchPasswordSuccessAction(password) {
  return {
    type: PATCH_PASSWORD_SUCCESS,
    payload: {
      password
    }
  };
}

export function patchPasswordErrorAction(error) {
  return {
    type: PATCH_PASSWORD_FAIL,
    payload: {
      error
    }
  };
}

export function resetPatchPasswordStatusAction() {
  return {
    type: RESET_PATCH_PASSWORD_STATUS
  };
}

export function updateBaseRoleAction(baseRole) {
  return {
    type: UPDATE_BASE_ROLE,
    payload: {
      baseRole
    }
  };
}

export function updateBaseRoleSuccessAction(baseRole) {
  return {
    type: UPDATE_BASE_ROLE_SUCCESS,
    payload: {
      baseRole
    }
  };
}

export function updateBaseRoleErrorAction(error) {
  return {
    type: UPDATE_BASE_ROLE_FAIL,
    payload: {
      error
    }
  };
}

export function resetUpdateBaseRoleStatusAction() {
  return {
    type: RESET_UPDATE_BASE_ROLE_STATUS
  };
}
