/**
 *
 * PaymentEventsTab
 *
 */

import { Calendar } from 'app/components/Calendar';
import { SidePopover } from 'app/components/SidePopover';
import { WithLoader } from 'app/components/WithLoader';
import { getPaymentEventsActionPayloadType } from 'app/containers/EventsPage/slice/types';
import { MappedPaymentEvent, PaymentEvent } from 'app/containers/EventsPage/types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { StateItem } from 'types/redux/state';
import { Box, Text } from 'theme-ui';
import { addDays, addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { frontToBackFormatDate } from 'utils/dateUtils';
import { ORDER_PAYMENT_TYPES } from 'app/containers/OrderPage/constants';
import { PaymentsEvent } from '../PaymentsEvent';
import { EventPaymentsInfo } from '../EventPaymentsInfo';

import './style.less';

interface PaymentEventsTabProps {
  getPaymentEvents: (data: getPaymentEventsActionPayloadType) => void;
  paymentEventsState: StateItem<PaymentEvent[]>;
}

export function PaymentEventsTab(props: PaymentEventsTabProps) {
  const { getPaymentEvents, paymentEventsState } = props;

  const [selectedItem, setSelectedItem] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [initialDate, setInitialDate] = useState(new Date());

  useEffect(() => {
    const fromDate = addDays(startOfMonth(initialDate), -7);
    const tillDate = addDays(endOfMonth(initialDate), 7);
    getPaymentEvents({
      tillDate: frontToBackFormatDate(tillDate),
      fromDate: frontToBackFormatDate(fromDate)
    });
  }, [getPaymentEvents, initialDate]);

  const setPeriodOneMonthForward = () => {
    setInitialDate(addMonths(initialDate, 1));
  };

  const setPeriodOneMonthBack = () => {
    setInitialDate(addMonths(initialDate, -1));
  };

  const setTodayMonth = () => {
    setInitialDate(new Date());
  };

  const handleEventClick = ({ event }) => {
    setSelectedItem(event.extendedProps.eventData);
    setIsPopoverOpen(true);
  };

  const events = useMemo<MappedPaymentEvent[]>(() => {
    const paymentEvents = paymentEventsState?.data ?? [];
    return paymentEvents.map((event) => {
      const { date, paymentType, income } = event;

      const isPlane = paymentType === ORDER_PAYMENT_TYPES.PLAN;
      let order = '1';
      if (isPlane && !income) order = '2';
      if (!isPlane && income) order = '3';
      if (!isPlane && !income) order = '4';

      return {
        start: new Date(date),
        order,
        eventData: event
      };
    });
  }, [paymentEventsState]);

  return (
    <div data-testid="PaymentEventsTab" className="PaymentEventsTab">
      <Box mb={6} className="PaymentEventsTab__legend">
        <Text variant="text_size_14_bold">Легенда:</Text>
        <Box className="legend plan">Плановые</Box>
        <Box className="legend fact">Фактические</Box>
      </Box>
      <WithLoader states={[paymentEventsState]}>
        <Calendar
          customEventComponent={PaymentsEvent}
          events={events}
          onEventClick={handleEventClick}
          nextMonth={setPeriodOneMonthForward}
          prevMonth={setPeriodOneMonthBack}
          today={setTodayMonth}
          initialDate={initialDate}
        />

        {selectedItem && (
          <SidePopover isOpen={isPopoverOpen}>
            <EventPaymentsInfo onClose={() => setIsPopoverOpen(false)} paymentsInfo={selectedItem} />
          </SidePopover>
        )}
      </WithLoader>
    </div>
  );
}

export default memo(PaymentEventsTab);
