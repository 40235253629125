import React from 'react';

function IncomeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#7C8793"
        d="M14.974 8.515L10.6 3.994a.857.857 0 00-1.197 0L5.026 8.515a.834.834 0 101.198 1.161l2.943-3.042v8.782a.834.834 0 001.667 0V6.633l2.943 3.043a.827.827 0 00.598.254.834.834 0 00.6-1.415z"
      />
    </svg>
  );
}

export default IncomeIcon;
