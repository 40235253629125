import { Box, Text } from 'theme-ui';
import React, { FC } from 'react';
import './style.less';
import Button from 'app/components/Button';
import { MODALS_IDS } from 'app/containers/Modal/constants';
import { useModalIdState } from 'app/containers/Modal/useModalState';
import { PRODUCT_CATEGORY } from 'app/containers/ProductsPage/constants';
import { keycloakManager } from 'app/services/keycloak';

import { Modal } from 'app/containers/Modal';
import { ProductImage } from '../ProductImage';
import { ProductCardProps } from '../../types';

export const ProductCard: FC<ProductCardProps> = ({ product, subscription, productCategory, disabled }) => {
  const { title, payload, description } = product;

  const { digitalProduct } = payload;
  const { modalId, openModal, closeModal } = useModalIdState();

  const navigateToModule = (url) => {
    window.open(url, '_blank').focus();
  };

  const showBuyProductModal = () => {
    openModal(MODALS_IDS.BUY_PRODUCT);
  };

  const showProductinfo = () => {
    openModal(MODALS_IDS.PRODUCT_INFO);
  };

  const makeStartPage = () => {
    console.log('makeStartPage...');
  };

  const buyProduct = () => {
    console.log('buyProduct');
  };

  return (
    <div onClick={() => subscription && navigateToModule(payload?.digitalProduct?.appUrl)} className="ProductCard">
      <div className="ProductCard__picture">
        <ProductImage id={payload.product.picture} type="picture" />
      </div>
      <div className="ProductCard__title">
        {productCategory === PRODUCT_CATEGORY.TS_PRODUCT ? (
          <div className="ProductCard__logo">
            <ProductImage id={digitalProduct.icon} type="icon" />
          </div>
        ) : null}
        <div className="capture">
          <span>{title}</span>
        </div>
      </div>

      {modalId === MODALS_IDS.PRODUCT_INFO ? (
        <Modal isOpen={modalId === MODALS_IDS.PRODUCT_INFO} closeModal={closeModal} title="Информация">
          <div className="ProductInfo">
            <div className="ProductInfo__title">
              <div className="ProductInfo__logo">
                <ProductImage id={digitalProduct?.icon} type="icon" />
              </div>
              <span>{title}</span>
            </div>

            <div className="ProductInfo__descr">
              <p>{description}</p>
            </div>

            <div className="ProductInfo__btns">
              <Button onClick={closeModal} small text="Отмена" className="secondary" />
              <Button onClick={showBuyProductModal} small text="Купить" className="" />
            </div>
          </div>
        </Modal>
      ) : null}

      {modalId === MODALS_IDS.BUY_PRODUCT ? (
        <Modal isOpen={modalId === MODALS_IDS.BUY_PRODUCT} closeModal={closeModal} title="Покупка">
          <div className="ProductInfo">
            <div className="ProductInfo__title">
              <div className="ProductInfo__logo">
                <ProductImage id={digitalProduct?.icon} type="icon" />
              </div>
              <Box>
                <Text variant="text_size_14_bold">{title}</Text>
              </Box>
            </div>
            <div className="ProductInfo__descr">
              <Box mb={3}>
                <Text variant="text02_size_14">Уважаемый {keycloakManager.tokenParsed?.name}!</Text>
              </Box>
              <Box mb={3}>
                <Text variant="text02_size_14">
                  Вы собираетесь приобрести доступ к модулю {title}. Подтвердите ваше намерение или отмените покупку. В
                  случае возникших вопросов просьба обращаться по следующим контактам:
                </Text>{' '}
              </Box>
              <Box mb={3}>
                <Text variant="text02_size_14">
                  Email: <a href="mailto:info@tcifra-s.com">info@tcifra-s.com</a>
                </Text>
              </Box>
            </div>

            <div className="ProductInfo__btns">
              <Button onClick={closeModal} small text="Отмена" className="secondary" />
              {/* <Button onClick={buyProduct} small text="Купить" className="" /> */}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
