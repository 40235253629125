import React from 'react';

function ClientIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g fill="#525252" clipPath="url(#clip0_3794_146213)" opacity="0.6">
        <path d="M3 15h10v-2.5A3.505 3.505 0 009.5 9h-3A3.504 3.504 0 003 12.5V15zM4.5 4.5a3.5 3.5 0 107 0 3.5 3.5 0 00-7 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_3794_146213">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default React.memo(ClientIcon);
