/* eslint-disable react/no-unstable-nested-components */
/**
 *
 * SidePopover
 *
 */

import React, { memo, ReactElement } from 'react';
import { Portal } from 'react-portal';
import './style.less';

interface SidePopoverProps {
  isOpen: boolean;
  children: ReactElement | null;
}

export function SidePopover(props: SidePopoverProps) {
  const { isOpen, children } = props;

  return (
    <Portal>
      <div className={`SidePopover ${isOpen ? 'open' : ''}`}>{children}</div>
    </Portal>
  );
}

export default memo(SidePopover);
