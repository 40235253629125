import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showToastAction } from 'app/containers/Toast/actions';
import { getProductsApi } from '../api';
import { productsActions } from '.';
import { getProductsActionPayloadType } from './types';

export function* getProductsSaga({ payload }: { payload: getProductsActionPayloadType } = { payload: '' }): unknown {
  try {
    const result = yield call(getProductsApi, payload as string);
    yield put(productsActions.getProductsSuccessAction(result));
  } catch (err) {
    yield put(productsActions.getProductsErrorAction(err as Error));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: (err as Error).message
      })
    );
  }
}

export default function* productsSaga() {
  yield takeLatest(productsActions.getProductsAction.type, getProductsSaga);
}
