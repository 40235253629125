import { takeLatest, call, put } from 'redux-saga/effects';
import { logIn, logOut } from 'app/services/oauthservice';
import { push } from 'connected-react-router';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import {
  loginErrorAction,
  logoutSuccessAction,
  logoutErrorAction,
  setUserSuccessAction,
  setUserFailAction,
  logoutAction,
  cleanLoginErrorAction,
  loginSuccessAction
} from 'app/containers/LoginPage/actions';
import { LOGIN_ACTION, LOGOUT_ACTION, SET_USER_ACTION } from 'app/containers/LoginPage/constants';
import jwtDecode from 'jwt-decode';
import { getAccessToken } from 'utils/storage/storage';
import { eraseAccountId, setAccountId } from 'utils/accountId/accountIdManager';

export function* loginSaga({ payload }) {
  const { username, password } = payload;
  try {
    yield call(logIn, username, password);
    const accessToken = getAccessToken();
    const parsedUser = jwtDecode(accessToken);
    yield put(cleanLoginErrorAction());
    yield put(loginSuccessAction(parsedUser));
    if (parsedUser?.clientIds?.length === 1) {
      setAccountId(parsedUser.clientIds[0]);
      yield put(push(`/${ROUTE_NAMES.ROOT}`));
    }
    yield put(push(`/${ROUTE_NAMES.ACCOUNTS}`));
  } catch (err) {
    yield put(loginErrorAction(err));
    // throw err;
  }
}

export function* logoutSaga() {
  try {
    yield call(logOut);
    yield put(logoutSuccessAction());
    // yield put(push(`/${ROUTE_NAMES.AUTH_LOGIN}`));
    eraseAccountId();
  } catch (err) {
    yield put(logoutErrorAction(err));
  }
}

export function* setUserSaga(action) {
  try {
    if (!action.payload.user || !Object.keys(action.payload.user).length) {
      yield put(logoutAction());
      return;
    }
    yield put(setUserSuccessAction(action.payload.user));
  } catch (err) {
    console.error(err);
    yield put(logoutAction());
    yield put(setUserFailAction(err));
  }
}

export default function* LoginSaga() {
  yield takeLatest(LOGIN_ACTION, loginSaga);
  yield takeLatest(LOGOUT_ACTION, logoutSaga);
  yield takeLatest(SET_USER_ACTION, setUserSaga);
}
