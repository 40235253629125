import React from 'react';

function GearIcon() {
  return (
    <svg className="Icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path fill="#393939" d="M0 0H16V16H0z"></path>
      <rect width="32" height="32" x="-8" y="-8" fill="#fff" rx="4"></rect>
      <g clipPath="url(#clip0_7_761)">
        <mask id="path-2-inside-1_7_761" fill="#fff">
          <path d="M13.5 8.38V8v-.385l.96-.84a1 1 0 00.19-1.275l-1.18-2a1 1 0 00-1.185-.45l-1.215.41a5.663 5.663 0 00-.655-.375l-.255-1.26a1 1 0 00-1-.805H6.82a1 1 0 00-1 .805l-.255 1.26a5.74 5.74 0 00-.66.375l-1.19-.43a1 1 0 00-.32-.03 1 1 0 00-.865.5l-1.18 2a1 1 0 00.205 1.255l.945.865v.765l-.945.84A1 1 0 001.35 10.5l1.18 2a1 1 0 001.185.45l1.215-.41c.21.14.428.265.655.375l.255 1.26a1 1 0 001 .805H9.2a1 1 0 001-.805l.255-1.26c.228-.11.448-.235.66-.375l1.21.41a1.001 1.001 0 001.185-.45l1.14-2a1 1 0 00-.205-1.255L13.5 8.38zM12.605 12l-1.715-.58c-.402.34-.86.606-1.355.785L9.18 14H6.82l-.355-1.775a4.68 4.68 0 01-1.35-.785l-1.72.56-1.18-2 1.36-1.2a4.45 4.45 0 010-1.565L2.215 6l1.18-2 1.715.58a4.43 4.43 0 011.355-.785L6.82 2h2.36l.355 1.775c.49.184.947.45 1.35.785l1.72-.56 1.18 2-1.36 1.2a4.448 4.448 0 010 1.565L13.785 10l-1.18 2z"></path>
        </mask>
        <path
          fill="#525252"
          stroke="#525252"
          strokeWidth="2"
          d="M13.5 8.38V8v-.385l.96-.84a1 1 0 00.19-1.275l-1.18-2a1 1 0 00-1.185-.45l-1.215.41a5.663 5.663 0 00-.655-.375l-.255-1.26a1 1 0 00-1-.805H6.82a1 1 0 00-1 .805l-.255 1.26a5.74 5.74 0 00-.66.375l-1.19-.43a1 1 0 00-.32-.03 1 1 0 00-.865.5l-1.18 2a1 1 0 00.205 1.255l.945.865v.765l-.945.84A1 1 0 001.35 10.5l1.18 2a1 1 0 001.185.45l1.215-.41c.21.14.428.265.655.375l.255 1.26a1 1 0 001 .805H9.2a1 1 0 001-.805l.255-1.26c.228-.11.448-.235.66-.375l1.21.41a1.001 1.001 0 001.185-.45l1.14-2a1 1 0 00-.205-1.255L13.5 8.38zM12.605 12l-1.715-.58c-.402.34-.86.606-1.355.785L9.18 14H6.82l-.355-1.775a4.68 4.68 0 01-1.35-.785l-1.72.56-1.18-2 1.36-1.2a4.45 4.45 0 010-1.565L2.215 6l1.18-2 1.715.58a4.43 4.43 0 011.355-.785L6.82 2h2.36l.355 1.775c.49.184.947.45 1.35.785l1.72-.56 1.18 2-1.36 1.2a4.448 4.448 0 010 1.565L13.785 10l-1.18 2z"
          mask="url(#path-2-inside-1_7_761)"
        ></path>
        <path
          fill="#525252"
          d="M8 11a3 3 0 113-3 2.97 2.97 0 01-3 3zm0-5a1.955 1.955 0 00-2 2 1.955 1.955 0 002 2 1.954 1.954 0 002-2 1.954 1.954 0 00-2-2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_7_761">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default GearIcon;
