import { GET_STATUSES, GET_STATUSES_SUCCESS, GET_STATUSES_FAIL, RESET_STATUSES_STATUS } from './constants';

export function getStatusesAction(someId) {
  return {
    type: GET_STATUSES,
    payload: {
      someId
    }
  };
}

export function getStatusesSuccessAction(statuses) {
  return {
    type: GET_STATUSES_SUCCESS,
    payload: {
      statuses
    }
  };
}

export function getStatusesErrorAction(error) {
  return {
    type: GET_STATUSES_FAIL,
    payload: {
      error
    }
  };
}

export function resetStatusesStatusAction() {
  return {
    type: RESET_STATUSES_STATUS
  };
}
