/*
 *
 * Modal reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export const initialState = {
  modal: {
    ...createStateStatus(),
    modalId: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const modalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case OPEN_MODAL:
        draft.modalId = action.payload.modalId;
        break;
      case CLOSE_MODAL:
        draft.modalId = undefined;
        break;
    }
  });

export default modalReducer;
