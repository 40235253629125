/**
 *
 * WithLoader
 *
 */

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import './style.less';
import Loader from 'app/components/Loader';

export function WithLoader(props) {
  const { children, showLoader, states = [] } = props;
  const isLoaderShowingByState = useMemo(
    () => (Array.isArray(states) ? states.reduce((prev, curr) => prev || curr?.isFetching, false) : states.isFetching),
    [states]
  );

  const isLoaderShowing = useMemo(() => showLoader || isLoaderShowingByState, [showLoader, isLoaderShowingByState]);

  return (
    <div data-testid="WithLoader" className="WithLoader">
      {isLoaderShowing ? <Loader /> : children}
    </div>
  );
}

const stateProp = PropTypes.shape({
  isFetching: PropTypes.bool,
  isDone: PropTypes.bool,
  isError: PropTypes.bool,
  data: PropTypes.any
});

WithLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.any]).isRequired,
  showLoader: PropTypes.bool,
  states: PropTypes.oneOfType([PropTypes.arrayOf(stateProp), stateProp])
};

export default memo(WithLoader);
