const IS_PROD = !!import.meta.env.VITE_IS_PROD || !!import.meta.env.IS_PROD;
const API_ENDPOINT_ADMIN = IS_PROD
  ? import.meta.env.VITE_API_ENDPOINT_ADMIN_PROD
  : import.meta.env.VITE_API_ENDPOINT_ADMIN;
const API_ENDPOINT = IS_PROD ? import.meta.env.VITE_API_ENDPOINT_PROD : import.meta.env.VITE_API_ENDPOINT;
const AUTH_ENDPOINT = IS_PROD ? import.meta.env.VITE_AUTH_ENDPOINT_PROD : import.meta.env.VITE_AUTH_ENDPOINT;
const API_ENDPOINT_NSI = IS_PROD ? import.meta.env.VITE_API_ENDPOINT_NSI_PROD : import.meta.env.VITE_API_ENDPOINT_NSI;
const API_ENDPOINT_USS = IS_PROD ? import.meta.env.VITE_API_ENDPOINT_USS_PROD : import.meta.env.VITE_API_ENDPOINT_USS;
const API_ENDPOINT_EDO = IS_PROD ? import.meta.env.VITE_API_ENDPOINT_EDO_PROD : import.meta.env.VITE_API_ENDPOINT_EDO;
const API_ENDPOINT_DADATA = IS_PROD
  ? import.meta.env.VITE_API_ENDPOINT_DADATA_PROD
  : import.meta.env.VITE_API_ENDPOINT_DADATA;
const API_ENDPOINT_LOGISTICS = IS_PROD
  ? import.meta.env.VITE_API_ENDPOINT_LOGISTICS_PROD
  : import.meta.env.VITE_API_ENDPOINT_LOGISTICS;
const SOCKET_BROKER_URI = IS_PROD
  ? import.meta.env.VITE_SOCKET_BROKER_URI_PROD
  : import.meta.env.VITE_SOCKET_BROKER_URI;

const AUTH_CLIENT_ID = IS_PROD ? import.meta.env.VITE_AUTH_CLIENT_ID_PROD : import.meta.env.VITE_AUTH_CLIENT_ID;
const AUTH_CLIENT_SECRET = IS_PROD
  ? import.meta.env.VITE_AUTH_CLIENT_SECRET_PROD
  : import.meta.env.VITE_AUTH_CLIENT_SECRET;
const AUTH_REALM = IS_PROD ? import.meta.env.VITE_AUTH_REALM_PROD : import.meta.env.VITE_AUTH_REALM;

type ValueFromProcessEnv = string | undefined;

interface AppConfig {
  baseApiAdminUrl: ValueFromProcessEnv;
  baseAuthURL: ValueFromProcessEnv;
  baseApiURL: ValueFromProcessEnv;
  baseApiNsiURL: ValueFromProcessEnv;
  baseApiUssURL: ValueFromProcessEnv;
  baseApiEdoURL: ValueFromProcessEnv;
  baseApiDadataURL: ValueFromProcessEnv;
  baseApiLogisticsURL: ValueFromProcessEnv;
  socketBrokerURI: ValueFromProcessEnv;
  auth: {
    client_id: ValueFromProcessEnv;
    client_secret: ValueFromProcessEnv;
    realm: ValueFromProcessEnv;
  };
}

export const APP_CONFIG: AppConfig = {
  baseApiAdminUrl: API_ENDPOINT_ADMIN,
  baseApiNsiURL: API_ENDPOINT_NSI,
  baseApiUssURL: API_ENDPOINT_USS,
  baseApiEdoURL: API_ENDPOINT_EDO,
  baseApiDadataURL: API_ENDPOINT_DADATA,
  socketBrokerURI: SOCKET_BROKER_URI,
  baseApiLogisticsURL: API_ENDPOINT_LOGISTICS,
  baseApiURL: API_ENDPOINT,
  baseAuthURL: AUTH_ENDPOINT,
  auth: {
    client_id: AUTH_CLIENT_ID,
    client_secret: AUTH_CLIENT_SECRET,
    realm: AUTH_REALM
  }
};
