/*
 *
 * GlobalSearch reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import {
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_SUCCESS,
  GET_GLOBAL_SEARCH_FAIL,
  RESET_GLOBAL_SEARCH_STATUS
} from './constants';

export const initialState = {
  globalSearch: {
    ...createStateStatus(),
    data: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const globalSearchReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_GLOBAL_SEARCH:
        draft.globalSearch.isFetching = true;
        draft.globalSearch.isError = false;
        break;
      case GET_GLOBAL_SEARCH_SUCCESS:
        draft.globalSearch.isFetching = false;
        draft.globalSearch.isDone = true;
        draft.globalSearch.data = action.payload.globalSearch;
        break;
      case GET_GLOBAL_SEARCH_FAIL:
        draft.globalSearch.isFetching = false;
        draft.globalSearch.isDone = true;
        draft.globalSearch.isError = action.payload.error;
        break;
      case RESET_GLOBAL_SEARCH_STATUS:
        draft.globalSearch.isFetching = false;
        draft.globalSearch.isDone = false;
        draft.globalSearch.isError = null;
        draft.globalSearch.data = undefined;
        break;
    }
  });

export default globalSearchReducer;
