import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  RESET_ACCOUNTS_STATUS,
  SET_ACCOUNT,
  SET_ACCOUNT_SUCCESS,
  SET_ACCOUNT_FAIL,
  RESET_SET_ACCOUNT_STATUS,
  PATCH_ACCOUNT_BASE_ROLE,
  PATCH_ACCOUNT_BASE_ROLE_SUCCESS,
  PATCH_ACCOUNT_BASE_ROLE_FAIL,
  RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS
} from './constants';

export function getAccountsAction(accountIds) {
  return {
    type: GET_ACCOUNTS,
    payload: {
      accountIds
    }
  };
}

export function getAccountsSuccessAction(accounts) {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    payload: {
      accounts
    }
  };
}

export function getAccountsErrorAction(error) {
  return {
    type: GET_ACCOUNTS_FAIL,
    payload: {
      error
    }
  };
}

export function resetAccountsStatusAction() {
  return {
    type: RESET_ACCOUNTS_STATUS
  };
}

export function setAccountAction(accountId, shouldEmit = true) {
  return {
    type: SET_ACCOUNT,
    payload: {
      accountId,
      shouldEmit
    }
  };
}

export function setAccountSuccessAction(account) {
  return {
    type: SET_ACCOUNT_SUCCESS,
    payload: {
      account
    }
  };
}

export function setAccountErrorAction(error) {
  return {
    type: SET_ACCOUNT_FAIL,
    payload: {
      error
    }
  };
}

export function resetSetAccountStatusAction() {
  return {
    type: RESET_SET_ACCOUNT_STATUS
  };
}

export function patchAccountBaseRoleAction(accountBaseRole) {
  return {
    type: PATCH_ACCOUNT_BASE_ROLE,
    payload: {
      accountBaseRole
    }
  };
}

export function patchAccountBaseRoleSuccessAction(accountBaseRole) {
  return {
    type: PATCH_ACCOUNT_BASE_ROLE_SUCCESS,
    payload: {
      accountBaseRole
    }
  };
}

export function patchAccountBaseRoleErrorAction(error, prevBaseRole) {
  return {
    type: PATCH_ACCOUNT_BASE_ROLE_FAIL,
    payload: {
      error,
      prevBaseRole
    }
  };
}

export function resetPatchAccountBaseRoleStatusAction() {
  return {
    type: RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS
  };
}
