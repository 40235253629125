/**
 *
 * Switch
 *
 */

import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';

import './style.less';
import { motion, AnimateSharedLayout } from 'framer-motion';
import DropdownMenu from 'app/components/DropdownMenu';
import Toggler from 'app/components/Toggler';
import { Label } from 'theme-ui';
import Badge from 'app/components/Badge';
import GearIcon from 'app/components/Icons/GearIcon';

const switchSettingOption = ({ item, i, setSettingsValues, settingsValues }) => ({
  disabled: false,
  label: () => {
    const id = `switch_settings_toggler_${i}`;
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const finalValues = settingsValues.map((value, mi) => {
            if (mi === i) {
              return !value;
            }
            return value;
          });

          if (finalValues.every((value) => !value)) {
            return settingsValues;
          }
          setSettingsValues(
            settingsValues.map((value, mi) => {
              if (mi === i) {
                return !value;
              }
              return value;
            })
          );
        }}
        key={id}
        className="OptionItem"
      >
        <Label htmlFor={id}>{item.name}</Label>
        <Toggler id={id} checked={settingsValues[i]} />
      </div>
    );
  },
  value: item.name
});

const globalSearchSettingsKey = 'globalSearchSettings';

const getLocalStorageId = (id) => `${globalSearchSettingsKey}${id}`;

const getInitialSettings = (items, id) => {
  if (!id) {
    return items.map(Boolean);
  }
  const valuesFromLocalStorage = JSON.parse(localStorage.getItem(getLocalStorageId(id)));
  if (valuesFromLocalStorage?.length !== items?.length) {
    return items.map(Boolean);
  }
  if (valuesFromLocalStorage?.length) {
    return valuesFromLocalStorage;
  }
  return items.map(Boolean);
};

function Switch({
  items,
  onClick,
  onIndexChange,
  disableUrlSync,
  id,
  secondaryVariant,
  thirdVariant,
  disabled,
  settings
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [settingsValues, setSettingsValues] = useState(settings && getInitialSettings(items, id));

  const location = useLocation();
  const history = useHistory();
  const { replace } = history;
  const { search } = location;
  const isJustOpened = useRef(true);

  const disableSync = disableUrlSync;

  const switchUrlName = id ? `current${id}SwitchValue` : 'currentSwitchValue';

  const handleClick = useCallback(
    (index = 0) => {
      if (index === selectedIndex) {
        return;
      }
      setSelectedIndex(index);
      const searchFromHistory = qs.parse(search.substring(1));
      onClick && onClick(items[index] && items[index]?.value);
      !disableUrlSync &&
        replace({
          search: qs.stringify({ ...searchFromHistory, [switchUrlName]: items[index]?.value })
        });
    },
    [selectedIndex, search, onClick, items, disableUrlSync, replace, switchUrlName]
  );

  useEffect(() => {
    onIndexChange(selectedIndex);
  }, [onIndexChange, selectedIndex]);

  useEffect(() => {
    const index = settingsValues?.findIndex(Boolean);
    handleClick(index);
  }, [settingsValues]);
  
  useEffect(() => {
    const searchFromHistory = qs.parse(search.substring(1));
    const foundIndex = items.findIndex((item) => item?.value.toString() === searchFromHistory[switchUrlName]);
    if ((!disableSync, searchFromHistory[switchUrlName])) {
      if (foundIndex !== -1) {
        setSelectedIndex(foundIndex);
        onClick && onClick(items[foundIndex]?.value);
      } else {
        setSelectedIndex(selectedIndex);
        onClick && onClick(items[selectedIndex]?.value);
      }
    }

    if (history.action === 'POP' && !disableSync && !searchFromHistory[switchUrlName] && !isJustOpened.current) {
      history.goBack();
      return;
    }

    if (!searchFromHistory[switchUrlName] && !isJustOpened.current) {
      !disableSync &&
        replace({
          search: qs.stringify({ ...searchFromHistory, [switchUrlName]: items[selectedIndex]?.value })
        });
    }

    if (!disableSync) {
      if (foundIndex < 0) {
        replace({
          search: qs.stringify({ ...searchFromHistory, [switchUrlName]: items[0]?.value })
        });
      }
    }

    isJustOpened.current = false;
  }, [disableSync, history, id, items, onClick, replace, search, selectedIndex, switchUrlName]);

  useEffect(
    () => () => {
      setSelectedIndex(0);
    },
    []
  );

  const mainClasses = classnames({
    Switch: true,
    SwitchSecondary: !!secondaryVariant,
    SwitchThird: !!thirdVariant,
    disabled: !!disabled
  });

  useEffect(
    () => () => {
      settings && localStorage.setItem(getLocalStorageId(id), JSON.stringify(settingsValues));
    },
    [id, settings, settingsValues]
  );

  return (
      <div data-testid="Switch" className={mainClasses}>
        <div className="items">
          {items.map(({ name, count, value, hasNewItems, icon: Icon }, i) => {
            if (settings && settingsValues && !settingsValues[i]) {
              return null;
            }
            const classes = classnames({
              Switch__item: true,
              active: i === selectedIndex && !disabled,
              prev: i === selectedIndex - 1
            });
            return (
              <div className="SwitchItem__wrap" key={value}>
                <button
                  data-testid={`Switch-item-button-${i}`}
                  type="button"
                  onClick={() => handleClick(i)}
                  className={classes}
                >
                  {Icon && (
                    <div className="SwitchItem__icon">
                      <Icon />
                    </div>
                  )}
                  {name}
                  {!!count && !disabled && (
                    <div className="SwitchItem__count">
                      <Badge value={count} hasNewItems={hasNewItems} />
                    </div>
                  )}
                </button>
                {selectedIndex === i && (
                  <motion.div
                    layoutId="underline"
                    className="SwitchItem__underline"
                    animate={{ x: 0 }}
                    initial={false}
                    transition={{ duration: 0.1 }}
                  />
                )}
              </div>
            );
          })}
          {settings && (
            <DropdownMenu
              key="21"
              keepOpenOnClick
              icon={GearIcon}
              options={items.map((item, i) =>
                switchSettingOption({
                  item,
                  i,
                  setSettingsValues,
                  settingsValues,
                  selectedIndex
                })
              )}
            />
          )}
        </div>
        <div className="line-bottom" />
      </div>
  );
}

Switch.propTypes = {
  items: PropTypes.array,
  id: PropTypes.string,
  onClick: PropTypes.func,
  onIndexChange: PropTypes.func,
  secondaryVariant: PropTypes.bool,
  thirdVariant: PropTypes.bool,
  disabled: PropTypes.bool,
  disableUrlSync: PropTypes.bool,
  settings: PropTypes.bool
};

Switch.defaultProps = {
  items: [{ name: 'default', value: 'default' }],

  onIndexChange: () => null
};

export default memo(Switch);
