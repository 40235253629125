/**
 *
 * ShipmentEventsTab
 *
 */

import React, { memo } from 'react';

import './style.less';

export function ShipmentEventsTab() {
  return (
    <div data-testid="ShipmentEventsTab" className="ShipmentEventsTab">
      <h3>В разработке...</h3>
    </div>
  );
}

export default memo(ShipmentEventsTab);
