/*
 *
 * EmailCodeValidation constants
 *
 */

export const DEFAULT_ACTION = 'app/EmailCodeValidation/DEFAULT_ACTION';

export const INIT_TIME_REMAINING = 60;

export const CONTAINER_KEY = 'emailCodeValidation';

export const GET_VALIDATION_CODE =
  'app/emailCodeValidation/GET_VALIDATION_CODE';
export const GET_VALIDATION_CODE_SUCCESS =
  'app/emailCodeValidation/GET_VALIDATION_CODE_SUCCESS';
export const GET_VALIDATION_CODE_FAIL =
  'app/emailCodeValidation/GET_VALIDATION_CODE_FAIL';

export const CHECK_VALIDATION_CODE =
  'app/emailCodeValidation/CHECK_VALIDATION_CODE';
export const CHECK_VALIDATION_CODE_SUCCESS =
  'app/emailCodeValidation/CHECK_VALIDATION_CODE_SUCCESS';
export const CHECK_VALIDATION_CODE_FAIL =
  'app/emailCodeValidation/CHECK_VALIDATION_CODE_FAIL';

export const RESET_VALIDATIONS = 'app/emailCodeValidation/RESET_VALIDATIONS';
