import { defaultMemoize } from 'reselect';
import { ROUTE_NAMES } from '../App/constants';

export const computeRestrictionsByPath = defaultMemoize((rolesData, path) => {
  const {
    rights: { canSeeTender, canSeePricingRules, canSeeSupply, canSeeSupplyContracts }
  } = rolesData;

  if (!canSeeTender && path === ROUTE_NAMES.TKP_REQUESTS) {
    return true;
  }
  if (!canSeePricingRules && path === ROUTE_NAMES.AUTO_PRICING) {
    return true;
  }
  if (!canSeeSupply && path === ROUTE_NAMES.PROVIDER_ORDERS) {
    return true;
  }
  if (!canSeeSupplyContracts && path === ROUTE_NAMES.CONTRACTS) {
    return true;
  }

  return false;
  // return roles.reduce((prev, curr) => prev && RESTRICTED_PATHS[curr]?.includes(path), true);
});
