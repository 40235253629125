/*
 *
 * Accounts constants
 *
 */

export const CONTAINER_KEY = 'accounts';

export const GET_ACCOUNTS = 'app/accounts/GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'app/accounts/GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAIL = 'app/accounts/GET_ACCOUNTS_FAIL';
export const RESET_ACCOUNTS_STATUS = 'app/accounts/RESET_ACCOUNTS_STATUS';

export const SET_ACCOUNT = 'app/accounts/SET_ACCOUNT';
export const SET_ACCOUNT_SUCCESS = 'app/accounts/SET_ACCOUNT_SUCCESS';
export const SET_ACCOUNT_FAIL = 'app/accounts/SET_ACCOUNT_FAIL';
export const RESET_SET_ACCOUNT_STATUS = 'app/accounts/RESET_SET_ACCOUNT_STATUS';

export const PATCH_ACCOUNT_BASE_ROLE = 'app/accounts/PATCH_ACCOUNT_BASE_ROLE';
export const PATCH_ACCOUNT_BASE_ROLE_SUCCESS = 'app/accounts/PATCH_ACCOUNT_BASE_ROLE_SUCCESS';
export const PATCH_ACCOUNT_BASE_ROLE_FAIL = 'app/accounts/PATCH_ACCOUNT_BASE_ROLE_FAIL';
export const RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS = 'app/accounts/RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS';
