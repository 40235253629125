import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { CONTAINER_KEY } from './constants';

/**
 * Direct selector to the globalSearch state domain
 */

const selectGlobalSearchDomain = (state) => state[CONTAINER_KEY] || initialState;

const makeSelectGlobalSearchState = () => createSelector(selectGlobalSearchDomain, (substate) => substate);

const makeSelectGlobalSearch = () => createSelector(selectGlobalSearchDomain, (substate) => substate.globalSearch);

export default makeSelectGlobalSearchState;
export { selectGlobalSearchDomain, makeSelectGlobalSearchState, makeSelectGlobalSearch };
