import React from 'react';

function RightArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#0F62FE"
        stroke="#0F62FE"
        d="M9.4 4.108L13.294 8 9 12.293l-.007-.007 3.435-3.432.855-.854h-.004l-.85-.853-3.028-3.04z"
      ></path>
    </svg>
  );
}

export default React.memo(RightArrow);
