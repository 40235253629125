/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useCallback, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { union } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';

import LoginPage from 'app/containers/LoginPage';

import NotFoundPage from 'app/containers/NotFoundPage';

import AuthRoute from 'app/components/AuthRoute';
import ProtectedRoute from 'app/components/ProtectedRoute';
import { useProtect } from 'app/components/useProtect';
import makeSelectRoles from 'app/containers/Roles/selectors';

import { CONTAINER_KEY as LOGIN_PAGE_CONTAINER_KEY } from 'app/containers/LoginPage/constants';
import loginPageSaga from 'app/containers/LoginPage/saga';
import loginPageReducer from 'app/containers/LoginPage/reducer';

import AccountsPage from 'app/containers/AccountsPage';
import { CONTAINER_KEY as ACCOUNTS_PAGE_CONTAINER_KEY } from 'app/containers/AccountsPage/constants';
import accountsSaga from 'app/containers/AccountsPage/saga';
import accountsReducer from 'app/containers/AccountsPage/reducer';

import { CONTAINER_KEY as STUB_PAGE_CONTAINER_KEY } from 'app/containers/StubPage/constants';
import stubPageSaga from 'app/containers/StubPage/saga';
import stubPageReducer from 'app/containers/StubPage/reducer';

import queryFiltersReducer from 'app/containers/QueryFilters/reducer';
import { CONTAINER_KEY as QUERY_FILTERS_CONTAINER_KEY } from 'app/containers/QueryFilters/constants';

import { CONTAINER_KEY as STATUSES_CONTAINER_KEY } from 'app/containers/Statuses/constants';
import statusesSaga from 'app/containers/Statuses/saga';
import statusesReducer from 'app/containers/Statuses/reducer';

import { CONTAINER_KEY as GLOBAL_SEARCH_CONTAINER_KEY } from 'app/containers/GlobalSearch/constants';
import globalSearchSaga from 'app/containers/GlobalSearch/saga';
import globalSearchReducer from 'app/containers/GlobalSearch/reducer';

import { setUserAction } from 'app/containers/LoginPage/actions';
import toastReducer from 'app/containers/Toast/reducer';
import toastSaga from 'app/containers/Toast/saga';
import { CONTAINER_KEY as TOAST_CONTAINER_KEY } from 'app/containers/Toast/constants';
import { makeSelectUser, makeSelectUserSettingStatus } from 'app/containers/LoginPage/selectors';
import { CONTAINER_KEY as BACK_NAVIGATOR_PAGE_CONTAINER_KEY } from 'app/containers/BackNavigator/constants';
import backNavigatorReducer from 'app/containers/BackNavigator/reducer';
import Layout from 'app/components/Layout';
import { CONTAINER_KEY as EMAIL_VERIFICATION_CONTAINER_KEY } from 'app/containers/EmailCodeValidation/constants';
import emailCodeValidationSaga from 'app/containers/EmailCodeValidation/saga';
import emailCodeValidationReducer from 'app/containers/EmailCodeValidation/reducer';
import { keycloakManager } from 'app/services/keycloak';
import { GlobalStyle } from 'styles/global-styles';
import { getAccountId } from 'utils/accountId/accountIdManager';
import { connect as connectAuthManager } from 'app/services/authManager';
import EventsPage from 'app/containers/EventsPage';
import ProductsPage from 'app/containers/ProductsPage';
import { useEventsSlice } from '../EventsPage/slice/injector';
import { useProductsSlice } from '../ProductsPage/slice/injector';
import SettingsPage from '../SettingsPage';
import settingsReducer from '../SettingsPage/reducer';
import settingsSaga from '../SettingsPage/saga';
import ForbiddenPage from '../ForbiddenPage';
import { CONTAINER_KEY as SETTINGS_CONTAINER_KEY } from '../SettingsPage/constants';
import '../../global.less';

import { ROUTE_NAMES } from './constants';

import './style.less';

import 'react-toastify/dist/ReactToastify.css';
import { makeSelectAccount, makeSelectAccounts } from '../AccountsPage/selectors';
import { getAccountsAction, setAccountAction } from '../AccountsPage/actions';
import rolesReducer from '../Roles/reducer';
import { CONTAINER_KEY as ROLES_CONTAINER_KEY } from '../Roles/constants';

export const App: FunctionComponent<any> = ({
  user,
  setUser,
  setAccount,
  getAccounts,
  rolesData,
  accountState: { data: account, isFetching: isAccountFetching },
  accountsState: { data: accounts, isFetching: isAccountsFetching, isError: isAccountsError }
}) => {
  useInjectSaga({ key: LOGIN_PAGE_CONTAINER_KEY, saga: loginPageSaga });
  useInjectReducer({
    key: LOGIN_PAGE_CONTAINER_KEY,
    reducer: loginPageReducer
  });
  useInjectReducer({
    key: BACK_NAVIGATOR_PAGE_CONTAINER_KEY,
    reducer: backNavigatorReducer
  });
  useInjectReducer({
    key: EMAIL_VERIFICATION_CONTAINER_KEY,
    reducer: emailCodeValidationReducer
  });

  useInjectSaga({
    key: EMAIL_VERIFICATION_CONTAINER_KEY,
    saga: emailCodeValidationSaga
  });
  useInjectReducer({
    key: QUERY_FILTERS_CONTAINER_KEY,
    reducer: queryFiltersReducer
  });
  useInjectReducer({ key: TOAST_CONTAINER_KEY, reducer: toastReducer });
  useInjectSaga({ key: TOAST_CONTAINER_KEY, saga: toastSaga });
  useInjectReducer({ key: STUB_PAGE_CONTAINER_KEY, reducer: stubPageReducer });
  useInjectSaga({ key: STUB_PAGE_CONTAINER_KEY, saga: stubPageSaga });
  useInjectReducer({ key: ACCOUNTS_PAGE_CONTAINER_KEY, reducer: accountsReducer });
  useInjectSaga({ key: ACCOUNTS_PAGE_CONTAINER_KEY, saga: accountsSaga });
  useInjectReducer({ key: STATUSES_CONTAINER_KEY, reducer: statusesReducer });
  useInjectSaga({ key: STATUSES_CONTAINER_KEY, saga: statusesSaga });
  useInjectReducer({ key: GLOBAL_SEARCH_CONTAINER_KEY, reducer: globalSearchReducer });
  useInjectSaga({ key: GLOBAL_SEARCH_CONTAINER_KEY, saga: globalSearchSaga });
  useInjectReducer({ key: SETTINGS_CONTAINER_KEY, reducer: settingsReducer });
  useInjectSaga({ key: SETTINGS_CONTAINER_KEY, saga: settingsSaga });
  useInjectReducer({ key: ROLES_CONTAINER_KEY, reducer: rolesReducer });
  useEventsSlice();
  useProductsSlice();

  const computeRouteProps = useCallback((isAuthorized, rolesData) => ({ isAuthorized, rolesData }), []);
  const isAuthorized = useProtect();
  const routeProps = React.useMemo(
    () => computeRouteProps(isAuthorized, rolesData),
    [computeRouteProps, isAuthorized, rolesData]
  );

  const [keycloakReady, setKeycloakReady] = React.useState(false);
  const [keycloakAuthenticated, setKeycloakAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const accountId = getAccountId();
    keycloakReady && connectAuthManager();
    if (!isAccountFetching && !!user && !!accountId && !account) {
      setAccount(accountId);
    }
    if (!accounts && user && !isAccountsFetching && !isAccountsError && rolesData?.roles) {
      const idsFromUm = rolesData?.roles?.map((role) => role?.client?.externalId);
      !rolesData.noAccounts && getAccounts(union(user.clientIds, idsFromUm));
    }
  }, [
    account,
    accounts,
    isAccountsError,
    getAccounts,
    isAccountFetching,
    isAccountsFetching,
    setAccount,
    keycloakReady,
    setUser,
    user,
    rolesData?.roles,
    rolesData
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (!keycloakManager.authenticated) {
      keycloakManager.initKeycloakLib({
        setUser,
        onInit: () => setKeycloakReady(true),
        onAuth: (value) => setKeycloakAuthenticated(value)
      });
    }
  }, [setUser, user]);

  React.useLayoutEffect(() => {
    const initialLoader = document.querySelector('.Loader.initial');
    const initialStyles = document.querySelector('#initialStyles');
    keycloakAuthenticated && rolesData.roles && initialLoader && initialLoader.remove();
    keycloakAuthenticated && rolesData.roles && initialStyles && initialStyles.remove();
  }, [keycloakAuthenticated, rolesData.roles]);

  const resetUser = useCallback(() => {
    setUser(undefined);
  }, [setUser]);

  React.useEffect(() => {
    window.addEventListener('logout', resetUser, false);
    return () => {
      window.removeEventListener('logout', resetUser);
    };
  }, [resetUser]);

  return (
    <>
      <Layout {...routeProps}>
        <Switch>
          <AuthRoute exact path={`/${ROUTE_NAMES.AUTH_LOGIN}`} component={LoginPage} {...routeProps} />
          <AuthRoute exact path={`/${ROUTE_NAMES.ACCOUNTS}`} component={AccountsPage} {...routeProps} />
          {/* <ProtectedRoute exact path={`/${ROUTE_NAMES.ROOT}`} component={StubPage} {...routeProps} /> */}
          <ProtectedRoute exact path={`/${ROUTE_NAMES.EVENTS}`} component={EventsPage} {...routeProps} />
          <ProtectedRoute exact path={`/${ROUTE_NAMES.PRODUCTS}`} component={ProductsPage} {...routeProps} />
          <ProtectedRoute exact path={`/${ROUTE_NAMES.SETTINGS}`} component={SettingsPage} {...routeProps} />
          <Route exact path={`/${ROUTE_NAMES.FORBIDDEN}/`} component={ForbiddenPage} {...routeProps} />
          <Route path="/404" component={NotFoundPage} />
          <Redirect from="/" to={`/${ROUTE_NAMES.PRODUCTS}`} />
          <Redirect from="*" to="/404" />
        </Switch>
      </Layout>
      <GlobalStyle />
    </>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  setUser: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  user: PropTypes.object,
  userSettingStatus: PropTypes.object,
  rolesData: PropTypes.object,
  accountState: PropTypes.object,
  accountsState: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  userSettingStatus: makeSelectUserSettingStatus(),
  rolesData: makeSelectRoles(),
  accountState: makeSelectAccount(),
  accountsState: makeSelectAccounts()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setUser: (clientId) => dispatch(setUserAction(clientId)),
    setAccount: (accountId) => dispatch(setAccountAction(accountId, false)),
    getAccounts: (accountsId) => dispatch(getAccountsAction(accountsId))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose()(withConnect)(App);
