/**
 *
 * LoginPage
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Loader from 'app/components/Loader';
import { getAccountId } from 'utils/accountId/accountIdManager';
import { compose } from 'redux';

import './style.less';
import { keycloakManager } from 'app/services/keycloak';
import { push } from 'connected-react-router';
import { ROUTE_NAMES } from '../App/constants';

export function LoginPage({ goTo }) {
  if (keycloakManager.tokenParsed && !getAccountId()) {
    goTo(`/${ROUTE_NAMES.ACCOUNTS}`);
  }
  return (
    <div data-testid="LoginPage" className="LoginPage">
      <div className="preloaderContainer">
        <Loader />
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return { goTo: (url) => dispatch(push(url)) };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginPage);
