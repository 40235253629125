import { create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import paramsSerializer from 'utils/paramsSerializer';
import { APP_CONFIG } from '../../config';

export const stubApi = create({
  baseURL: APP_CONFIG.baseApiURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getStubApi = async () => (await stubApi.get('/stub')).data;

stubApi.addAsyncRequestTransform(requestTransform);
stubApi.addAsyncResponseTransform(responseTransform);
