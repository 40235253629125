import { RootState } from 'types/RootState';
import { createSelector } from 'reselect';
import { initialState } from '.';
import { CONTAINER_KEY } from '../constants';

/**
 * Direct selector to the events state domain
 */

const selectEventsDomain = (state: RootState) => state[CONTAINER_KEY] || initialState;

const makeSelectEventsPageState = () => createSelector(selectEventsDomain, (substate) => substate);

const makeSelectEvents = () => createSelector(selectEventsDomain, (substate) => substate.events);

const makeSelectPaymentEvents = () => createSelector(selectEventsDomain, (substate) => substate.paymentEvents);

export default makeSelectEventsPageState;
export { makeSelectPaymentEvents, selectEventsDomain, makeSelectEventsPageState, makeSelectEvents };
