/*
 *
 * EmailCodeValidation actions
 *
 */

import {
  CHECK_VALIDATION_CODE,
  CHECK_VALIDATION_CODE_FAIL,
  CHECK_VALIDATION_CODE_SUCCESS,
  GET_VALIDATION_CODE,
  GET_VALIDATION_CODE_FAIL,
  GET_VALIDATION_CODE_SUCCESS,
  RESET_VALIDATIONS
} from './constants';

export function getValidationCodeAction(email) {
  return {
    type: GET_VALIDATION_CODE,
    payload: {
      email
    }
  };
}

export function getValidationCodeSuccessAction() {
  return {
    type: GET_VALIDATION_CODE_SUCCESS
  };
}

export function getValidationCodeErrorAction(error) {
  return {
    type: GET_VALIDATION_CODE_FAIL,
    payload: {
      error
    }
  };
}

export function checkValidationCodeAction(code, email) {
  return {
    type: CHECK_VALIDATION_CODE,
    payload: {
      code,
      email
    }
  };
}

export function checkValidationCodeSuccessAction(result) {
  return {
    type: CHECK_VALIDATION_CODE_SUCCESS,
    payload: { result }
  };
}

export function checkValidationCodeErrorAction(error) {
  return {
    type: CHECK_VALIDATION_CODE_FAIL,
    payload: {
      error
    }
  };
}

export function resetValidationsAction() {
  return {
    type: RESET_VALIDATIONS
  };
}
