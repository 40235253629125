/**
 *
 * Accounts
*
*/

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Text, Button } from 'theme-ui';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import Loader from 'app/components/Loader';
import { eraseAccountId } from 'utils/accountId/accountIdManager';
import AuthZoneWrapper from 'app/components/AuthZoneWrapper';
import { logOut } from 'app/services/oauthservice';
import { makeSelectUser } from 'app/containers/LoginPage/selectors';
import { KeycloakManager } from 'app/services/keycloak';
import LockIcon from 'app/components/Icons/LockIcon';
import CustomButton from 'app/components/Button';
import { makeSelectAccount, makeSelectAccounts } from './selectors';
import { getAccountsAction, resetAccountsStatusAction, resetSetAccountStatusAction, setAccountAction } from './actions';

import './style.less';
import RightArrow from './icons/RightArrow';
import makeSelectRoles from '../Roles/selectors';

const keycloakManager = new KeycloakManager();

export function AccountsPage(props) {
  const {
    setAccount,
    rolesData,
    user,
    accountsState: { data: accounts, isFetching: isAccountsFetching, isDone: isAccountsDone },
    accountState: { isFetching: isAccountFetching, isError: accountSettingError, isDone: isAccountDone, account }
  } = props;

  const [mounted, setMounted] = useState(false);

  if (keycloakManager?.tokenParsed && rolesData?.roles && rolesData.noAccounts) {
    eraseAccountId();
  }
  useEffect(() => {
    const filteredAccounts = accounts?.filter((account) => {
      const accountFromUm = rolesData?.roles?.find((role) => role.client.externalId === account.id);
      const isBlocked = accountFromUm && accountFromUm.status === 'Заблокирован';
      const isDisabled = accountFromUm && accountFromUm.status === 'Отключен';
      if (isBlocked || isDisabled) {
        return false;
      }
      return true;
    });

    if (filteredAccounts?.length === 1) {
      setAccount(filteredAccounts[0].id, true);
      setMounted(false);
      return;
    }
    setMounted(true);
  }, [accounts, rolesData?.roles, setAccount]);

  const isShowingLoader =
    !mounted ||
    account ||
    !keycloakManager.authenticated ||
    isAccountsFetching ||
    isAccountFetching ||
    (!user && !rolesData?.roles) ||
    (!rolesData.noAccounts && !isAccountsDone && !isAccountDone) ||
    (!accountSettingError && (isAccountsFetching || isAccountFetching));

  if (isShowingLoader) {
    return (
      <div data-testid="AccountsPage" className="AccountsPage">
        <Loader />
      </div>
    );
  }
  return (
    <div data-testid="AccountsPage" className="AccountsPage">
      <AuthZoneWrapper>
        <Text className="Title" variant="pageTitle">
          Выберите организацию для входа
        </Text>
        {rolesData.noAccounts && (
          <div style={{ marginBottom: 16 }}>Ни одна компания на данный момент не подключила Вас к своему кабинету.</div>
        )}
        {rolesData.noAccounts && <CustomButton text="Выйти" className="exit" onClick={logOut} />}
        {!isShowingLoader && accountSettingError && <div className="error">{accountSettingError.message}</div>}
        {!isShowingLoader &&
          accounts?.map((account) => {
            const accountFromUm = rolesData?.roles?.find((role) => role.client.externalId === account.id);
            const isBlocked = accountFromUm && accountFromUm.status === 'Заблокирован';
            const isEmptyRoles = !accountFromUm?.roles.length && !user?.clientIds?.includes(account.id);
            const isDisabled = accountFromUm && accountFromUm.status === 'Отключен';
            if (isDisabled || isEmptyRoles) {
              return null;
            }
            const classes = classNames({
              Account__button: true,
              blocked: isBlocked
            });
            return (
              <Button
                className={classes}
                variant="account"
                disabled={isBlocked}
                key={account.id}
                onClick={() => {
                  setMounted(false);
                  return setAccount(account.id, true);
                }}
              >
                <span className="AccountButton_content">
                  {isBlocked ? <LockIcon /> : null} {account.title}
                </span>
                <RightArrow />
              </Button>
            );
          })}
      </AuthZoneWrapper>
    </div>
  );
}

AccountsPage.propTypes = {
  setAccount: PropTypes.func,
  accountsState: PropTypes.object,
  user: PropTypes.object,
  rolesData: PropTypes.object,
  accountState: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  accountsState: makeSelectAccounts(),
  user: makeSelectUser(),
  accountState: makeSelectAccount(),
  rolesData: makeSelectRoles()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getAccounts: (accountsId) => dispatch(getAccountsAction(accountsId)),
    setAccount: (accountId, shouldEmit) => dispatch(setAccountAction(accountId, shouldEmit)),
    resetSetAccountStatus: () => dispatch(resetSetAccountStatusAction()),
    resetAccountsStatus: () => dispatch(resetAccountsStatusAction()),
    goTo: (url) => dispatch(push(url))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(AccountsPage);
