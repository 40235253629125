/*
 *
 * QueryFilters constants
 *
 */

export const CONTAINER_KEY = 'queryFilters';

export const SET_QUERY_FILTERS = 'app/queryFilters/SET_QUERY_FILTERS';
export const RESET_QUERY_FILTERS = 'app/queryFilters/RESET_QUERY_FILTERS';
