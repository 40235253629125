/**
 *
 * Toast
 *
 */

import React, { memo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.less';
import CloseIcon from './icons/CloseIcon';

const options = {
  autoClose: 5000,
  closeButton: CloseIcon,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: true,
  theme: "colored"
};

export function Toast() {
  return (
    <div className="Toast">
      <ToastContainer {...options} />
    </div>
  );
}

export default memo(Toast);
