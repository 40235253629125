import React from 'react';

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_637_24425)">
        <mask id="path-1-inside-1_637_24425" fill="#fff">
          <path d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8 12 4.7z"></path>
        </mask>
        <path
          fill="#525252"
          d="M12 4.7l-.7-.7L8 7.3 4.7 4l-.7.7L7.3 8 4 11.3l.7.7L8 8.7l3.3 3.3.7-.7L8.7 8 12 4.7z"
        ></path>
        <path
          fill="#525252"
          d="M12 4.7l.707.707.707-.707-.707-.707L12 4.7zm-.7-.7l.707-.707-.707-.707-.707.707L11.3 4zM8 7.3l-.707.707.707.707.707-.707L8 7.3zM4.7 4l.707-.707-.707-.707-.707.707L4.7 4zm-.7.7l-.707-.707-.707.707.707.707L4 4.7zM7.3 8l.707.707L8.714 8l-.707-.707L7.3 8zM4 11.3l-.707-.707-.707.707.707.707L4 11.3zm.7.7l-.707.707.707.707.707-.707L4.7 12zM8 8.7l.707-.707L8 7.286l-.707.707L8 8.7zm3.3 3.3l-.707.707.707.707.707-.707L11.3 12zm.7-.7l.707.707.707-.707-.707-.707L12 11.3zM8.7 8l-.707-.707L7.286 8l.707.707L8.7 8zm4.007-4.007l-.7-.7-1.414 1.414.7.7 1.414-1.414zm-2.114-.7l-3.3 3.3 1.414 1.414 3.3-3.3-1.414-1.414zm-1.886 3.3l-3.3-3.3-1.414 1.414 3.3 3.3 1.414-1.414zm-4.714-3.3l-.7.7 1.414 1.414.7-.7-1.414-1.414zm-.7 2.114l3.3 3.3 1.414-1.414-3.3-3.3-1.414 1.414zm3.3 1.886l-3.3 3.3 1.414 1.414 3.3-3.3-1.414-1.414zm-3.3 4.714l.7.7 1.414-1.414-.7-.7-1.414 1.414zm2.114.7l3.3-3.3-1.414-1.414-3.3 3.3 1.414 1.414zm1.886-3.3l3.3 3.3 1.414-1.414-3.3-3.3-1.414 1.414zm4.714 3.3l.7-.7-1.414-1.414-.7.7 1.414 1.414zm.7-2.114l-3.3-3.3-1.414 1.414 3.3 3.3 1.414-1.414zm-3.3-1.886l3.3-3.3-1.414-1.414-3.3 3.3 1.414 1.414z"
          mask="url(#path-1-inside-1_637_24425)"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_637_24425">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
