import { RootState } from 'types/RootState';
import { createSelector } from 'reselect';
import { initialState } from '.';
import { CONTAINER_KEY } from '../constants';

/**
 * Direct selector to the forbidden state domain
 */

const selectForbiddenDomain = (state: RootState) => state[CONTAINER_KEY] || initialState;

const makeSelectForbiddenPageState = () => createSelector(selectForbiddenDomain, (substate) => substate);

const makeSelectForbidden = () => createSelector(selectForbiddenDomain, (substate) => substate.forbidden);

export default makeSelectForbiddenPageState;
export { selectForbiddenDomain, makeSelectForbiddenPageState, makeSelectForbidden };
