/**
 *
 * Link
 *
 */

import React, { ReactNode } from 'react';
import { Link as NativeLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.less';

interface Props {
  to: string;
  className?: string;
  children: ReactNode;
}

function Link(props: Props) {
  const { to, children, ...rest } = props;
  return (
    <NativeLink to={to} className="Link" data-testid="Link" {...rest}>
      {children}
    </NativeLink>
  );
}

export default Link;
