/*
 *
 * LoginPage reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import {
  LOGIN_FAIL_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGIN_ACTION,
  // LOGOUT_ACTION,
  LOGOUT_FAIL_ACTION,
  LOGOUT_SUCCESS_ACTION,
  SET_USER_SUCCESS_ACTION,
  SET_USER_FAIL_ACTION,
  SET_USER_ACTION,
  CLEAN_LOGIN_ERROR_ACTION
} from './constants';

export const initialState = {
  user: null,
  isFetching: false,
  isDone: false,
  error: null,
  setUserStatus: createStateStatus()
};

/* eslint-disable default-case, no-param-reassign */
const loginPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_ACTION:
        draft.isFetching = true;
        break;
      case LOGIN_SUCCESS_ACTION:
        draft.isFetching = false;
        draft.user = action.payload.user;
        break;
      case LOGIN_FAIL_ACTION:
        draft.isFetching = false;
        draft.error = action.payload.error;
        break;
      case CLEAN_LOGIN_ERROR_ACTION:
        draft.error = null;
        break;
      // case LOGOUT_ACTION:
      //   console.log(LOGOUT_ACTION);
      //   break;
      case LOGOUT_SUCCESS_ACTION:
        draft.user = null;
        break;
      case LOGOUT_FAIL_ACTION:
        draft.error = action.payload.error;
        break;
      case SET_USER_ACTION:
        draft.setUserStatus.isFetching = true;
        break;
      case SET_USER_SUCCESS_ACTION:
        draft.user = JSON.parse(JSON.stringify(action.payload.user));
        draft.setUserStatus.isFetching = false;
        draft.setUserStatus.isDone = true;
        break;
      case SET_USER_FAIL_ACTION:
        draft.error = action.payload.error;
        draft.setUserStatus.isFetching = false;
        draft.user = {};
        break;
    }
  });

export default loginPageReducer;
