/*
 *
 * GlobalSearch constants
 *
 */

export const CONTAINER_KEY = 'globalSearch';

export const GET_GLOBAL_SEARCH = 'app/globalSearch/GET_GLOBAL_SEARCH';
export const GET_GLOBAL_SEARCH_SUCCESS = 'app/globalSearch/GET_GLOBAL_SEARCH_SUCCESS';
export const GET_GLOBAL_SEARCH_FAIL = 'app/globalSearch/GET_GLOBAL_SEARCH_FAIL';
export const RESET_GLOBAL_SEARCH_STATUS = 'app/globalSearch/RESET_GLOBAL_SEARCH_STATUS';

export enum globalSearchFilterNames {
  All = 'all',
  CatalogElements = 'catalogElements',
  ServiceAgreements = 'serviceAgreements',
  Clients = 'clients',
  Tasks = 'tasks'
}

export enum globalSearchResultTypes {
  client = 'client',
  providerElement = 'providerElement',
  supplierElement = 'supplierElement',
  catalog = 'catalog',
  task = 'task',
  order = 'order',
  requirement = 'requirement',
  agreement = 'agreement',
  user = 'user'
}

export const globalSearchSwitchNames = {
  [globalSearchFilterNames.All]: 'Все',
  [globalSearchFilterNames.CatalogElements]: 'Позиции',
  [globalSearchFilterNames.ServiceAgreements]: 'Договоры',
  [globalSearchFilterNames.Clients]: 'Клиенты',
  [globalSearchFilterNames.Tasks]: 'Задачи'
};
