/*
 *
 * Roles constants
 *
 */

import { ROUTE_NAMES } from 'app/containers/App/constants';

export const SET_ROLES_DATA = 'app/Roles/SET_ROLES_DATA';

export const CONTAINER_KEY = 'ROLES';

export const JEST_RESTRICTED_PATHS = [ROUTE_NAMES.AUTH_LOGIN];

export const ADMIN_RESTRICTED_PATHS = [ROUTE_NAMES.AUTH_LOGIN];

export const ROLE_NAMES = {
  UM_ADMIN: 'UM_ADMIN',
  SUPPLY_MANAGER: 'SUPPLY_MANAGER',
  GENERAL_SALE_MANAGER: 'GENERAL_SALE_MANAGER',
  SALE_MANAGER: 'SALE_MANAGER',
  CLIENT_MANAGER: 'CLIENT_MANAGER',
  undefined: 'ADMIN'
};

export const ROLES_CODES = {
  UM_ADMIN: 'RO001',
  SUPPLY_MANAGER: 'RO005'
};

export const PERMISSION_NAMES = {
  TENDER: 'TENDER',
  PRICING: 'PRICING',
  TCP_PUBLICATION: 'TCP_PUBLICATION',
  SUPPLIES_VIEWING: 'SUPPLIES_VIEWING',
  LOGISTICS: 'LOGISTICS',
  PAYMENT: 'PAYMENT',
  SUPPLY_CONTRACT_MANAGING: 'SUPPLY_CONTRACT_MANAGING',
  SUPPLY_CONTRACT_VIEWING: 'SUPPLY_CONTRACT_VIEWING',
  PRICE_AGREEMENT_MANAGING: 'PRICE_AGREEMENT_MANAGING',
  CONSIGNMENT_MANAGING: 'CONSIGNMENT_MANAGING',
  ORIGIN_DOCUMENTS_MANAGING: 'ORIGIN_DOCUMENTS_MANAGING'
};

export const PERMISSION_CODES = {
  [PERMISSION_NAMES.TENDER]: 'PE001',
  [PERMISSION_NAMES.PRICING]: 'PE002',
  [PERMISSION_NAMES.TCP_PUBLICATION]: 'PE003',
  [PERMISSION_NAMES.SUPPLIES_VIEWING]: 'PE004',
  [PERMISSION_NAMES.LOGISTICS]: 'PE005',
  [PERMISSION_NAMES.PAYMENT]: 'PE006',
  [PERMISSION_NAMES.SUPPLY_CONTRACT_MANAGING]: 'PE007',
  [PERMISSION_NAMES.SUPPLY_CONTRACT_VIEWING]: 'PE008',
  [PERMISSION_NAMES.PRICE_AGREEMENT_MANAGING]: 'PE009',
  [PERMISSION_NAMES.CONSIGNMENT_MANAGING]: 'PE010',
  [PERMISSION_NAMES.ORIGIN_DOCUMENTS_MANAGING]: 'PE011'
};

export const RESTRICTED_PATHS = {
  [ROLE_NAMES.ADMIN]: ADMIN_RESTRICTED_PATHS,
  [ROLE_NAMES.JEST]: ADMIN_RESTRICTED_PATHS,
  [ROLE_NAMES.JEST]: JEST_RESTRICTED_PATHS,
  [ROLE_NAMES.TEST]: []
};
