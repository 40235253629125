/**
 *
 * ControlFieldError
 *
 */

import { Box } from '@theme-ui/components';
import React from 'react';
import './style.less';

interface Props {
  text: string;
}

function ControlFieldError({ text }: Props) {
  return (
    <Box className="ControlFieldError" mt={1} data-testid="error">
      <span data-testid="errorField" className="ControlFieldError__text">
        {text}
      </span>
    </Box>
  );
}

export default ControlFieldError;
