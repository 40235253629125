/**
 *
 * Forbidden
 *
 */

import React, { memo, FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
import { getAccountId } from 'utils/accountId/accountIdManager';
import { makeSelectForbidden } from './slice/selectors';
import { forbiddenActions } from './slice';

import './style.less';
import { makeSelectAccount } from '../AccountsPage/selectors';
import makeSelectRoles from '../Roles/selectors';
import { ROUTE_NAMES } from '../App/constants';

interface OwnProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;
type ForbiddenPageProps = OwnProps & PropsFromRedux;

export const ForbiddenPage: FC<ForbiddenPageProps> = (props) => {
  const { accountState: { data: account } = {}, rolesState } = props;
  const history = useHistory();
  if (!account || !getAccountId() || !rolesState?.roles) {
    return null;
  }
  if (rolesState && rolesState.noAccounts) {
    history.push({
      pathname: ROUTE_NAMES.ACCOUNTS
    });
  }
  if (rolesState && !rolesState.isAppRestrictedByRoles) {
    history.push({
      pathname: ROUTE_NAMES.COMPANY
    });
  }
  return (
    <div data-testid="ForbiddenPage" className="ForbiddenPage">
      <div className="ForbiddenPage__content">
        <FaExclamationTriangle className="ForbiddenPage__icon" />
        <h1 className="ForbiddenPage__title">Доступ запрещен</h1>
        <p className="ForbiddenPage__text">
          У вашей учетной записи нет доступа к данному приложению под клиентом {account.title}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  forbiddenState: makeSelectForbidden(),
  rolesState: makeSelectRoles(),
  accountState: makeSelectAccount()
});

const mapDispatchToProps = {
  getForbidden: forbiddenActions.getForbiddenAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<FC<OwnProps>>(connector, memo)(ForbiddenPage);
