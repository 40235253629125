import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { CONTAINER_KEY } from './constants';

/**
 * Direct selector to the accounts state domain
 */

const selectAccountsDomain = (state) => state[CONTAINER_KEY] || initialState;

const makeSelectAccountsPageState = () => createSelector(selectAccountsDomain, (substate) => substate);

const makeSelectAccounts = () => createSelector(selectAccountsDomain, (substate) => substate.accounts);

const makeSelectAccount = () => createSelector(selectAccountsDomain, (substate) => substate.account);

const makeSelectAccountId = () => createSelector(selectAccountsDomain, (substate) => substate.account.data?.id);

const makeSelectAccountBaseRole = () => createSelector(selectAccountsDomain, (substate) => substate.accountBaseRole);

export default makeSelectAccountsPageState;
export {
  makeSelectAccountBaseRole,
  makeSelectAccount,
  selectAccountsDomain,
  makeSelectAccountsPageState,
  makeSelectAccounts,
  makeSelectAccountId
};
