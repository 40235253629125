/*
 *
 * Toast actions
 *
 */

import { SHOW_TOAST_ACTION } from './constants';

// const payload = {
//   type,
//   message,
//   callback,
//   callbackButtonName,
// };
export function showToastAction(payload) {
  return {
    type: SHOW_TOAST_ACTION,
    payload
  };
}
