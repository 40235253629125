/**
 *
 * Products
 *
 */

import React, { memo, FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Box, Text } from 'theme-ui';
import Switch from 'app/components/Switch';
import { WithLoader } from 'app/components/WithLoader';
import { makeSelectProducts } from './slice/selectors';
import { productsActions } from './slice';

import './style.less';

import { ProductCard } from './components/ProductCard';
import { PRODUCTS_TABS, PRODUCT_CATEGORY } from './constants';
import makeSelectRoles from '../Roles/selectors';
import { makeSelectAccount } from '../AccountsPage/selectors';
import { makeSelectUser } from '../LoginPage/selectors';

const getFilteredProducts = (products, isPlatformOwner, category) => {
  const filtered = products?.filter((product) => {
    const result = isPlatformOwner && product.product?.payload?.tsProduct?.type === 'admin';
    if (category === PRODUCT_CATEGORY.ADMINISTRATION) {
      return result;
    }
    return !result;
  });

  return filtered;
};

const findRoles = (rolesData, code) => {
  if (Array.isArray(rolesData)) {
    return rolesData
      ?.map((data) => data.roles)
      ?.flat()
      ?.filter((role) => role.products[0].code === code);
  }
  return [];
};

const findRolesByProductCode = (rolesData, productCode) => {
  const roles = rolesData?.roles;
  const isPlatformOwner = rolesData?.isPlatformOwner;
  if (productCode === 'PR004' || productCode === 'PR009' || productCode === 'PR008') {
    if (isPlatformOwner) {
      return [true];
    }
  }
  return findRoles(roles, productCode);
};

interface OwnProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;
type ProductsPageProps = OwnProps & PropsFromRedux;

export const ProductsPage: FC<ProductsPageProps> = (props) => {
  const { getProducts, productsState, rolesData, accountsState, user } = props;

  const { data: products } = productsState;
  const { data: account } = accountsState;
  const [productCategory, setProductCategory] = useState(PRODUCT_CATEGORY.TS_PRODUCT);
  React.useEffect(() => {
    if (productCategory === PRODUCT_CATEGORY.ADMINISTRATION) {
      getProducts(PRODUCT_CATEGORY.TS_PRODUCT);
      return;
    }
    getProducts(productCategory);
  }, [getProducts, productCategory, account]);

  const handleProductsTabChange = (value) => {
    if (value !== productCategory) {
      setProductCategory(value);
    }
  };

  const isNotPanel = (product) =>
    productCategory === PRODUCT_CATEGORY.PARTNER_PRODUCT || (product && product?.payload?.product?.orderPosition !== 1);

  return (
    <div data-testid="ProductsPage" className="ProductsPage">
      <Helmet>
        <meta name="description" content="Description of Products" />
      </Helmet>
      <Box mb={7} className="Page__header">
        <Text variant="pageTitle">Продукты</Text>
      </Box>

      <Box ml={5}>
        <Switch
          id="status"
          items={PRODUCTS_TABS.filter((tab) => {
            if (tab.value === PRODUCT_CATEGORY.ADMINISTRATION) {
              return rolesData?.isPlatformOwner;
            }
            return true;
          })}
          onClick={handleProductsTabChange}
          thirdVariant
        />
      </Box>

      <WithLoader states={[productsState]}>
        <Box sx={{ padding: '40px', display: 'flex', gap: '40px', flexWrap: 'wrap' }}>
          {getFilteredProducts(products?.activeProducts, rolesData?.isPlatformOwner, productCategory)?.length
            ? getFilteredProducts(products?.activeProducts, rolesData?.isPlatformOwner, productCategory)?.map(
                (item) => {
                  const isExistKeycloakButNotInUm =
                    !!rolesData?.roles &&
                    !(rolesData?.roles as any)?.find(
                      (role) =>
                        role?.client?.externalId === account.id &&
                        role?.roles?.find((role) =>
                          role?.products?.find((product) => product.code === item?.product?.payload?.tsProduct?.code)
                        ) &&
                        user?.clientIds?.includes(account.id)
                    ) &&
                    user?.clientIds &&
                    user?.clientIds.includes(account.id);
                  return isNotPanel(item?.product) &&
                    (isExistKeycloakButNotInUm ||
                      findRolesByProductCode(rolesData || [], item?.product?.payload?.tsProduct?.code)?.length) ? (
                    <ProductCard
                      key={item.product.id}
                      product={item.product}
                      subscription={item.subscription}
                      productCategory={productCategory}
                    />
                  ) : null;
                }
              )
            : null}
        </Box>
      </WithLoader>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  productsState: makeSelectProducts(),
  rolesData: makeSelectRoles(),
  user: makeSelectUser(),
  accountsState: makeSelectAccount()
});

const mapDispatchToProps = {
  getProducts: productsActions.getProductsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<FC<OwnProps>>(connector, memo)(ProductsPage);
