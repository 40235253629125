import { io } from 'socket.io-client';
import { push } from 'connected-react-router';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import { getAccountId, setAccountId } from 'utils/accountId/accountIdManager';
import { store } from 'store/configureStore';
import { APP_CONFIG } from 'app/config';
import { setRolesDataAction } from 'app/containers/Roles/actions';
import { setAccountAction } from 'app/containers/AccountsPage/actions';
import { keycloakManager } from './keycloak';

let socket;

// eslint-disable-next-line import/no-mutable-exports
export let connected = false;

const setAccountIdFunc = (data) => {
  const { accountId, rolesData = [], productsData } = data;
  if (!accountId) {
    store.dispatch(push(ROUTE_NAMES.ACCOUNTS));
    store.dispatch(setRolesDataAction({ productsData, rolesData }));
    return;
  }
  const localAccountId = getAccountId();
  if (accountId && accountId !== localAccountId) {
    store.dispatch(setAccountAction(data.accountId));
    setAccountId(data.accountId);
  }

  if (!accountId && localAccountId) {
    socket.emit('onAccountChange', {
      accountId: localAccountId,
      email: keycloakManager.tokenParsed?.email
    });
  }
  store.dispatch(setRolesDataAction({ productsData, rolesData }));
};

const addListeners = (socket) => {
  socket.on('onConnection', setAccountIdFunc);
};

export const connect = () => {
  if (!connected && keycloakManager.authenticated) {
    socket = io(APP_CONFIG.socketBrokerURI as string, {
      auth: (cb) => {
        cb({ token: keycloakManager.token });
      }
    });
    addListeners(socket);
    socket.emit('onConnection', { email: keycloakManager.tokenParsed?.email });
    connected = true;
  }
};

export const changeAccountId = (accountId: string) => {
  keycloakManager?.tokenParsed &&
    socket.emit('onAccountChange', {
      accountId,
      email: keycloakManager.tokenParsed?.email
    });
};
