import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { CONTAINER_KEY } from './constants';

/**
 * Direct selector to the settings state domain
 */

const selectSettingsDomain = (state) => state[CONTAINER_KEY] || initialState;

const makeSelectSettingsPageState = () => createSelector(selectSettingsDomain, (substate) => substate);

const makeSelectSettings = () => createSelector(selectSettingsDomain, (substate) => substate.settings);

const makeSelectPassword = () => createSelector(selectSettingsDomain, (substate) => substate.password);

const makeSelectBaseRole = () => createSelector(selectSettingsDomain, (substate) => substate.baseRole);

export default makeSelectSettingsPageState;
export {
  makeSelectBaseRole,
  makeSelectPassword,
  selectSettingsDomain,
  makeSelectSettingsPageState,
  makeSelectSettings
};
