/**
 *
 * DropdownMenu
 *
 */

import React, { FC, memo } from 'react';
import { Menu, MenuItem, MenuButton, ClickEvent } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import ArrowDownWhiteIcon from './icons/arrowDownWhite';
import ArrowDownBlueIcon from './icons/arrowDownBlue';
import Icon from './icons/icon';

import './style.less';

interface Option {
  action?: (event: ClickEvent) => void;
  disabled?: boolean;
  label: string | FC;
  value?: string | number | null;
}

interface DropdownMenuProps {
  options: (Option | null)[];
  isDisabled?: boolean;
  align?: 'start' | 'center' | 'end';
  icon?: FC;
  renderAsButton?: boolean;
  keepOpenOnClick?: boolean;
  btnText?: string;
  btnClassName?: string;
}

function DropdownMenu(props: DropdownMenuProps) {
  const {
    options,
    isDisabled,
    align,
    icon: CustomIcon,
    keepOpenOnClick,
    renderAsButton,
    btnText = 'Действия',
    btnClassName = 'primary'
  } = props;
  const buttonIcon = CustomIcon ? <CustomIcon /> : <Icon />;

  const btnIcons = {
    ghost: <ArrowDownBlueIcon />,
    primary: <ArrowDownWhiteIcon />
  };

  const simpleButton = (
    <button type="button" className={`Button small ${btnClassName}`}>
      <span className="Button__text">{btnText}</span>

      <span className="Button__icon">{btnIcons[btnClassName]}</span>
    </button>
  );
  return !isDisabled ? (
    <Menu
      className="DropdownMenu"
      menuButton={renderAsButton ? simpleButton : <MenuButton>{buttonIcon}</MenuButton>}
      align={align}
      portal
    >
      {options.map((option) => {
        if (!option) {
          return null;
        }
        return (
          <MenuItem
            onClick={(e) => {
              if (keepOpenOnClick) {
                e.stopPropagation = true;
                e.keepOpen = true;
              }
              option.action && option.action(e);
            }}
            disabled={option.disabled}
            key={option.label.toString()}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  ) : null;
}

export default memo(DropdownMenu);
