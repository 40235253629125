/*
 *
 * EmailCodeValidation reducer
 *
 */
import produce from 'immer';
import {
  CHECK_VALIDATION_CODE,
  CHECK_VALIDATION_CODE_FAIL,
  CHECK_VALIDATION_CODE_SUCCESS,
  GET_VALIDATION_CODE,
  GET_VALIDATION_CODE_FAIL,
  GET_VALIDATION_CODE_SUCCESS,
  RESET_VALIDATIONS
} from './constants';

export const initialState = {
  isFetching: false,
  error: null,
  result: undefined,
  mailSended: false
};

/* eslint-disable default-case, no-param-reassign */
const emailCodeValidationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_VALIDATION_CODE:
      case CHECK_VALIDATION_CODE:
        draft.result = undefined;
        draft.mailSended = false;
        draft.isFetching = true;
        break;
      case GET_VALIDATION_CODE_SUCCESS:
        draft.isFetching = false;
        draft.result = undefined;
        draft.mailSended = true;
        break;
      case CHECK_VALIDATION_CODE_SUCCESS:
        draft.isFetching = false;
        draft.result = action.payload.result;
        break;
      case GET_VALIDATION_CODE_FAIL:
      case CHECK_VALIDATION_CODE_FAIL:
        draft.isFetching = false;
        draft.isError = action.payload.error;
        break;
      case RESET_VALIDATIONS:
        draft.result = undefined;
        draft.mailSended = false;
        draft.error = null;
        draft.isFetching = false;
        break;
    }
  });

export default emailCodeValidationReducer;
