import React from 'react';

export const useModalState = ({ initialOpen = false } = {}) => {
  const [isOpen, setIsOpen] = React.useState(initialOpen);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return { onOpen, onClose, isOpen, onToggle };
};

export const useModalIdState = () => {
  const [modalId, setModalId] = React.useState(undefined);

  const openModal = (id) => {
    setModalId(id);
  };

  const closeModal = () => {
    setModalId(undefined);
  };

  return { openModal, closeModal, modalId };
};
