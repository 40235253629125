/*
 *
 * Toast constants
 *
 */

import { toast } from 'react-toastify';

export const TOAST_TYPES = {
  INFO: toast.TYPE.INFO,
  DEFAULT: toast.TYPE.DEFAULT,
  ERROR: toast.TYPE.ERROR,
  SUCCESS: toast.TYPE.SUCCESS,
  WARNING: toast.TYPE.WARNING
};

export const DEFAULT_ACTION = 'app/Toast/DEFAULT_ACTION';

export const SHOW_TOAST_ACTION = 'app/Toast/SHOW_TOAST_ACTION';

export const CONTAINER_KEY = 'toastContainer';
