/**
 *
 * EventPaymentsInfo
 *
 */

import Link from 'app/components/Link';
import React, { memo } from 'react';
import { Box, Text } from 'theme-ui';
import { backToFrontFormatDate } from 'utils/dateUtils';
import { getOrderUrl } from 'utils/urls/getEntityUrl';
import { PaymentEvent, PaymentEventOrder } from 'app/containers/EventsPage/types';
import { Scroll } from 'app/components/Scroll';
import { formatCurrency } from 'utils/currency/formatCurrency';
import CloseIcon from '../icons/close';
import IncomeIcon from '../icons/income';

import './style.less';

interface EventPaymentsInfoProps {
  onClose: () => void;
  paymentsInfo: PaymentEvent;
}

export function EventPaymentsInfo(props: EventPaymentsInfoProps) {
  const { onClose, paymentsInfo } = props;
  const { date, income, ordersByClient } = paymentsInfo;

  return (
    <div data-testid="EventPaymentsInfo" className="EventPaymentsInfo">
      <Box mb={6} className="EventPaymentsInfo__header">
        <Box className="title">{backToFrontFormatDate(date, 'dd MMMM yyyy')}</Box>
        <Box ml={5} className="closeIcon" onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>
      <Scroll maxHeight="86vh">
        <Box className="EventPaymentsInfo__content">
          {Object.entries(ordersByClient).map(([clientName, eventOrders]: [string, PaymentEventOrder[]]) => (
            <Box className="paymentsInfo">
              <Box className="client">
                <Text>{clientName}</Text>
                <Box className={`icon ${income ? 'income' : 'outcome'}`}>
                  <IncomeIcon />
                </Box>
              </Box>

              {eventOrders.map((order) => {
                const { id: orderId, title: orderName, payments } = order;
                return (
                  <Box mb={4} className="eventOrder">
                    <Box mb={2} className="orderName">
                      <Link to={getOrderUrl(orderId)}>{orderName}</Link>
                    </Box>
                    <Box className="payments">
                      {payments?.map((payment) => (
                        <Box key={payment.id} className="payment">
                          <Text variant="text02_size_14">{payment.paymentStage.title}</Text>
                          <Box className={`value ${income ? 'income' : 'outcome'}`}>
                            {formatCurrency({ value: payment.amountInRub, currency: 'RUB' })}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      </Scroll>
    </div>
  );
}

export default memo(EventPaymentsInfo);
