/*
 *
 * Accounts reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  RESET_ACCOUNTS_STATUS,
  SET_ACCOUNT,
  SET_ACCOUNT_SUCCESS,
  SET_ACCOUNT_FAIL,
  RESET_SET_ACCOUNT_STATUS,
  PATCH_ACCOUNT_BASE_ROLE,
  PATCH_ACCOUNT_BASE_ROLE_SUCCESS,
  PATCH_ACCOUNT_BASE_ROLE_FAIL,
  RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS
} from './constants';

export const initialState = {
  accountBaseRole: {
    ...createStateStatus()
  },
  account: {
    ...createStateStatus(),
    data: undefined
  },
  accounts: {
    ...createStateStatus(),
    data: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const accountsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PATCH_ACCOUNT_BASE_ROLE:
        draft.accountBaseRole.isFetching = true;
        draft.accountBaseRole.isError = false;
        break;
      case PATCH_ACCOUNT_BASE_ROLE_SUCCESS:
        draft.account.data.payload.client.baseRole = action.payload.accountBaseRole;
        draft.accountBaseRole.isFetching = false;
        draft.accountBaseRole.isDone = true;
        break;
      case PATCH_ACCOUNT_BASE_ROLE_FAIL:
        draft.accountBaseRole.isFetching = false;
        draft.accountBaseRole.isDone = true;
        draft.accountBaseRole.isError = action.payload.error;
        draft.account.data.payload.client.baseRole = action.payload.prevBaseRole;
        break;
      case RESET_PATCH_ACCOUNT_BASE_ROLE_STATUS:
        draft.accountBaseRole.isDone = false;
        draft.accountBaseRole.isFetching = false;
        draft.accountBaseRole.isError = null;
        break;

      case SET_ACCOUNT:
        draft.account.isFetching = true;
        draft.account.isError = false;
        break;setAccountId
      case SET_ACCOUNT_SUCCESS:
        draft.account.isFetching = false;
        draft.account.isDone = true;
        draft.account.data = action.payload.account;
        break;
      case SET_ACCOUNT_FAIL:
        draft.account.isFetching = false;
        draft.account.isDone = true;
        draft.account.isError = action.payload.error;
        break;
      case RESET_SET_ACCOUNT_STATUS:
        draft.account.isDone = false;
        draft.account.isFetching = false;
        draft.account.isError = null;
        break;

      case GET_ACCOUNTS:
        draft.accounts.isFetching = true;
        draft.accounts.isError = false;
        break;
      case GET_ACCOUNTS_SUCCESS:
        draft.accounts.isFetching = false;
        draft.accounts.isDone = true;
        draft.accounts.data = action.payload.accounts;
        break;
      case GET_ACCOUNTS_FAIL:
        draft.accounts.isFetching = false;
        draft.accounts.isDone = true;
        draft.accounts.isError = action.payload.error;
        break;
      case RESET_ACCOUNTS_STATUS:
        draft.accounts.isFetching = false;
        draft.accounts.isDone = false;
        draft.accounts.isError = null;
        draft.accounts.data = undefined;
        break;
    }
  });

export default accountsReducer;
