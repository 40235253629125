/**
 *
 * GlobalSearch
 *
 */

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { StateItem } from 'types/redux/state';
import Select, { GroupBase, MenuListProps } from 'react-select';
import { debounce, get } from 'lodash';
import Switch from 'app/components/Switch';
import { Element } from 'types/platform/element';
import * as urlFuncs from 'utils/urls/getEntityUrl';
import { ELEMENT_ROOT_CATEGORIES } from 'app/containers/App/constants';
import SearchIcon from './icons/search';
import { useOnClickOutside } from 'utils/hooks/outsideClick';
import Link from 'app/components/Link';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { makeSelectGlobalSearch } from './selectors';
import { getGlobalSearchAction, resetGlobalSearchStatusAction } from './actions';
import { GlobalSearchResponse } from './types';
import { globalSearchFilterNames, globalSearchResultTypes, globalSearchSwitchNames } from './constants';

import './style.less';
import ElementIcon from './icons/ElementIcon';
import ClientIcon from './icons/ClientIcon';
import AgreementIcon from './icons/AgreementIcon';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    searchData?: GlobalSearchResponse;
    closeHandler: (forceClose?: boolean) => void;
    isDone?: boolean;
  }
}

const getSwitchTitle = (propertyName?: string) => {
  if (!propertyName) {
    return globalSearchSwitchNames[globalSearchFilterNames.All];
  }

  if (globalSearchSwitchNames[propertyName]) {
    return globalSearchSwitchNames[propertyName];
  }

  return propertyName;
};

const getSwitchItems = (searchData: GlobalSearchResponse | object) => {
  const searchKeys = Object.keys(searchData);
  const items = searchKeys.map((key) => ({
    name: getSwitchTitle(key),
    value: key,
    count: searchData[key].length
  }));
  items.unshift({
    name: globalSearchSwitchNames[globalSearchFilterNames.All],
    value: globalSearchFilterNames.All,
    count: Object.values(searchData).flat().length
  });
  return items;
};

const getResultType = (element) => {
  if (element.payload.supplierCatalog) {
    return globalSearchResultTypes.supplierElement;
  }
  if (element.payload.providerCatalog) {
    return globalSearchResultTypes.providerElement;
  }
  if (element.payload.client) {
    return globalSearchResultTypes.client;
  }
  if (element.payload.serviceAgreement) {
    return globalSearchResultTypes.agreement;
  }
  return '';
};

const getIcon = (element) => {
  const type = getResultType(element);
  const icons = {
    [globalSearchResultTypes.providerElement]: <ElementIcon />,
    [globalSearchResultTypes.supplierElement]: <ElementIcon />,
    [globalSearchResultTypes.client]: <ClientIcon />,
    [globalSearchResultTypes.agreement]: <AgreementIcon />
  };
  if (icons[type]) {
    return icons[type];
  }
  return null;
};

const getLink = (element?) => {
  const type = getResultType(element);

  // if (type === globalSearchResultTypes.supplierElement) {
  //   return urlFuncs.getElementUrl(element.id, ELEMENT_ROOT_CATEGORIES.SUPPLIER_CATALOG);
  // }
  // if (type === globalSearchResultTypes.providerElement) {
  //   return urlFuncs.getElementUrl(element.id, ELEMENT_ROOT_CATEGORIES.PROVIDER_CATALOG);
  // }
  // if (type === globalSearchResultTypes.client) {
  //   return urlFuncs.getClientUrl(element.id);
  // }
  // if (type === globalSearchResultTypes.agreement) {
  //   return urlFuncs.getCategoryAgreementUrl(element.id);
  // }
  return '';
};

const getMeta = (element?) => {
  const type = getResultType(element);

  if (type === globalSearchResultTypes.supplierElement || type === globalSearchResultTypes.providerElement) {
    return `в ${element.categories[0].title}`;
  }

  if (type === globalSearchResultTypes.client) {
    return get(element, 'payload.client.status.title');
  }
  if (type === globalSearchResultTypes.agreement) {
    return `#${get(element, 'payload.serviceAgreement.client.title')}`;
  }
  return '';
};

const MenuList = (props: MenuListProps<{}, false, GroupBase<{}>>) => {
  const { selectProps, className } = props;
  const { searchData = {}, closeHandler, menuIsOpen, inputValue, isLoading, isDone } = selectProps;
  const ref = useRef(null);

  useOnClickOutside(ref, () => menuIsOpen && closeHandler(), ['Input']);

  const [switchValue, setSwitchValue] = useState(globalSearchFilterNames.All);
  const isOptionsEmpty = useMemo(() => !searchData || !Object.values(searchData).flat().length, [searchData]);
  const switchItems = useMemo(() => getSwitchItems(searchData), [searchData]);
  const resultItems: Element<unknown>[] = useMemo(() => {
    if (switchValue === globalSearchFilterNames.All) {
      return Object.values(searchData).flat();
    }
    return searchData[switchValue];
  }, [searchData, switchValue]);

  useEffect(() => {}, [searchData]);
  const rowVirtualizer = useWindowVirtualizer({
    count: resultItems.length,
    estimateSize: () => 50,
    overscan: 10
  });

  if (isOptionsEmpty) {
    if (inputValue?.length < 3 || isLoading || !isDone) {
      return null;
    }

    return (
      <div ref={ref} className={`MenuList empty ${className}`}>
        <Switch id="globalSearch" settings disableUrlSync items={switchItems} onClick={setSwitchValue} />
        <div className="emptyMessage">Ничего не найдено</div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: `700px`,
        minWidth: '606px',
        overflow: 'auto'
      }}
      ref={ref}
      className={`MenuList ${className}`}
    >
      <Switch id="globalSearch" settings disableUrlSync items={switchItems} onClick={setSwitchValue} />
      <div className="MenuList__results MenuListResults">
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const element = resultItems[virtualItem.index];
          const link = getLink(element);
          return (
            <div
              onClick={() => closeHandler(true)}
              style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                transform: `translateY(${virtualItem.start}px)`
              }}
            >
              <Link key={link} to={link} className="MenuListResults__item MenuListResultsItem">
                <div className="MenuListResultsItem__pic">{getIcon(element)}</div>
                <div onClick={() => closeHandler(true)} className="MenuListResultsItem__info MenuListResultsItemInfo">
                  <div className="MenuListResultsItemInfo__title">{element.title}</div>
                  <div className="MenuListResultsItemInfo__meta">{getMeta(element)}</div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface GlobalSearchProps {
  getGlobalSearch: (query: string) => void;
  resetGlobalSearchStatus: () => void;
  globalSearch: StateItem<GlobalSearchResponse>;
}

export function GlobalSearch(props: GlobalSearchProps) {
  const {
    getGlobalSearch,
    globalSearch: { data: searchData, isFetching: isSearchDataFetching, isDone } = {},
    resetGlobalSearchStatus
  } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const selectRef = useRef<HTMLSelectElement>(null);
  const handleSelectChange = useCallback(
    debounce((value) => {
      if (value?.length > 2) {
        getGlobalSearch(value);
      }
    }, 600),
    []
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const renderSelect = useCallback(
    () => (
      <>
        <SearchIcon
          onClick={() => {
            setMenuIsOpen(true);
            return selectRef?.current?.focus();
          }}
        />
        <Select
          isSearchable
          className="GlobalSearch__select"
          placeholder="Что будем искать?"
          isLoading={isSearchDataFetching}
          options={[]}
          onMenuClose={() => {
            if (searchValue.length < 3) {
              resetGlobalSearchStatus();
            }
          }}
          closeHandler={(forceClose) => {
            if (forceClose) {
              setMenuIsOpen(false);
              return;
            }
            menuIsOpen && setMenuIsOpen(false);
          }}
          ref={selectRef as React.RefObject<any>}
          searchData={searchData}
          styles={{
            menu: () => ({ zIndex: 2, transform: 'translate-y(10px)' }),
            placeholder: (base, state) => ({
              ...base,
              display: state.isFocused ? 'none' : ''
            }),
            control: () => ({
              border: 'none',
              height: '24px',
              display: 'flex',
              padding: '0 15.5px'
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: () => ({ display: 'none' })
          }}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => {
            setMenuIsOpen(true);
          }}
          openMenuOnClick
          closeMenuOnSelect
          blurInputOnSelect={false}
          isDone={isDone}
          inputValue={searchValue}
          onFocus={() => {
            if (searchValue?.length > 2) {
              getGlobalSearch(searchValue);
            }
            setMenuIsOpen(true);
          }}
          onInputChange={(value, reason) => {
            if (reason.action === 'set-value' || reason.action === 'input-blur' || reason.action === 'menu-close') {
              return;
            }
            setSearchValue(value);
            handleSelectChange(value);
            if (searchValue.length < 2) {
              resetGlobalSearchStatus();
            }
          }}
          menuShouldScrollIntoView={false}
          captureMenuScroll={false}
          components={{
            MenuList,
            // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
            Menu: (props) => <div className="menu">{props.children}</div>
          }}
        />
      </>
    ),
    [isSearchDataFetching, searchData, searchValue, menuIsOpen, isDone]
  );

  return (
    <div data-testid="GlobalSearch" className="GlobalSearch">
      {renderSelect()}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  globalSearch: makeSelectGlobalSearch()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getGlobalSearch: (query: string) => dispatch(getGlobalSearchAction(query)),
    resetGlobalSearchStatus: () => dispatch(resetGlobalSearchStatusAction())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(GlobalSearch);
