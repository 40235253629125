import classnames from 'classnames';
import React from 'react';
import './style.less';

interface Props {
  white?: boolean;
  small?: boolean;
}

const Loader = ({ small, white = false }: Props) => {
  const loaderClasses = classnames({
    Loader: true,
    small: !!small
  });

  const ringClasses = classnames({
    'lds-ring': true,
    'lds-ring__white': !!white,
    small: !!small
  });

  return (
    <div className={loaderClasses} data-testid="Loader">
      <div className={ringClasses}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
