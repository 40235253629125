/* eslint-disable no-param-reassign */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createStateStatus } from 'utils/stateStatus';
import { EventsState, getEventsActionPayloadType, getPaymentEventsActionPayloadType } from './types';
import { Events, PaymentEvent } from '../types';

export const initialState: EventsState = {
  events: {
    ...createStateStatus<Events[]>()
  },
  paymentEvents: {
    ...createStateStatus<PaymentEvent[]>()
  }
  // [INSERT NEW ENTITY IN INITIAL STATE KEY ABOVE] < Needed for generating actions seamlessly< Needed for generating actions seamlessly
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getEventsAction(state, action: PayloadAction<getEventsActionPayloadType>) {
      state.events.isFetching = true;
      state.events.isError = null;
    },
    getEventsSuccessAction(state, action: PayloadAction<Events[]>) {
      state.events.isFetching = false;
      state.events.isDone = true;
      state.events.data = action.payload;
    },
    getEventsErrorAction(state, action: PayloadAction<Error>) {
      state.events.isFetching = false;
      state.events.isDone = true;
      state.events.isError = action.payload;
    },
    resetEventsStatusAction(state) {
      state.events.isFetching = false;
      state.events.isDone = false;
      state.events.isError = null;
      state.events.data = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchPaymentEventsAction(state, action: PayloadAction<getPaymentEventsActionPayloadType>) {
      state.paymentEvents.isFetching = true;
      state.paymentEvents.isError = null;
    },
    fetchPaymentEventsSuccessAction(state, action: PayloadAction<PaymentEvent[]>) {
      state.paymentEvents.isFetching = false;
      state.paymentEvents.isDone = true;
      state.paymentEvents.data = action.payload;
    },
    fetchPaymentEventsErrorAction(state, action: PayloadAction<Error>) {
      state.paymentEvents.isFetching = false;
      state.paymentEvents.isDone = true;
      state.paymentEvents.isError = action.payload;
    },
    resetPaymentEventsStatusAction(state) {
      state.paymentEvents.isFetching = false;
      state.paymentEvents.isDone = false;
      state.paymentEvents.isError = null;
      state.paymentEvents.data = undefined;
    }
    // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating actions seamlessly< Needed for generating actions seamlessly
  }
});

export const { actions: eventsActions, reducer, name } = slice;
