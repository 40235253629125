/**
 *
 * Events
 *
 */

import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Box, Text } from 'theme-ui';
import Switch from 'app/components/Switch';
import { makeSelectPaymentEvents } from './slice/selectors';
import { eventsActions } from './slice';

import './style.less';
import { EVENTS_PAGE_SWITCH_ITEMS, EVENT_TYPES } from './constants';
import { PaymentEventsTab } from './components/PaymentEventsTab';
import { ShipmentEventsTab } from './components/ShipmentEventsTab';

interface OwnProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;
type EventsPageProps = OwnProps & PropsFromRedux;

export const EventsPage: FC<EventsPageProps> = (props) => {
  const { getPaymentEvents, paymentEventsState } = props;

  const [activeTab, setActiveTab] = useState(EVENT_TYPES.PAYMENTS);

  const handleSwitchChange = (value: string) => {
    if (value !== activeTab) {
      setActiveTab(value);
    }
  };

  const tabs = {
    [EVENT_TYPES.PAYMENTS]: (
      <PaymentEventsTab getPaymentEvents={getPaymentEvents} paymentEventsState={paymentEventsState} />
    ),
    [EVENT_TYPES.SHIPMENTS]: <ShipmentEventsTab />
  };

  return (
    <div data-testid="EventsPage" className="EventsPage">
      <Helmet>
        <title>События</title>
        <meta name="description" content="Description of Events" />
      </Helmet>
      <Box mb={7} className="Page__header">
        <Text variant="pageTitle">Календарь событий</Text>
      </Box>

      <Box mb={7} className="Page__switches">
        <Switch items={EVENTS_PAGE_SWITCH_ITEMS} onClick={handleSwitchChange} />
      </Box>

      {tabs[activeTab]}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  paymentEventsState: makeSelectPaymentEvents()
});

const mapDispatchToProps = {
  getPaymentEvents: eventsActions.fetchPaymentEventsAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<FC<OwnProps>>(connector)(EventsPage);
