import { call, put, takeLatest } from '@redux-saga/core/effects';
import { GET_VALIDATION_CODE, CHECK_VALIDATION_CODE } from './constants';
import {
  getValidationCodeErrorAction,
  getValidationCodeSuccessAction,
  checkValidationCodeErrorAction,
  checkValidationCodeSuccessAction
} from './actions';
import { getValidationCodeApi, checkValidationCodeApi } from './api';

export function* getValidationCodeSaga({ payload }) {
  const { email } = payload;
  try {
    yield call(getValidationCodeApi, email);
    yield put(getValidationCodeSuccessAction());
  } catch (err) {
    yield put(getValidationCodeErrorAction(err));
  }
}

export function* checkValidationCodeSaga({ payload }) {
  try {
    const result = yield call(checkValidationCodeApi, payload);
    yield put(checkValidationCodeSuccessAction(result));
  } catch (err) {
    yield put(checkValidationCodeErrorAction(err));
  }
}

export default function* emailCodeValidationSaga() {
  yield takeLatest(GET_VALIDATION_CODE, getValidationCodeSaga);
  yield takeLatest(CHECK_VALIDATION_CODE, checkValidationCodeSaga);
}
