/* eslint-disable no-restricted-syntax */
export const digitalSeparator = (value?: string | number): string => {
  if (typeof value === 'undefined' || (typeof value !== 'string' && typeof value !== 'number')) {
    return '';
  }

  if (typeof value === 'string') {
    return Number(parseFloat(value).toFixed(2))?.toLocaleString('ru-RU') ?? '';
  }
  return Number(value?.toFixed(2))?.toLocaleString('ru-RU') ?? '';

  // const regExp = /\d+/g;
  // let newValue = (value as string | number).toString();
  // const iterator = newValue.matchAll(regExp);
  // let prevDelta = 0;
  // for (const match of iterator) {
  //   const [matchedString] = match;
  //   const matchedValueLength = matchedString.toString().length;
  //   const matchedIndex = match.index || 0;
  //   const separatedValue = parseInt(matchedString, 10).toLocaleString('ru-RU');
  //   newValue = removeFromString(newValue, matchedIndex + prevDelta, matchedValueLength);
  //   newValue = insert(newValue, matchedIndex + prevDelta, separatedValue);
  //   prevDelta += separatedValue.length - matchedValueLength;
  // }
  // return newValue;
};

const insert = (string: string, index: number, newString: unknown): string => {
  if (index > 0) {
    return string.substring(0, index) + newString + string.substring(index);
  }

  return newString + string;
};

const removeFromString = (string: string, removeFrom: number, removeCount: number) => {
  const array = Array.from(string);
  array.splice(removeFrom, removeCount);
  return array.join('');
};
