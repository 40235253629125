/*
 *
 * Modal constants
 *
 */

export const CONTAINER_KEY = 'modal';

export const OPEN_MODAL = `app/${CONTAINER_KEY}/OPEN_MODAL`;
export const CLOSE_MODAL = `app/${CONTAINER_KEY}/CLOSE_MODAL`;

export const MODALS_IDS = {
  SIMPLE_CONFIRMATION: 'SIMPLE_CONFIRMATION',
  ADD_REQUISITES: 'ADD_REQUISITES',
  EDIT_REQUISITES: 'EDIT_REQUISITES',
  DELETE_REQUISITES: 'DELETE_REQUISITES',
  PRODUCT_INFO: 'PRODUCT_INFO',
  BUY_PRODUCT: 'BUY_PRODUCT'
};
