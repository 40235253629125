/*
 *
 * Sidebar constants
 *
 */
import { ROUTE_NAMES } from 'app/containers/App/constants';

export const CONTAINER_KEY = 'Sidebar';
export const pageKey = 'Sidebar';

export const SIDEBAR_CLICK = `app/${pageKey}/SIDEBAR_CLICK`;

export const sidebarInitialValue = window.innerWidth >= 1312;

const getMenuIdFunc = () => {
  let id = 0;
  return () => {
    id += 1;
    return id;
  };
};

const getMenuId = getMenuIdFunc();

export const MENU_IDS = Object.keys(ROUTE_NAMES).reduce((prev, curr) => {
  // eslint-disable-next-line no-param-reassign
  prev[ROUTE_NAMES[curr]] = getMenuId();
  return prev;
}, {});

export const MENU_ITEMS = [
  {
    id: MENU_IDS[ROUTE_NAMES.PRODUCTS],
    name: 'Продукты',
    path: ROUTE_NAMES.PRODUCTS
  }
];

export const UTILS_ITEMS = [
  {
    id: MENU_IDS[ROUTE_NAMES.SETTINGS],
    name: 'Настройки',
    path: ROUTE_NAMES.SETTINGS,
    disabled: true
  }
];

export const COMPANY_ITEMS = [
  {
    id: MENU_IDS[ROUTE_NAMES.COMPANY],
    name: undefined,
    path: ROUTE_NAMES.COMPANY
  }
];

export const ALL_ITEMS = [...MENU_ITEMS, ...UTILS_ITEMS, ...COMPANY_ITEMS];
