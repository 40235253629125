import { RootState } from 'types/RootState';
import { createSelector } from 'reselect';
import { initialState } from '.';
import { CONTAINER_KEY } from '../constants';

/**
 * Direct selector to the products state domain
 */

const selectProductsDomain = (state: RootState) => state[CONTAINER_KEY] || initialState;

const makeSelectProductsPageState = () => createSelector(selectProductsDomain, (substate) => substate);

const makeSelectProducts = () => createSelector(selectProductsDomain, (substate) => substate.products);

export default makeSelectProductsPageState;
export { selectProductsDomain, makeSelectProductsPageState, makeSelectProducts };
