/* eslint-disable react/button-has-type */
/**
 *
 * Button
 *
 */

import React, { JSX } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { SvgProps } from 'types/global';
import './style.less';
import Loader from '../Loader';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  text?: string;
  icon?: (data: SvgProps) => JSX.Element;
  iconColor?: string;
  className?: string;
  tip?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  small?: boolean;
  large?: boolean;
  isFetching?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
}

function Button(props: Props) {
  const {
    type = 'button',
    text = 'Click me',
    icon: Icon,
    iconColor,
    className,
    disabled = false,
    fullWidth = false,
    large = false,
    small,
    onClick,
    tip,
    isFetching,
    testId
  } = props;
  const classes = classnames({
    Button: true,
    [className as string]: !!className,
    fullWidth: !!fullWidth,
    large: !!large,
    small: !!small,
    disabled: !!disabled
  });

  const buttonContent = () => (
    <>
      <span className="Button__text">{text}</span>
      {Icon && (
        <span className="Button__icon">
          <Icon className="Button__icon" color={iconColor} />
        </span>
      )}
    </>
  );

  return (
    <button
      data-testid={testId || 'Button'}
      className={classes}
      onClick={onClick}
      type={type}
      disabled={disabled || isFetching}
      data-tip={tip}
    >
      {isFetching ? <Loader small /> : buttonContent()}
      <Tooltip />
    </button>
  );
}

export default Button;
