import { takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showToastAction } from 'app/containers/Toast/actions';
import { setAccountId } from 'utils/accountId/accountIdManager';
import { changeAccountId } from 'app/services/authManager';
import { getAccountsApi, getAccountApi, patchAccountBaseRoleApi } from './api';
import {
  getAccountsAction,
  getAccountsErrorAction,
  getAccountsSuccessAction,
  setAccountSuccessAction,
  setAccountErrorAction,
  patchAccountBaseRoleSuccessAction,
  patchAccountBaseRoleErrorAction
} from './actions';
import { GET_ACCOUNTS, SET_ACCOUNT, PATCH_ACCOUNT_BASE_ROLE } from './constants';
import { makeSelectAccount } from './selectors';

export function* getAccountsSaga({ payload = {} } = {}) {
  const { accountIds } = payload;
  try {
    const result = yield call(getAccountsApi, accountIds);
    yield put(getAccountsSuccessAction(result));
  } catch (err) {
    yield put(getAccountsErrorAction(err));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: err.message
      })
    );
  }
}

export function* setAccountSaga({ payload = {} } = {}) {
  const { accountId, shouldEmit } = payload;
  try {
    const result = yield call(getAccountApi, accountId);
    yield put(setAccountSuccessAction(result));
    shouldEmit && changeAccountId(accountId);
    setAccountId(result.id);
  } catch (err) {
    yield put(setAccountErrorAction(err));
  }
}

export function* patchAccountBaseRoleSaga({ payload = {} } = {}) {
  const { accountBaseRole } = payload;

  const account = yield select(makeSelectAccount());
  const prevBaseRole = account.data.payload.client.baseRole;
  try {
    yield put(patchAccountBaseRoleSuccessAction(accountBaseRole));
    yield call(patchAccountBaseRoleApi, accountBaseRole);
    yield put(getAccountsAction());
  } catch (err) {
    yield put(patchAccountBaseRoleErrorAction(err, prevBaseRole));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: 'Не удалось изменить главную роль на сервере, возвращаем обратно.'
      })
    );
  }
}

export default function* accountsSaga() {
  yield takeLatest(PATCH_ACCOUNT_BASE_ROLE, patchAccountBaseRoleSaga);
  yield takeLatest(SET_ACCOUNT, setAccountSaga);
  yield takeLatest(GET_ACCOUNTS, getAccountsSaga);
}
