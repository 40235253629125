/**
 *
 * UserPanel
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import styled from 'styled-components';

import { Transition } from 'react-transition-group';
import { push } from 'connected-react-router';
import classnames from 'classnames';
import { makeSelectIsSidebarOpen } from 'app/containers/Sidebar/selectors';
import { sidebarClickAction } from 'app/containers/Sidebar/actions';
import UserMenu from 'app/components/UserMenu';
import { makeSelectAccountId, makeSelectAccounts } from 'app/containers/AccountsPage/selectors';
import { setAccountAction } from 'app/containers/AccountsPage/actions';

import { makeSelectUser } from 'app/containers/LoginPage/selectors';
import BurgerIcon from 'app/components/Icons/BurgerIcon';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import GlobalSearch from 'app/containers/GlobalSearch';
import { changeLocale } from 'app/containers/LanguageProvider/actions';
import { makeSelectLocale } from 'app/containers/LanguageProvider/selectors';
import { getStatusesAction } from 'app/containers/Statuses/actions';

import './style.less';
import makeSelectRoles from 'app/containers/Roles/selectors';
import { ChangeCompanyMenu } from './components/ChangeCompanyMenu';

const userPanelTransitionStyles = {
  entering: { left: '0', width: '100%' },
  entered: { left: 'var(--sidebar-width)', width: 'calc(100% - var(--sidebar-width)' },
  exiting: { left: 'var(--sidebar-width)', width: 'calc(100% - var(--sidebar-width)' },
  exited: { left: '0', width: '100%' }
};

function UserPanel({
  isSidebarOpen,
  sidebarClick,
  logout,
  rolesState,
  user,
  getStatuses,
  setAccount,
  accountsState: { data: accounts },
  accountId,
  goToClientAdding,
  goToClient,
  roles
}) {
  React.useEffect(() => {
    getStatuses();
  }, [getStatuses]);

  const handleSetAccount = useCallback(
    (id) => {
      setAccount(id);
      goToClient();
    },
    [goToClient, setAccount]
  );

  const classNames = classnames({ UserPanel: true, sidebarOpened: isSidebarOpen });
  return (
    <>
      <div className="UserPanelStab" />
      <Transition in={isSidebarOpen} timeout={250}>
        {(state) => (
          <div style={userPanelTransitionStyles[state]} className={classNames}>
            <div className="UserPanel__leftSide">
              {!isSidebarOpen && (
                <div className="BurgerIconWrapper">
                  <BurgerIcon onClick={sidebarClick} fill="#262C40" />
                </div>
              )}
            </div>
            <div className="UserPanel__centerSide">
              <GlobalSearch />
            </div>

            <ChangeCompanyMenu
              roles={rolesState?.roles}
              handleSetAccount={handleSetAccount}
              accounts={accounts}
              accountId={accountId}
            />

            <div className="UserPanel__rightSide">
              <div className="UserPanel__Menu">
                <UserMenu
                  goToClientAdding={goToClientAdding}
                  clientId={accountId}
                  accounts={accounts}
                  setClientId={handleSetAccount}
                  logout={logout}
                  user={user}
                  isSidebarOpen={isSidebarOpen}
                />
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
}

UserPanel.propTypes = {
  isSidebarOpen: PropTypes.bool,
  accountId: PropTypes.string,
  accountsState: PropTypes.object,
  rolesState: PropTypes.object,
  sidebarClick: PropTypes.func,
  logout: PropTypes.func,
  getStatuses: PropTypes.func,
  setAccount: PropTypes.func,
  goToClientAdding: PropTypes.func,
  goToClient: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  isSidebarOpen: makeSelectIsSidebarOpen(),
  currentLanguage: makeSelectLocale(),
  user: makeSelectUser(),
  accountId: makeSelectAccountId(),
  accountsState: makeSelectAccounts(),
  rolesState: makeSelectRoles()
});

function mapDispatchToProps(dispatch) {
  return {
    sidebarClick: () => dispatch(sidebarClickAction()),
    changeLocale: (lang) => dispatch(changeLocale(lang)),
    getStatuses: () => dispatch(getStatusesAction()),
    setAccount: (accountId) => dispatch(setAccountAction(accountId)),
    goToClientAdding: (url = `/${ROUTE_NAMES.CREATE_CLIENT}`) => dispatch(push(url)),
    goToClient: (url = `/company?currentSwitchValue=PROFILE`) => dispatch(push(url))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(UserPanel);

export default React.memo(withConnect);
