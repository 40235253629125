/*
 *
 * Stub reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import { GET_STUB, GET_STUB_SUCCESS, GET_STUB_FAIL } from './constants';

export const initialState = {
  stub: {
    ...createStateStatus(),
    data: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const stubReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_STUB:
        draft.stub.isFetching = true;
        draft.stub.isError = false;
        break;
      case GET_STUB_SUCCESS:
        draft.stub.isFetching = false;
        draft.stub.isDone = true;
        draft.stub.data = action.payload.stub;
        break;
      case GET_STUB_FAIL:
        draft.stub.isFetching = false;
        draft.stub.isDone = true;
        draft.stub.isError = action.payload.error;
        break;
    }
  });

export default stubReducer;
