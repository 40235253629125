import { takeLatest, call, put, select } from 'redux-saga/effects';
import { ROLE_NAMES } from 'app/containers/Roles/constants';
import makeSelectRoles from 'app/containers/Roles/selectors';
import { getGlobalSearchApi, getGlobalSearchBuyerApi } from './api';
import { getGlobalSearchAction, getGlobalSearchErrorAction, getGlobalSearchSuccessAction } from './actions';
import { GET_GLOBAL_SEARCH } from './constants';

const getGlobalSearchApiByRole = (rolesData) => {
  if (rolesData?.roles?.includes(ROLE_NAMES.BUYER)) {
    return getGlobalSearchBuyerApi;
  }
  return getGlobalSearchApi;
};

export function* getGlobalSearchSaga(action: ReturnType<typeof getGlobalSearchAction>) {
  const {
    payload: { query }
  } = action;
  try {
    const rolesData = yield select(makeSelectRoles());
    const result = yield call(getGlobalSearchApiByRole(rolesData), query);
    yield put(getGlobalSearchSuccessAction(result));
  } catch (err) {
    yield put(getGlobalSearchErrorAction(err as Error));
  }
}

export default function* globalSearchSaga() {
  yield takeLatest(GET_GLOBAL_SEARCH, getGlobalSearchSaga);
}
