import ClientOAuth2, { Token } from 'client-oauth2';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import { APP_CONFIG } from 'app/config';
import {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken
} from 'utils/storage/storage';
import { eraseAccountId } from 'utils/accountId/accountIdManager';
import { keycloakManager } from './keycloak';
import { changeAccountId } from './authManager';

export const AppAuthorities = {
  USER_API: 'USER_API'
};

const { VITE_AUTH_ENDPOINT } = import.meta.env;
const DEFAULT_AUTH_ADDRESS = APP_CONFIG.baseAuthURL;
export const authAddress = VITE_AUTH_ENDPOINT || DEFAULT_AUTH_ADDRESS;

const { VITE_API_ENDPOINT } = import.meta.env;
const DEFAULT_API_ADDRESS = `${APP_CONFIG.baseAuthURL}/api`;
export const apiAddress = VITE_API_ENDPOINT || DEFAULT_API_ADDRESS;

const OAUTH_CLIENT_ID = APP_CONFIG.auth.client_id;
const DEFAULT_OAUTH_CLIENT_ID = 'rea-ui';
export const clientId = OAUTH_CLIENT_ID || DEFAULT_OAUTH_CLIENT_ID;

const OAUTH_CLIENT_SECRET = APP_CONFIG.auth.client_secret;
const DEFAULT_OAUTH_CLIENT_SECRET = undefined;

export const clientSecret = OAUTH_CLIENT_SECRET || DEFAULT_OAUTH_CLIENT_SECRET;

export const DEFAULT_REALM = 'rea';
export const authRealm = APP_CONFIG.auth.realm || DEFAULT_REALM;

const clientSettings = {
  clientId,
  clientSecret
};

const AppAuth = new ClientOAuth2({
  ...clientSettings,
  authorizationUri: `${authAddress}/auth/realms/${authRealm}/protocol/openid-connect/authorize`,
  accessTokenUri: `${authAddress}/auth/realms/${authRealm}/protocol/openid-connect/token`
});

let TOKEN;
let USER_PERMISSIONS;

export class AuthException extends Error {}

export async function logIn(login, password) {
  TOKEN = await AppAuth.owner.getToken(login, password, {
    body: {
      client_secret: clientSecret,
      client_id: clientId
    }
  });
  setAccessToken(TOKEN.accessToken);
  setRefreshToken(TOKEN.refreshToken);
  return TOKEN;
}

export function logOut() {
  removeRefreshToken();
  TOKEN = new Token(AppAuth, {});
  removeAccessToken();
  eraseAccountId();
  changeAccountId(null);
  keycloakManager.keycloak.logout();
}

window.logOut = logOut;

export async function getToken() {
  if (!TOKEN) {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (!accessToken) {
      return;
    }
    const tokenData = jwtDecode(accessToken);

    if (!refreshToken) {
      removeAccessToken();
      throw new AuthException('Нету refresh token');
    }
    TOKEN = AppAuth.createToken({
      access_token: accessToken,
      refresh_token: refreshToken
    });
    TOKEN.expiresIn(tokenData.exp - tokenData.iat);
    TOKEN.tokenType = 'bearer';
  }

  try {
    if (TOKEN.expired()) {
      TOKEN = await TOKEN.refresh({
        body: {
          client_secret: clientSecret,
          client_id: clientId
        }
      });
    }
  } catch (error) {
    return false;
  }
  const token = getAccessToken();
  TOKEN.token = token;
  return TOKEN;
}

export async function refreshToken(force) {
  if (force || TOKEN.expired()) {
    TOKEN = await TOKEN.refresh(getRequestBody());
  }
}

function getRequestBody() {
  return {
    body: {
      client_secret: clientSecret,
      client_id: clientId
    }
  };
}

export function isAuth() {
  const refreshToken = getRefreshToken();
  return !!TOKEN || !!refreshToken;
}

export async function getUserPermissions() {
  if (!USER_PERMISSIONS) {
    const token = await getToken();

    try {
      const checkedToken = await getTokenData(token.accessToken);
      USER_PERMISSIONS = checkedToken.authorities;
    } catch (e) {
      throw new AuthException('Невозможность получить список ролей пользователя');
    }
  }

  return USER_PERMISSIONS;
}

export async function getTokenData(token) {
  const checkedToken = await fetch(`${authAddress}/oauth/check_token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${clientSettings.clientId}:${clientSettings.clientSecret}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: qs.stringify({ token })
  });
  return checkedToken.json();
}
