/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const UNKNOWN_MSG = 'Неизвестно';

export const ROUTE_NAMES = {
  ACCOUNTS: 'accounts',
  AUTH_LOGIN: 'login',
  AUTH_REGISTRATION: 'register',
  AUTH_RECOVERY: 'recover',
  ROOT: '',
  SETTINGS: 'settings',
  REQUESTS: 'requests',
  WAREHOUSES: 'warehouses',
  PROJECTS: 'projects',
  COMPANY: 'company',
  EVENTS: 'events',
  ORDER: 'order',
  PRODUCTS: 'products',
  FORBIDDEN: 'forbidden'
};

export enum AllowedRefTypes {
  S3_OBJECT = 'S3_OBJECT',
  CATEGORY = 'CATEGORY',
  ELEMENT = 'ELEMENT',
  RELATION_TYPE = 'RELATION_TYPE',
  RELATION = 'RELATION',
  ELEMENT_VIEW = 'ELEMENT_VIEW'
}

export enum AttributeTypes {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  NUMBER = 'NUMBER',
  DECIMAL = 'DECIMAL',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  REF = 'REF'
}

export const ELEMENT_ROOT_CATEGORIES = {
  SUPPLIER_CATALOG: 'supplierCatalog',
  PROVIDER_CATALOG: 'providerCatalog',
  DICTIONARIES: 'referenceBook'
} as const;
