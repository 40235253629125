import React, { useState, JSX } from 'react';
import { Popover } from 'react-tiny-popover';
import { throttle } from 'lodash';
import classNames from 'classnames';
import ArrowDownWhiteIcon from 'app/components/Icons/ArrowDownIcon';
import { ID } from 'types';
import LockIcon from 'app/components/Icons/LockIcon';
import './style.less';

interface ChangeCompanyMenuProps {
  handleSetAccount: (id: ID) => void;
  accounts: { id: ID }[];
  roles: [];
  accountId: ID;
}

export const ChangeCompanyMenu = ({ handleSetAccount, accounts, accountId, roles }: ChangeCompanyMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const restAccounts = accounts?.filter((item) => item.id !== accountId);
  const throttledSetAccount = throttle(handleSetAccount, 500);
  if (!accounts?.length) {
    return null;
  }
  return (
    <div className="ChangeCompanyMenu">
      <Popover
        positions={['bottom', 'left']}
        padding={14}
        isOpen={isOpen}
        containerClassName="ChangeCompanyMenu__popover"
        onClickOutside={() => setIsOpen(false)}
        content={() => Company(restAccounts, throttledSetAccount, roles)}
      >
        <div className="ChangeCompanyMenu__label" onClick={() => setIsOpen(!isOpen)}>
          {/* <span>{currentCompany(accounts, accountId)}</span> */}
          <span>Мои организации</span> <ArrowDownWhiteIcon />
        </div>
      </Popover>
    </div>
  );
};

function Company(restAccounts: any, throttledSetAccount, roles): JSX.Element {
  return (
    <div className="UserMenu">
      {restAccounts?.map((company) => {
        const accountFromUm = roles.find((role) => role.client.externalId === company.id);
        const isBlocked = accountFromUm && accountFromUm.status === 'Заблокирован';
        const isDisabled = accountFromUm && accountFromUm.status === 'Отключен';
        if (isDisabled) {
          return null;
        }
        const classes = classNames({
          'UserMenu__item UserMenu__item_BorderBottom': true,
          blocked: isBlocked
        });
        return (
          <div className={classes} onClick={() => !isBlocked && throttledSetAccount(company.id)}>
            {isBlocked ? <LockIcon /> : null} {isBlocked} {company.title}
          </div>
        );
      })}
    </div>
  );
}
