import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { CONTAINER_KEY } from './constants';

/**
 * Direct selector to the loginPage state domain
 */

const selectLoginPageDomain = state =>
  (state && state[CONTAINER_KEY]) || initialState;

const makeSelectLoginPage = () =>
  createSelector(selectLoginPageDomain, substate => substate);

const makeSelectUser = () =>
  createSelector(selectLoginPageDomain, substate => substate.user);

const makeSelectUserInn = () =>
  createSelector(selectLoginPageDomain, substate =>
    substate.user ? substate.user.client.contractor.inn : ''
  );
const makeSelectUserOGRN = () =>
  createSelector(selectLoginPageDomain, substate =>
    substate.user ? substate.user.client.contractor.ogrn : ''
  );
const makeSelectUserBIN = () =>
  createSelector(selectLoginPageDomain, substate =>
    substate.user ? substate.user.client.contractor.bin : ''
  );
const makeSelectUserSettingStatus = () =>
  createSelector(selectLoginPageDomain, substate => substate.setUserStatus);

export default makeSelectLoginPage;
export {
  selectLoginPageDomain,
  makeSelectUser,
  makeSelectUserInn,
  makeSelectUserOGRN,
  makeSelectUserSettingStatus,
  makeSelectUserBIN
};
