import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showToastAction } from 'app/containers/Toast/actions';
import { ActionWithPayload } from 'types/global';
import { ApiManager } from 'utils/apiManager';
import * as eventsApi from '../api';
import { getEventsApi } from '../api';
import { eventsActions } from '.';
import { getEventsActionPayloadType, getPaymentEventsActionPayloadType } from './types';

const buyerApis = {
  getPaymentEventsApi: eventsApi.getPaymentEventsBuyerApi
};
const providerApis = {
  getPaymentEventsApi: eventsApi.getPaymentEventsProviderApi
};

const apiManager = new ApiManager({ commonAndSupplierApis: eventsApi, buyerApis, providerApis });

export function* getEventsSaga(action: ActionWithPayload<getEventsActionPayloadType>): unknown {
  try {
    console.log('12312213')
      console.log(err);
    const result = yield call(getEventsApi, action.payload);
    yield put(eventsActions.getEventsSuccessAction(result));
    yield put(
      showToastAction({
        type: toast.TYPE.SUCCESS,
        message: 'Успех'
      })
    );
  } catch (err) {
    console.log(err);
    yield put(eventsActions.getEventsErrorAction(err as Error));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: (err as Error).message
      })
    );
  }
}

export function* fetchPaymentEventsSaga(action: ActionWithPayload<getPaymentEventsActionPayloadType>): unknown {
  try {
    const result = yield call(apiManager.getApiFunction('getPaymentEventsApi'), action.payload);
    yield put(eventsActions.fetchPaymentEventsSuccessAction(result));
  } catch (err) {
    yield put(eventsActions.fetchPaymentEventsErrorAction(err as Error));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: (err as Error).message
      })
    );
  }
}

export default function* eventsSaga() {
  yield takeLatest(eventsActions.fetchPaymentEventsAction.type, fetchPaymentEventsSaga);
  yield takeLatest(eventsActions.getEventsAction.type, getEventsSaga);
}
