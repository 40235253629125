import React from 'react';

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className="Toast__close--icon"
    >
      <path
        fill="#fff"
        d="M13.414 12l4.293-4.293a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 001.414 1.414L12 13.414l4.293 4.293A.998.998 0 0018 17a.997.997 0 00-.293-.707L13.414 12z"
      />
    </svg>
  );
}

export default CloseIcon;
