/* eslint-disable no-constructor-return */
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { APP_CONFIG } from 'app/config';
import { getAccessToken, getRefreshToken } from 'utils/storage/storage';

const keycloak = new Keycloak({
  url: `${APP_CONFIG.baseAuthURL}/auth`,
  realm: APP_CONFIG.auth.realm as string,
  clientId: APP_CONFIG.auth.client_id as string
});

keycloak.clientSecret = APP_CONFIG.auth.client_secret as string;

export class KeycloakManager {
  authenticated?: boolean;

  initialized: boolean = false;

  token?: string;

  keycloak?: typeof keycloak;

  tokenParsed?: KeycloakTokenParsed;

  static instance;

  listeners: Array<(keycloakInstance: typeof keycloak) => void> = [];

  constructor() {
    if (KeycloakManager.instance) {
      return KeycloakManager.instance;
    }
    KeycloakManager.instance = this;
    this.keycloak = keycloak;
  }

  addOnTokenExpiredListener(listener) {
    this.listeners.push(listener);
  }

  async initKeycloakLib({ setUser, onInit, onAuth }) {
    keycloak
      .init({
        onLoad: 'check-sso',
        token: getAccessToken() as string,
        refreshToken: getRefreshToken() as string,
        checkLoginIframe: false
      })
      .then((authenticated) => {
        this.initialized = true;
        this.authenticated = authenticated;
        if (authenticated) {
          onAuth(true);
          this.token = keycloak.token;
          this.tokenParsed = keycloak.tokenParsed;
          if (keycloak.tokenParsed) {
            setUser && setUser(keycloak.tokenParsed);
          }
        } else {
          keycloak.login({ redirectUri: `${location.origin}/company` });
        }
        onInit();
      })
      .catch((err) => {
        console.error('Failed to initialize Keycloak: ', err);
      });

    keycloak.onTokenExpired = async () => {
      // Refresh the access token
      await keycloak.updateToken(5).catch((err) => {
        console.error('Failed to refresh token: ', err);
      });
      this.token = keycloak.token;
      this.tokenParsed = keycloak.tokenParsed;

      this.listeners.forEach((listener) => listener(keycloak));
    };
  }
}

export const keycloakManager = new KeycloakManager();
