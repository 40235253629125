import React from 'react';

function Icon() {
  return (
    <svg
      className="Dropdown__icon Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="16"
      fill="none"
      viewBox="0 0 4 16"
    >
      <path
        fill="#161616"
        d="M2 3.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      />
    </svg>
  );
}

export default Icon;
