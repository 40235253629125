import { create } from 'apisauce';
import { APP_CONFIG } from '../../config';

export const emailCodeValidationApi = create({
  baseURL: APP_CONFIG.baseApiURL,
  headers: { Accept: 'application/vnd.github.v3+json' }
});

export const getValidationCodeApi = async email =>
  (
    await emailCodeValidationApi.post('/validations', email, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })
  ).data;

export const checkValidationCodeApi = async ({ code, email }) =>
  (
    await emailCodeValidationApi.post(`/validations/${code}`, email, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })
  ).data;
