/**
 *
 * ProtectedRoute
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import { computeRestrictionsByPath } from 'app/containers/Roles/computeRestrictionsByPath';

import './style.less';
import { keycloakManager } from 'app/services/keycloak';
import { getAccountId } from 'utils/accountId/accountIdManager';

const ProtectedRoute = ({ component: Component, isAuthorized, path, rolesData, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (Array.isArray(rolesData.roles)) {
        if (rolesData.noAccounts || !getAccountId()) {
          return <Redirect to={`/${ROUTE_NAMES.ACCOUNTS}`} />;
        }
        if (keycloakManager.authenticated && rolesData.isAppRestrictedByRoles) {
          return <Redirect to={`/${ROUTE_NAMES.FORBIDDEN}`} />;
        }
        if (!rolesData.roles || isAuthorized === null) {
          return null;
        }
        if (computeRestrictionsByPath(rolesData, path)) {
          return <Redirect to={`/${ROUTE_NAMES.COMPANY}`} />;
        }
      }

      if (isAuthorized === true) {
        return <Component {...props} />;
      }
    }}
  />
);

ProtectedRoute.propTypes = {
  isAuthorized: PropTypes.bool,
  path: PropTypes.string,
  rolesData: PropTypes.object,
  component: PropTypes.any
};

export default ProtectedRoute;
