/*
 *
 * Statuses constants
 *
 */

export const CONTAINER_KEY = 'statuses';

export const GET_STATUSES = 'app/statuses/GET_STATUSES';
export const GET_STATUSES_SUCCESS = 'app/statuses/GET_STATUSES_SUCCESS';
export const GET_STATUSES_FAIL = 'app/statuses/GET_STATUSES_FAIL';
export const RESET_STATUSES_STATUS = 'app/statuses/RESET_STATUSES_STATUS';
