/*
 *
 * LoginPage constants
 *
 */

export const CONTAINER_KEY = 'loginPage';

export const LOGIN_ACTION = 'app/LoginPage/LOGIN';
export const LOGIN_SUCCESS_ACTION = 'app/LoginPage/LOGIN_SUCCESS';
export const LOGIN_FAIL_ACTION = 'app/LoginPage/LOGIN_FAIL';

export const LOGOUT_ACTION = 'app/LoginPage/LOGOUT';
export const LOGOUT_SUCCESS_ACTION = 'app/LoginPage/LOGOUT_SUCCESS';
export const LOGOUT_FAIL_ACTION = 'app/LoginPage/LOGOUT_FAIL';

export const SET_USER_ACTION = 'app/LoginPage/SET_USER';
export const SET_USER_SUCCESS_ACTION = 'app/LoginPage/SET_USER_SUCCESS';
export const SET_USER_FAIL_ACTION = 'app/LoginPage/SET_USER_FAIL';

export const CLEAN_LOGIN_ERROR_ACTION = 'app/LoginPage/CLEAN_LOGIN_ERROR';

export const GET_USER = 'app/loginPage/ GET_USER';
export const GET_USER_SUCCESS = 'app/loginPage/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'app/loginPage/GET_USER_FAIL';
export const RESET_GET_USER_STATUS = 'app/loginPage/RESET_GET_USER_STATUS';
