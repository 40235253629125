/*
 *
 * Statuses reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import { GET_STATUSES, GET_STATUSES_SUCCESS, GET_STATUSES_FAIL, RESET_STATUSES_STATUS } from './constants';

export const initialState = {
  statuses: {
    ...createStateStatus(),
    data: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const statusesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_STATUSES:
        draft.statuses.isFetching = true;
        draft.statuses.isError = false;
        break;
      case GET_STATUSES_SUCCESS:
        draft.statuses.isFetching = false;
        draft.statuses.isDone = true;
        draft.statuses.data = action.payload.statuses;
        break;
      case GET_STATUSES_FAIL:
        draft.statuses.isFetching = false;
        draft.statuses.isDone = true;
        draft.statuses.isError = action.payload.error;
        break;
      case RESET_STATUSES_STATUS:
        draft.statuses.isFetching = false;
        draft.statuses.isDone = false;
        draft.statuses.isError = null;
        draft.statuses.data = undefined;
        break;
    }
  });

export default statusesReducer;
