/*
 *
 * Order constants
 *
 */

export const CONTAINER_KEY = 'order';

export const FETCH_ORDER_POSITIONS = 'app/order/FETCH_ORDER_POSITIONS';
export const FETCH_ORDER_POSITIONS_SUCCESS = 'app/order/FETCH_ORDER_POSITIONS_SUCCESS';
export const FETCH_ORDER_POSITIONS_FAIL = 'app/order/FETCH_ORDER_POSITIONS_FAIL';
export const RESET_FETCH_ORDER_POSITIONS_STATUS = 'app/order/RESET_FETCH_ORDER_POSITIONS_STATUS';

export const FETCH_ORDER_STATUSES = 'app/order/FETCH_ORDER_STATUSES';
export const FETCH_ORDER_STATUSES_SUCCESS = 'app/order/FETCH_ORDER_STATUSES_SUCCESS';
export const FETCH_ORDER_STATUSES_FAIL = 'app/order/FETCH_ORDER_STATUSES_FAIL';
export const RESET_FETCH_ORDER_STATUSES_STATUS = 'app/order/RESET_FETCH_ORDER_STATUSES_STATUS';

export const UPDATE_ORDER_STATUS = 'app/order/UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_SUCCESS = 'app/order/UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAIL = 'app/order/UPDATE_ORDER_STATUS_FAIL';
export const RESET_UPDATE_ORDER_STATUS_STATUS = 'app/order/RESET_UPDATE_ORDER_STATUS_STATUS';

export const ORDER_PAGE_TABS = {
  POSITIONS: 'POSITIONS',
  PRIMARY_DOCUMENTS: 'PRIMARY_DOCUMENTS',
  DOCUMENTS: 'DOCUMENTS',
  PAYMENT: 'PAYMENT',
  STATUS_HISTORY: 'STATUS_HISTORY'
};

export const ORDER_PAGE_SWITCH_ITEMS = [
  {
    name: 'Позиции',
    value: ORDER_PAGE_TABS.POSITIONS
  },
  {
    name: 'Документы',
    value: ORDER_PAGE_TABS.DOCUMENTS
  },
  {
    name: 'Оплата',
    value: ORDER_PAGE_TABS.PAYMENT
  },
  {
    name: 'Первичные документы',
    value: ORDER_PAGE_TABS.PRIMARY_DOCUMENTS
  },
  {
    name: 'История',
    value: ORDER_PAGE_TABS.STATUS_HISTORY
  }
];

export const SHIPMENT_STATUSES = {
  PREPARING: '64007b8bd4440521a353d963',
  PENDING: '63ef1394d4440521a353a23f',
  DELIVERED: '63ef2088d4440521a353a240'
};

export const ORDER_PAYMENT_TYPES = {
  PLAN: 'plan',
  FACT: 'fact'
};

export const CREATE_SIGNED_ORDER_DOCUMENT = 'app/order/CREATE_SIGNED_ORDER_DOCUMENT';
export const CREATE_SIGNED_ORDER_DOCUMENT_SUCCESS = 'app/order/CREATE_SIGNED_ORDER_DOCUMENT_SUCCESS';
export const CREATE_SIGNED_ORDER_DOCUMENT_FAIL = 'app/order/CREATE_SIGNED_ORDER_DOCUMENT_FAIL';
export const RESET_CREATE_SIGNED_ORDER_DOCUMENT_STATUS = 'app/order/RESET_CREATE_SIGNED_ORDER_DOCUMENT_STATUS';

export const CREATE_APPROVED_ORDER_DOCUMENTS = 'app/order/CREATE_APPROVED_ORDER_DOCUMENTS';
export const CREATE_APPROVED_ORDER_DOCUMENTS_SUCCESS = 'app/order/CREATE_APPROVED_ORDER_DOCUMENTS_SUCCESS';
export const CREATE_APPROVED_ORDER_DOCUMENTS_FAIL = 'app/order/CREATE_APPROVED_ORDER_DOCUMENTS_FAIL';
export const RESET_CREATE_APPROVED_ORDER_DOCUMENTS_STATUS = 'app/order/RESET_CREATE_APPROVED_ORDER_DOCUMENTS_STATUS';

export const REMOVE_ORDER_DOCUMENTS = 'app/order/REMOVE_ORDER_DOCUMENTS';
export const REMOVE_ORDER_DOCUMENTS_SUCCESS = 'app/order/REMOVE_ORDER_DOCUMENTS_SUCCESS';
export const REMOVE_ORDER_DOCUMENTS_FAIL = 'app/order/REMOVE_ORDER_DOCUMENTS_FAIL';
export const RESET_REMOVE_ORDER_DOCUMENTS_STATUS = 'app/order/RESET_REMOVE_ORDER_DOCUMENTS_STATUS';

export const CREATE_ORDER_PAYMENTS = 'app/order/CREATE_ORDER_PAYMENTS';
export const CREATE_ORDER_PAYMENTS_SUCCESS = 'app/order/CREATE_ORDER_PAYMENTS_SUCCESS';
export const CREATE_ORDER_PAYMENTS_FAIL = 'app/order/CREATE_ORDER_PAYMENTS_FAIL';
export const RESET_CREATE_ORDER_PAYMENTS_STATUS = 'app/order/RESET_CREATE_ORDER_PAYMENTS_STATUS';

export const FETCH_ORDER_PAYMENTS = 'app/order/FETCH_ORDER_PAYMENTS';
export const FETCH_ORDER_PAYMENTS_SUCCESS = 'app/order/FETCH_ORDER_PAYMENTS_SUCCESS';
export const FETCH_ORDER_PAYMENTS_FAIL = 'app/order/FETCH_ORDER_PAYMENTS_FAIL';
export const RESET_FETCH_ORDER_PAYMENTS_STATUS = 'app/order/RESET_FETCH_ORDER_PAYMENTS_STATUS';

export const REMOVE_ORDER_PAYMENTS = 'app/order/REMOVE_ORDER_PAYMENTS';
export const REMOVE_ORDER_PAYMENTS_SUCCESS = 'app/order/REMOVE_ORDER_PAYMENTS_SUCCESS';
export const REMOVE_ORDER_PAYMENTS_FAIL = 'app/order/REMOVE_ORDER_PAYMENTS_FAIL';
export const RESET_REMOVE_ORDER_PAYMENTS_STATUS = 'app/order/RESET_REMOVE_ORDER_PAYMENTS_STATUS';

export const FETCH_ORDER_PAYMENT_STAGES = 'app/order/FETCH_ORDER_PAYMENT_STAGES';
export const FETCH_ORDER_PAYMENT_STAGES_SUCCESS = 'app/order/FETCH_ORDER_PAYMENT_STAGES_SUCCESS';
export const FETCH_ORDER_PAYMENT_STAGES_FAIL = 'app/order/FETCH_ORDER_PAYMENT_STAGES_FAIL';
export const RESET_FETCH_ORDER_PAYMENT_STAGES_STATUS = 'app/order/RESET_FETCH_ORDER_PAYMENT_STAGES_STATUS';

export const UPDATE_ORDER_PAYMENTS = 'app/order/UPDATE_ORDER_PAYMENTS';
export const UPDATE_ORDER_PAYMENTS_SUCCESS = 'app/order/UPDATE_ORDER_PAYMENTS_SUCCESS';
export const UPDATE_ORDER_PAYMENTS_FAIL = 'app/order/UPDATE_ORDER_PAYMENTS_FAIL';
export const RESET_UPDATE_ORDER_PAYMENTS_STATUS = 'app/order/RESET_UPDATE_ORDER_PAYMENTS_STATUS';

export const UPDATE_ORDER_PAYMENT_STAGES = 'app/order/UPDATE_ORDER_PAYMENT_STAGES';
export const UPDATE_ORDER_PAYMENT_STAGES_SUCCESS = 'app/order/UPDATE_ORDER_PAYMENT_STAGES_SUCCESS';
export const UPDATE_ORDER_PAYMENT_STAGES_FAIL = 'app/order/UPDATE_ORDER_PAYMENT_STAGES_FAIL';
export const RESET_UPDATE_ORDER_PAYMENT_STAGES_STATUS = 'app/order/RESET_UPDATE_ORDER_PAYMENT_STAGES_STATUS';

export const FETCH_ORDER = 'app/order/FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'app/order/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAIL = 'app/order/FETCH_ORDER_FAIL';
export const RESET_FETCH_ORDER_STATUS = 'app/order/RESET_FETCH_ORDER_STATUS';

export const CREATE_ORDER_PRIMARY_DOCUMENTS = 'app/order/CREATE_ORDER_PRIMARY_DOCUMENTS';
export const CREATE_ORDER_PRIMARY_DOCUMENTS_SUCCESS = 'app/order/CREATE_ORDER_PRIMARY_DOCUMENTS_SUCCESS';
export const CREATE_ORDER_PRIMARY_DOCUMENTS_FAIL = 'app/order/CREATE_ORDER_PRIMARY_DOCUMENTS_FAIL';
export const RESET_CREATE_ORDER_PRIMARY_DOCUMENTS_STATUS = 'app/order/RESET_CREATE_ORDER_PRIMARY_DOCUMENTS_STATUS';

export const FETCH_ORDER_PRIMARY_DOCUMENTS = 'app/order/FETCH_ORDER_PRIMARY_DOCUMENTS';
export const FETCH_ORDER_PRIMARY_DOCUMENTS_SUCCESS = 'app/order/FETCH_ORDER_PRIMARY_DOCUMENTS_SUCCESS';
export const FETCH_ORDER_PRIMARY_DOCUMENTS_FAIL = 'app/order/FETCH_ORDER_PRIMARY_DOCUMENTS_FAIL';
export const RESET_FETCH_ORDER_PRIMARY_DOCUMENTS_STATUS = 'app/order/RESET_FETCH_ORDER_PRIMARY_DOCUMENTS_STATUS';

export const UPDATE_ORDER_PRIMARY_DOCUMENTS = 'app/order/UPDATE_ORDER_PRIMARY_DOCUMENTS';
export const UPDATE_ORDER_PRIMARY_DOCUMENTS_SUCCESS = 'app/order/UPDATE_ORDER_PRIMARY_DOCUMENTS_SUCCESS';
export const UPDATE_ORDER_PRIMARY_DOCUMENTS_FAIL = 'app/order/UPDATE_ORDER_PRIMARY_DOCUMENTS_FAIL';
export const RESET_UPDATE_ORDER_PRIMARY_DOCUMENTS_STATUS = 'app/order/RESET_UPDATE_ORDER_PRIMARY_DOCUMENTS_STATUS';

export const UPDATE_ORDER_POSITIONS = 'app/order/UPDATE_ORDER_POSITIONS';
export const UPDATE_ORDER_POSITIONS_SUCCESS = 'app/order/UPDATE_ORDER_POSITIONS_SUCCESS';
export const UPDATE_ORDER_POSITIONS_FAIL = 'app/order/UPDATE_ORDER_POSITIONS_FAIL';
export const RESET_UPDATE_ORDER_POSITIONS_STATUS = 'app/order/RESET_UPDATE_ORDER_POSITIONS_STATUS';

export const UPDATE_ORDER = 'app/order/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'app/order/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'app/order/UPDATE_ORDER_FAIL';
export const RESET_UPDATE_ORDER_STATUS = 'app/order/RESET_UPDATE_ORDER_STATUS';

export const CREATE_ORDER_LOGISTICS = 'app/order/CREATE_ORDER_LOGISTICS';
export const CREATE_ORDER_LOGISTICS_SUCCESS = 'app/order/CREATE_ORDER_LOGISTICS_SUCCESS';
export const CREATE_ORDER_LOGISTICS_FAIL = 'app/order/CREATE_ORDER_LOGISTICS_FAIL';
export const RESET_CREATE_ORDER_LOGISTICS_STATUS = 'app/order/RESET_CREATE_ORDER_LOGISTICS_STATUS';

export const FETCH_ORDER_SHIPMENTS = 'app/order/FETCH_ORDER_SHIPMENTS';
export const FETCH_ORDER_SHIPMENTS_SUCCESS = 'app/order/FETCH_ORDER_SHIPMENTS_SUCCESS';
export const FETCH_ORDER_SHIPMENTS_FAIL = 'app/order/FETCH_ORDER_SHIPMENTS_FAIL';
export const RESET_FETCH_ORDER_SHIPMENTS_STATUS = 'app/order/RESET_FETCH_ORDER_SHIPMENTS_STATUS';

export const FETCH_SHIPMENT_POSITIONS = 'app/order/FETCH_SHIPMENT_POSITIONS';
export const FETCH_SHIPMENT_POSITIONS_SUCCESS = 'app/order/FETCH_SHIPMENT_POSITIONS_SUCCESS';
export const FETCH_SHIPMENT_POSITIONS_FAIL = 'app/order/FETCH_SHIPMENT_POSITIONS_FAIL';
export const RESET_FETCH_SHIPMENT_POSITIONS_STATUS = 'app/order/RESET_FETCH_SHIPMENT_POSITIONS_STATUS';

export const UPDATE_ORDER_SHIPMENTS = 'app/order/UPDATE_ORDER_SHIPMENTS';
export const UPDATE_ORDER_SHIPMENTS_SUCCESS = 'app/order/UPDATE_ORDER_SHIPMENTS_SUCCESS';
export const UPDATE_ORDER_SHIPMENTS_FAIL = 'app/order/UPDATE_ORDER_SHIPMENTS_FAIL';
export const RESET_UPDATE_ORDER_SHIPMENTS_STATUS = 'app/order/RESET_UPDATE_ORDER_SHIPMENTS_STATUS';

export const CREATE_ORDER_SHIPMENTS = 'app/order/CREATE_ORDER_SHIPMENTS';
export const CREATE_ORDER_SHIPMENTS_SUCCESS = 'app/order/CREATE_ORDER_SHIPMENTS_SUCCESS';
export const CREATE_ORDER_SHIPMENTS_FAIL = 'app/order/CREATE_ORDER_SHIPMENTS_FAIL';
export const RESET_CREATE_ORDER_SHIPMENTS_STATUS = 'app/order/RESET_CREATE_ORDER_SHIPMENTS_STATUS';

export const UPDATE_ORDER_POSITIONS_VAT = 'app/order/UPDATE_ORDER_POSITIONS_VAT';
export const UPDATE_ORDER_POSITIONS_VAT_SUCCESS = 'app/order/UPDATE_ORDER_POSITIONS_VAT_SUCCESS';
export const UPDATE_ORDER_POSITIONS_VAT_FAIL = 'app/order/UPDATE_ORDER_POSITIONS_VAT_FAIL';
export const RESET_UPDATE_ORDER_POSITIONS_VAT_STATUS = 'app/order/RESET_UPDATE_ORDER_POSITIONS_VAT_STATUS';

export const FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS = 'app/order/FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS';
export const FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_SUCCESS =
  'app/order/FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_SUCCESS';
export const FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_FAIL = 'app/order/FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_FAIL';
export const RESET_FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_STATUS =
  'app/order/RESET_FETCH_ORDER_PAYMENTS_CUMULATIVE_AMOUNTS_STATUS';

export const FETCH_ORDER_PLAN_PAYMENTS = 'app/order/FETCH_ORDER_PLAN_PAYMENTS';
export const FETCH_ORDER_PLAN_PAYMENTS_SUCCESS = 'app/order/FETCH_ORDER_PLAN_PAYMENTS_SUCCESS';
export const FETCH_ORDER_PLAN_PAYMENTS_FAIL = 'app/order/FETCH_ORDER_PLAN_PAYMENTS_FAIL';
export const RESET_FETCH_ORDER_PLAN_PAYMENTS_STATUS = 'app/order/RESET_FETCH_ORDER_PLAN_PAYMENTS_STATUS';

export const FETCH_ORDER_ARRIVAL_WAREHOUSES = 'app/order/FETCH_ORDER_ARRIVAL_WAREHOUSES';
export const FETCH_ORDER_ARRIVAL_WAREHOUSES_SUCCESS = 'app/order/FETCH_ORDER_ARRIVAL_WAREHOUSES_SUCCESS';
export const FETCH_ORDER_ARRIVAL_WAREHOUSES_FAIL = 'app/order/FETCH_ORDER_ARRIVAL_WAREHOUSES_FAIL';
export const RESET_FETCH_ORDER_ARRIVAL_WAREHOUSES_STATUS = 'app/order/RESET_FETCH_ORDER_ARRIVAL_WAREHOUSES_STATUS';

export const FETCH_ORDER_DEPARTURE_WAREHOUSES = 'app/order/FETCH_ORDER_DEPARTURE_WAREHOUSES';
export const FETCH_ORDER_DEPARTURE_WAREHOUSES_SUCCESS = 'app/order/FETCH_ORDER_DEPARTURE_WAREHOUSES_SUCCESS';
export const FETCH_ORDER_DEPARTURE_WAREHOUSES_FAIL = 'app/order/FETCH_ORDER_DEPARTURE_WAREHOUSES_FAIL';
export const RESET_FETCH_ORDER_DEPARTURE_WAREHOUSES_STATUS = 'app/order/RESET_FETCH_ORDER_DEPARTURE_WAREHOUSES_STATUS';

export const CREATE_SHIPMENT_POSITION_OPTIONS = 'app/order/CREATE_SHIPMENT_POSITION_OPTIONS';
export const CREATE_SHIPMENT_POSITION_OPTIONS_SUCCESS = 'app/order/CREATE_SHIPMENT_POSITION_OPTIONS_SUCCESS';
export const CREATE_SHIPMENT_POSITION_OPTIONS_FAIL = 'app/order/CREATE_SHIPMENT_POSITION_OPTIONS_FAIL';
export const RESET_CREATE_SHIPMENT_POSITION_OPTIONS_STATUS = 'app/order/RESET_CREATE_SHIPMENT_POSITION_OPTIONS_STATUS';

export const UPDATE_SHIPMENT_POSITION_OPTIONS = 'app/order/UPDATE_SHIPMENT_POSITION_OPTIONS';
export const UPDATE_SHIPMENT_POSITION_OPTIONS_SUCCESS = 'app/order/UPDATE_SHIPMENT_POSITION_OPTIONS_SUCCESS';
export const UPDATE_SHIPMENT_POSITION_OPTIONS_FAIL = 'app/order/UPDATE_SHIPMENT_POSITION_OPTIONS_FAIL';
export const RESET_UPDATE_SHIPMENT_POSITION_OPTIONS_STATUS = 'app/order/RESET_UPDATE_SHIPMENT_POSITION_OPTIONS_STATUS';

export const CREATE_SOURCE_ORDERS = 'app/order/CREATE_SOURCE_ORDERS';
export const CREATE_SOURCE_ORDERS_SUCCESS = 'app/order/CREATE_SOURCE_ORDERS_SUCCESS';
export const CREATE_SOURCE_ORDERS_FAIL = 'app/order/CREATE_SOURCE_ORDERS_FAIL';
export const RESET_CREATE_SOURCE_ORDERS_STATUS = 'app/order/RESET_CREATE_SOURCE_ORDERS_STATUS';

export const FETCH_SOURCE_ORDERS = 'app/order/FETCH_SOURCE_ORDERS';
export const FETCH_SOURCE_ORDERS_SUCCESS = 'app/order/FETCH_SOURCE_ORDERS_SUCCESS';
export const FETCH_SOURCE_ORDERS_FAIL = 'app/order/FETCH_SOURCE_ORDERS_FAIL';
export const RESET_FETCH_SOURCE_ORDERS_STATUS = 'app/order/RESET_FETCH_SOURCE_ORDERS_STATUS';

export const FETCH_SHIPMENT_POSITIONS_FOR_MODAL = 'app/order/FETCH_SHIPMENT_POSITIONS_FOR_MODAL';
export const FETCH_SHIPMENT_POSITIONS_FOR_MODAL_SUCCESS = 'app/order/FETCH_SHIPMENT_POSITIONS_FOR_MODAL_SUCCESS';
export const FETCH_SHIPMENT_POSITIONS_FOR_MODAL_FAIL = 'app/order/FETCH_SHIPMENT_POSITIONS_FOR_MODAL_FAIL';
export const RESET_FETCH_SHIPMENT_POSITIONS_FOR_MODAL_STATUS =
  'app/order/RESET_FETCH_SHIPMENT_POSITIONS_FOR_MODAL_STATUS';
