const UIIDPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

type accountId = string;

export const accountIdName: string = 'currentAccountId';

export function setAccountId(value: accountId) {
  localStorage.setItem(accountIdName, value);
}

export function getAccountId() {
  return localStorage.getItem(accountIdName);
}

export function eraseAccountId() {
  localStorage.removeItem(accountIdName);
}

export function isValid(id: accountId) {
  return UIIDPattern.test(id);
}
