/**
 *
 * Badge
 *
 */

import classnames from 'classnames';
import React from 'react';
import { Text } from '@theme-ui/components';
import PropTypes from 'prop-types';

import './style.less';

interface Props {
  text: string;
  value: string | string;
  hasNewItems: boolean;
}

function Badge({ text, value, hasNewItems }) {
  const classes = classnames({
    Badge: true,
    new: hasNewItems
  });
  return (
    <div data-testid="Badge" className={classes}>
      {text && <Text mr={2}>{text}:</Text>}
      {value}
    </div>
  );
}

Badge.propTypes = {
  text: PropTypes.node,
  value: PropTypes.number,
  hasNewItems: PropTypes.bool
};

export default Badge;
