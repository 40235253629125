import { create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import { ServerResponseWithError } from 'types/global';
import paramsSerializer from 'utils/paramsSerializer';
import { APP_CONFIG } from '../../config';
import { GlobalSearchResponse } from './types';

export const globalSearchApi = create({
  baseURL: APP_CONFIG.baseApiUssURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getGlobalSearchApi = async (query: string) => {
  const response = await globalSearchApi.get<GlobalSearchResponse, ServerResponseWithError>('/supplier/global-search', {
    q: query
  });
  if (response.ok) {
    return response.data;
  }

  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

export const getGlobalSearchBuyerApi = async (query: string) => {
  const response = await globalSearchApi.get<GlobalSearchResponse, ServerResponseWithError>('/buyer/global-search', {
    q: query
  });
  if (response.ok) {
    return response.data;
  }

  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

globalSearchApi.addAsyncRequestTransform(requestTransform);
globalSearchApi.addAsyncResponseTransform(responseTransform);
