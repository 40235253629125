import { useEffect } from 'react';

export function useOnClickOutside(ref, handler, additionalClasses = []) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        additionalClasses.reduce(
          (prev, curr) =>
            prev ||
            (typeof event?.target?.className?.includes === 'function' && event?.target?.className?.includes(curr)),
          false
        )
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
