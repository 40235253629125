import { ID } from 'types/global';

import { ELEMENT_ROOT_CATEGORIES, ROUTE_NAMES } from 'app/containers/App/constants';

type catalogNames = (typeof ELEMENT_ROOT_CATEGORIES)[keyof typeof ELEMENT_ROOT_CATEGORIES];
export const getOrderUrl = (orderId: ID): string => `/${ROUTE_NAMES.ORDER}/${orderId}`;
// export const getElementUrl = (elementId: ID, root?: catalogNames): string => {
//   if (!root) {
//     return `/${ROUTE_NAMES.CATALOG_ELEMENT}/${elementId}`;
//   }
//   return `/${ROUTE_NAMES.CATALOG_ELEMENT}/${elementId}?rootCategory=${root}`;
// };

// export const getClientUrl = (clientId: ID): string => `/${ROUTE_NAMES.CLIENTS}/${clientId}`;

// export const getCategoryAgreementUrl = (agreementId: ID): string => `/${ROUTE_NAMES.CATEGORY_AGREEMENT}/${agreementId}`;
// export const getPriceAgreementUrl = (agreementId: ID): string => `/${ROUTE_NAMES.PRICE_AGREEMENT}/${agreementId}`;
// export const getConsignmentAgreementUrl = (agreementId: ID): string =>
//   `/${ROUTE_NAMES.CONSIGNMENT_AGREEMENT}/${agreementId}`;

// export const getTenantUrl = (warehouseId: ID, tenantId: ID): string =>
//   `/${ROUTE_NAMES.TENANT}/${tenantId}?warehouseId=${warehouseId}`;
// export const getWarehouseUrl = (warehouseId: ID): string => `/${ROUTE_NAMES.WAREHOUSES}/${warehouseId}`;
// export const getTcpUrl = (tcpId: ID): string => `/${ROUTE_NAMES.TCP}/${tcpId}`;
// export const getTcpRequestUrl = (tcpRequestId: ID): string => `/${ROUTE_NAMES.TKP_REQUESTS}/${tcpRequestId}`;
// export const getComplexRequirementUrl = (requirementId: ID): string =>
//   `/${ROUTE_NAMES.COMPLEX_REQUIREMENT}/${requirementId}`;
// export const getOrderUrl = (orderId: ID): string => `/${ROUTE_NAMES.ORDER}/${orderId}`;
// export const getSupplyContractUrl = (supplyContractId: ID): string =>
//   `/${ROUTE_NAMES.SUPPLY_CONTRACT}/${supplyContractId}`;
