/**
 *
 * Layout
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import { logoutAction } from 'app/containers/LoginPage/actions';
import { Toast } from 'app/containers/Toast';
import Sidebar from 'app/containers/Sidebar';
import { makeSelectPathName } from 'app/containers/App/selectors';

import './style.less';
import UserPanel from '../UserPanel';

const isRootOnlyPath = path => {
  const firstSign = path[0];
  const lastSign = path[path.length - 1];
  return firstSign === '/' && lastSign === '/';
};

const isWithoutSidebar = (pathname) => {
  const routes = [ROUTE_NAMES.AUTH_LOGIN, ROUTE_NAMES.ACCOUNTS];

  return routes.reduce((curr, next) => {
    const isOnlyRoot = isRootOnlyPath(next);
    if (isOnlyRoot) {
      const isRootPath = pathname.split('/').filter((item) => !!item).length === 1;
      if (isRootPath && next.includes(pathname)) {
        return true;
      }
      return curr || pathname.split('/')[1].includes(next);
    }
    return curr || pathname.split('/')[1].includes(next);
  }, false);
};

function Layout({
  children,
  pathname = '/',
  logout,
  isAuthorized,
  rolesData,
  clientId,
  accounts,
  setClientId,
  openInviteCompanyModal
}) {
  const withoutSidebar = useMemo(() => isWithoutSidebar(pathname), [pathname]);
  const classes = useMemo(
    () =>
      classnames({
        'Content Layout__content': true,
        withSidebar: isAuthorized && !withoutSidebar
      }),
    [withoutSidebar, isAuthorized]
  );
  const showPanels = useMemo(() => !withoutSidebar && isAuthorized, [withoutSidebar, isAuthorized]);
  return (
    <div data-testid="Layout" className="Layout">
      <Toast />
      {showPanels && <Sidebar logout={logout} rolesData={rolesData} />}
      <div className={classes}>
        {showPanels && (
          <UserPanel
            openInviteCompanyModal={openInviteCompanyModal}
            logout={logout}
            clientId={clientId}
            accounts={accounts}
            setClientId={setClientId}
          />
        )}
        {children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  pathname: PropTypes.string,
  children: PropTypes.node,
  logout: PropTypes.func,
  isAuthorized: PropTypes.bool,
  rolesData: PropTypes.object,
  accounts: PropTypes.array,
  clientId: PropTypes.string,
  setClientId: PropTypes.func,
  openInviteCompanyModal: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  pathname: makeSelectPathName()
});

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(logoutAction());
    },
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default withConnect;
