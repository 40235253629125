import { takeLatest, call, put } from 'redux-saga/effects';
import { getStubApi } from './api';
import { getStubErrorAction, getStubSuccessAction } from './actions';
import { GET_STUB } from './constants';

export function* getStubSaga({ payload = {} } = {}) {
  const { something } = payload;
  try {
    const result = yield call(getStubApi, something);
    yield put(getStubSuccessAction(result));
  } catch (err) {
    yield put(getStubErrorAction(err));
  }
}

export default function* stubSaga() {
  yield takeLatest(GET_STUB, getStubSaga);
}
