/**
 *
 * Calendar
 *
 */

import React, { FC, memo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import './style.less';
import { EventClickArg, EventContentArg } from '@fullcalendar/core';

interface CalendarProps {
  customEventComponent?: FC<EventContentArg>;
  events?: any;
  onEventClick?: (data: EventClickArg) => void;
  nextMonth: () => void;
  prevMonth: () => void;
  today: () => void;
  initialDate: Date;
}

export function Calendar(props: CalendarProps) {
  const { customEventComponent, events, onEventClick, nextMonth, prevMonth, today, initialDate } = props;

  return (
    <div data-testid="Calendar" className="Calendar">
      <FullCalendar
        initialDate={initialDate}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        // weekends={false}
        events={events}
        eventContent={customEventComponent}
        eventClick={onEventClick}
        // eventColor='purple'
        // eventTextColor='yellow'

        // contentHeight={600}
        // height={600}
        aspectRatio={1.8}
        eventOrder="order"
        headerToolbar={{
          start: 'customToday',
          center: 'customPrev title customNext',
          end: ''
        }}
        // dayMaxEvents={2}
        locale={ruLocale}
        customButtons={{
          customNext: {
            text: '',
            click: nextMonth,
            icon: 'chevron-right'
          },
          customPrev: {
            text: '',
            click: prevMonth,
            icon: 'chevron-left'
          },
          customToday: {
            text: 'Сегодня',
            click: today
          }
        }}
      />
    </div>
  );
}

export default memo(Calendar);
