import Button from 'app/components/Button';
import PropTypes from 'prop-types';
import SuccessIcon from 'app/containers/Toast/icons/SuccessIcon';
import React from 'react';

import './style.less';

export const SuccessToast = props => {
  const { message, callback, callbackButtonName = 'Открыть' } = props;

  return (
    <div className="Toast">
      <div className="Toast__message">
        <div>{message}</div>
      </div>
      {callback && (
        <div className="Toast__actions">
          <Button
            className="secondary"
            onClick={callback}
            text={callbackButtonName}
          />
          <Button className="secondary" text="Отмена" />
        </div>
      )}
    </div>
  );
};

SuccessToast.propTypes = {
  message: PropTypes.string,
  callbackButtonName: PropTypes.string,
  callback: PropTypes.func
};
