/* eslint-disable no-param-reassign */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createStateStatus } from 'utils/stateStatus';
import { ForbiddenState, getForbiddenActionPayloadType } from './types';
import { Forbidden } from '../types';

export const initialState: ForbiddenState = {
  forbidden: {
    ...createStateStatus<Forbidden[]>()
  }
  // [INSERT NEW ENTITY IN INITIAL STATE KEY ABOVE] < Needed for generating actions seamlessly
};

const slice = createSlice({
  name: 'forbidden',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getForbiddenAction(state, action: PayloadAction<getForbiddenActionPayloadType>) {
      state.forbidden.isFetching = true;
      state.forbidden.isError = null;
    },
    getForbiddenSuccessAction(state, action: PayloadAction<Forbidden[]>) {
      state.forbidden.isFetching = false;
      state.forbidden.isDone = true;
      state.forbidden.data = action.payload;
    },
    getForbiddenErrorAction(state, action: PayloadAction<Error>) {
      state.forbidden.isFetching = false;
      state.forbidden.isDone = true;
      state.forbidden.isError = action.payload;
    },
    resetForbiddenStatusAction(state) {
      state.forbidden.isFetching = false;
      state.forbidden.isDone = false;
      state.forbidden.isError = null;
      state.forbidden.data = undefined;
    }
    // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating actions seamlessly
  }
});

export const { actions: forbiddenActions, reducer, name } = slice;
