/*
 *
 * Sidebar actions
 *
 */

import { SIDEBAR_CLICK } from './constants';

export function sidebarClickAction() {
  return {
    type: SIDEBAR_CLICK
  };
}
