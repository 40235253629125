import { Theme } from 'theme-ui';

export const theme: Theme = {
  colors: {
    text: '#161616',
    text02: '#525252',
    background: '#fff',
    primary: '#0F62FE',
    secondary: '#30c',
    muted: '#f6f6f9',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
    borderColor: '#8D8D8D',
    borderHover: '#b3b3b3',
    backgroundGray: '#F4F4F4',
    fancyBackground: '#F4F8FC',
    support: '#24A148'
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '40px'
  },
  //      0, 1, 2, 3, 4,  5,  6,  7,  8
  space: [0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 64, 80, 96, 160, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    default: 2,
    circle: 99999
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)'
  },
  // rebass variants
  text: {
    pageTitle: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 'normal'
    },
    text_size_14_bold: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600
    },
    text02_size_12: {
      fontSize: '12px',
      lineHeight: '16px',
      color: 'text02'
    },
    text02_size_14: {
      fontSize: '14px',
      lineHeight: '18px',
      color: 'text02'
    },
    text02_size_14_bold: {
      fontSize: '14px',
      lineHeight: '18px',
      color: 'text02',
      fontWeight: 600
    },
    // text_size_18: {
    //   fontSize: '18px',
    //   lineHeight: '24px'
    // },
    // text02_size_18: {
    //   fontSize: '18px',
    //   lineHeight: '24px',
    //   color: 'text02'
    // },
    heading: {
      fontSize: 6, // 40
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading'
      // color: 'gray',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading'
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  // variants: {
  //   avatar: {
  //     width: 'avatar',
  //     height: 'avatar',
  //     borderRadius: 'circle'
  //   },
  //   card: {
  //     p: 2,
  //     bg: 'background',
  //     boxShadow: 'card'
  //   },
  //   link: {
  //     color: 'primary'
  //   },
  //   nav: {
  //     fontSize: 1,
  //     fontWeight: 'bold',
  //     display: 'inline-block',
  //     p: 2,
  //     color: 'inherit',
  //     textDecoration: 'none',
  //     ':hover,:focus,.active': {
  //       color: 'primary'
  //     }
  //   },
  //   tableLoaderContainer: {
  //     height: '440px',
  //     display: 'flex',
  //     margin: 'auto'
  //   },
  //   tableNotificationContainer: {
  //     marginTop: '48px'
  //   },
  //   fancyWrapper: {
  //     padding: '16px 16px 24px',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     bg: 'fancyBackground',
  //     alignItems: 'flex-start',
  //     borderRadius: '8px'
  //   }
  // },
  buttons: {
    account: {
      border: '1px solid',
      borderColor: 'primary',
      bg: 'background',
      color: 'primary'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'primary',
      textDecoration: 'none'
    },
    hr: {
      border: '1px solid #E0E0E0'
    }
    // input: {
    //   padding: '0 10px',
    //   border: 'none',
    //   borderBottom: '1px solid',
    //   borderColor: 'borderColor',
    //   borderRadius: '0',
    //   bg: 'backgroundGray',
    //   '&:hover': {
    //     borderColor: 'borderHover'
    //   },
    //   '&:focus': {
    //     borderColor: 'primary'
    //   }
    // },
  },
  forms: {
    input: {
      padding: '0 10px',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'borderColor',
      borderRadius: '0',
      bg: 'backgroundGray',
      '&:hover': {
        borderColor: 'borderHover'
      },
      '&:focus': {
        borderColor: 'primary'
      }
    },
    switch: {
      'input:checked ~ &': {
        backgroundColor: 'support'
      }
    },
    label: {
      fontSize: '12px',
      color: 'text02',
      marginBottom: '8px'
      // width: '100%'
    },
    bigLabel: {
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0.25px',
      marginTop: '24px'
    },
    checkbox: {
      width: '20px',
      height: '20px',
      margin: 'auto',
      color: 'text02'
    }
  }
};
