/*
 *
 * Products constants
 *
 */

export const CONTAINER_KEY = 'products';

export enum PRODUCT_CATEGORY {
  TS_PRODUCT = 'tsProduct',
  PARTNER_PRODUCT = 'partnerProduct',
  ADMINISTRATION = 'administration'
}

export const PRODUCTS_TABS = [
  {
    name: 'Администрирование Цифра-S',
    value: 'administration'
  },
  {
    name: 'Цифра-S',
    value: 'tsProduct'
  },
  {
    name: 'Партнеры',
    value: 'partnerProduct'
  }
];

export const PRODUCT_TYPES = {
  COMMON: 'common',
  BUYER: 'buyer',
  PROVIDER: 'provider',
  SUPPLIER: 'supplier',
  ADMIN: 'admin'
};

export const PRODUCT_TITLES = {
  ADMIN: 'Админка',
  ADMIN_UM: 'Управление пользователями',
  ADMIN_CATEGORIES: 'Управление категориями'
};
