import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { pageKey } from './constants';

/**
 * Direct selector to the sidebar state domain
 */

const selectSidebarDomain = state => state[pageKey] || initialState;

/**
 * Other specific selectors
 */

const makeSelectIsSidebarOpen = () =>
  createSelector(selectSidebarDomain, substate => substate.isOpen);

/**
 * Default selector used by Sidebar
 */

const makeSelectSidebar = () =>
  createSelector(selectSidebarDomain, substate => substate);

export default makeSelectSidebar;
export { selectSidebarDomain, makeSelectIsSidebarOpen };
