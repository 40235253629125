/**
 *
 * Modal
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import NativeModal from 'react-modal';
import { useInjectReducer } from 'redux-injectors';
import Loader from '../../components/Loader';
import makeSelectModal from './selectors';
import { CONTAINER_KEY } from './constants';
import reducer from './reducer';

import './style.less';
import CloseIcon from './components/icons/close';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    borderRadius: 0
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 11
  }
};

if (NativeModal.setAppElement) {
  NativeModal.setAppElement('body');
}

export function Modal(props) {
  useInjectReducer({ key: CONTAINER_KEY, reducer });
  const {
    title = '',
    helpText,
    preset,
    children,
    isOpen = false,
    hasPopover = false,
    noHeader = false,
    closeModal,
    bgWhite,
    maxWidth,
    isLoading = false
  } = props;

  const modalTitle = <div>{title}</div>;

  return (
    <NativeModal
      isOpen={isOpen}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      // ariaHideApp={false}
    >
      <div className="Modal">
        {isLoading && <Loader />}
        <div className="Modal__close">
          <div onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        {!noHeader && (
          <div style={{ maxWidth }} className={`Modal__header ${bgWhite ? 'bgWhite' : ''}`}>
            {modalTitle}
          </div>
        )}
        <div className="Modal__content">{children}</div>
      </div>
    </NativeModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  helpText: PropTypes.string,
  preset: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  hasPopover: PropTypes.bool,
  bgWhite: PropTypes.bool,
  noHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  modal: makeSelectModal()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Modal);
