import PropTypes from 'prop-types';
import React from 'react';
import LogoIcon from 'app/components/Icons/LogoIcon'
import './style.less';

// import LogoIcon from '../Icons/logo';

const AuthZoneWrapper = ({ children }) => (
  <div className="FormContainer">
    <div className="FormContainer__header">
      <div className="logo">
        <LogoIcon />
      </div>
    </div>

    <div className="FormContainer__content">{children}</div>

    <div className="FormContainer__Contacts"></div>
  </div>
);

AuthZoneWrapper.propTypes = {
  children: PropTypes.node
};

export default AuthZoneWrapper;
