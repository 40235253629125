/**
 *
 * PaymentsEvent
 *
 */

import { EventContentArg } from '@fullcalendar/core';
import { PaymentEvent } from 'app/containers/EventsPage/types';
import { ORDER_PAYMENT_TYPES } from 'app/containers/OrderPage/constants';
import { digitalSeparator } from 'utils/currency/digitalSeparator';
import React, { memo } from 'react';
import IncomeIcon from '../icons/income';

import './style.less';

interface ExtendedProps extends PaymentEvent {}

interface PaymentsEventProps extends EventContentArg {}

export function PaymentsEvent(props: PaymentsEventProps) {
  const { income, paymentType, count, amountInRub = 0 } = props.event.extendedProps.eventData as ExtendedProps;

  const style = {
    width: '100%',
    backgroundColor: paymentType === ORDER_PAYMENT_TYPES.PLAN ? '#8884d8' : '#82ca9d',
    color: '#fff'
  };

  // const eventTitle = paymentType === ORDER_PAYMENT_TYPES.PLAN ? 'Плановые' : 'Фактические';

  return (
    <div data-testid="PaymentsEvent" className="PaymentsEvent" style={style}>
      {/* <span>{`${eventTitle} (${count})`}</span> */}

      <span>{`(${count})`}</span>
      <span className="trancatable">{`${digitalSeparator(amountInRub)} RUB`}</span>
      <div className={`icon ${income ? 'income' : 'outcome'}`}>
        <IncomeIcon />
      </div>
    </div>
  );
}

export default memo(PaymentsEvent);
