/*
 *
 * QueryFilters reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import { RESET_QUERY_FILTERS, SET_QUERY_FILTERS } from './constants';

export const initialState = {
  ...createStateStatus(),
  queryFilters: {}
};

/* eslint-disable default-case, no-param-reassign */
const queryFiltersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_QUERY_FILTERS:
        if (!draft.queryFilters) {
          draft.queryFilters = {};
        }
        draft.queryFilters[action.payload.id] = {
          ...draft.queryFilters[action.payload.id],
          ...action.payload.filters
        };
        break;
      case RESET_QUERY_FILTERS:
        delete draft.queryFilters[action.payload.id];
        break;
    }
  });

export default queryFiltersReducer;
