import { createSelector } from 'reselect';

const selectRouter = state => state.router;

const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState && routerState.location
  );
const makeSelectPathName = () =>
  createSelector(
    selectRouter,
    routerState => routerState && routerState.location.pathname
  );

export { makeSelectLocation, makeSelectPathName };
