import * as React from 'react';
import { memo } from 'react';

const LockIcon = (props) => (
  <svg className="LockIcon" width="16px" height="22px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 9.5h-1.5V5a4.5 4.5 0 1 0-9 0v4.5H2A1.5 1.5 0 0 0 .5 11v9A1.5 1.5 0 0 0 2 21.5h12a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14 9.5ZM5 5a3 3 0 1 1 6 0v4.5H5V5Zm9 15H2v-9h12v9Z"
      fill="#7C8793"
    />
  </svg>
);

const Memo = memo(LockIcon);
export default Memo;
