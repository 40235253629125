/*
 *
 * BackNavigator constants
 *
 */

export const CONTAINER_KEY = 'backNavigator';

export const SET_BACK_NAVIGATOR_NAME =
  'app/backNavigator/SET_BACK_NAVIGATOR_NAME';
export const SET_BACK_NAVIGATOR_URL =
  'app/backNavigator/SET_BACK_NAVIGATOR_URL';
export const RESET_BACK_NAVIGATOR = 'app/backNavigator/RESET_BACK_NAVIGATOR';
