/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * PopoverMenu
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { Popover } from 'react-tiny-popover';
import { Link } from 'react-router-dom';
import { ROUTE_NAMES } from 'app/containers/App/constants';
import UserIcon from './icons/user';
import ArrowIcon from './icons/arrow';
import AddIcon from './icons/add';

import './style.less';

function Content(setIsOpen, level, userName, setLevel, accounts, handleAddCompanyClick, logout) {
  return (
    <div
      onClick={(e) => {
        if (isTouchableNodes(e)) {
          return setIsOpen(false);
        }
      }}
      className="UserMenu"
    >
      {!level ? (
        <div className="UserMenu__item UserMenu__item_BorderBottom">
          <h3>{userName}</h3>
        </div>
      ) : null}
      {level ? (
        <div className="UserMenu__item back UserMenu__item_BorderBottom" onClick={() => setLevel(null)}>
          <ArrowIcon /> {findLevel(data, level).backButtonLabel}
        </div>
      ) : null}
      {renderUserMenu(data, level, setLevel, accounts && accounts.length === 1, handleAddCompanyClick)}

      <div className="UserMenu__item" onClick={() => logout()}>
        Выйти
      </div>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
const AddOrganizationComponent = ({ openInviteCompanyModal }) => (
  <div className="UserMenu__item UserMenu__item_BorderBottom action" onClick={() => openInviteCompanyModal()}>
    {' '}
    <span>Добавить организацию</span> <AddIcon />
  </div>
);

const data = [
  // {
  //   id: '_111',
  //   order: 1,
  //   label: 'Сменить организацию',
  //   backButtonLabel: 'Организации',
  //   isSingleAccount: false,
  //   renderSingleAccountAlternative: (action) => <AddOrganizationComponent openInviteCompanyModal={action} />,
  //   child: [
  //     { id: 4, label: 'ООО Раз', action: () => console.log('action') }, // пример
  //     { id: 5, label: 'ООО Два', action: () => console.log('action') } // пример
  //   ]
  // },
  {
    id: '_222',
    order: 1,
    label: 'Настройки',
    link: `/${ROUTE_NAMES.SETTINGS}`
  }
];

const findLevel = (data, level) =>
  level === null ? undefined : data.find((item) => item.id.toString() === level.toString());

const isTouchableNodes = (e) =>
  !e.target.classList.contains('sub') &&
  !e.target.classList.contains('back') &&
  !e.target.classList.contains('ArrowIcon');

const renderUserMenu = (data, level, setLevel, isSingleAccount, openInviteCompanyModal) => {
  let currentData = data;
  if (level) {
    currentData = findLevel(data, level).child;
  }
  const result =
    currentData &&
    currentData.map((item) => {
      if (item.link) {
        return (
          <Link
            key={item.id}
            className="UserMenu__item UserMenu__item_BorderBottom"
            onClick={() => item.action && item.action(item.id)}
            to={item.link}
          >
            {item.label}
          </Link>
        );
      }
      if (!item.isSingleAccount && isSingleAccount) {
        return item.renderSingleAccountAlternative(openInviteCompanyModal);
      }
      if (!item.child) {
        return (
          <div
            key={item.id}
            className="UserMenu__item UserMenu__item_BorderBottom"
            onClick={() => item.action && item.action(item.id)}
          >
            {item.label}
          </div>
        );
      }
      return (
        <div
          key={item.id}
          className="UserMenu__item sub UserMenu__item_BorderBottom"
          onClick={() => {
            setLevel(item.id);
          }}
        >
          {item.label} <ArrowIcon />
        </div>
      );
    });

  return result;
};

function UserMenu({ logout, user, accounts, clientId, setClientId, goToClientAdding }) {
  const [isOpen, setIsOpen] = useState(false);
  const [level, setLevel] = useState(null);
  const throttledSetClientId = throttle(setClientId, 500);
  data[0].child = accounts
    ?.map((account) => {
      if (account.id.toString() !== clientId) {
        return {
          id: account.id,
          label: account.title,
          action: throttledSetClientId
          // link: `/${ROUTE_NAMES.DOCUMENTS}`
        };
      }
      return null;
    })
    .filter((account) => !!account);

  const currentUser = user;
  const userName = currentUser && currentUser.name;

  const handleAddCompanyClick = () => {
    setLevel(null);
    goToClientAdding();
  };

  return (
    <div className="UserMenuWrapper">
      <Popover
        positions={['bottom', 'left']}
        padding={14}
        isOpen={isOpen}
        containerClassName="UserMenuWrapper__popover"
        onClickOutside={(e) => {
          if (isTouchableNodes(e)) {
            setLevel(null);
            return setIsOpen(false);
          }
        }}
        content={() => Content(setIsOpen, level, userName, setLevel, accounts, handleAddCompanyClick, logout)}
      >
        <div className="image" onClick={() => setIsOpen(!isOpen)}>
          <UserIcon />
        </div>
      </Popover>
    </div>
  );
}

UserMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  setClientId: PropTypes.func.isRequired,
  goToClientAdding: PropTypes.func.isRequired,
  clientId: PropTypes.string
};

export default memo(UserMenu);
