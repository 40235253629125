/**
 *
 * Sidebar
 *
 */

import React, { useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Portal } from 'react-portal';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import makeSelectRoles from 'app/containers/Roles/selectors';

import { ROUTE_NAMES } from 'app/containers/App/constants';
import BurgerIcon from 'app/components/Icons/BurgerIcon';
import { computeRestrictionsByPath } from 'app/containers/Roles/computeRestrictionsByPath';

import { pageKey, MENU_IDS, MENU_ITEMS, UTILS_ITEMS, ALL_ITEMS, COMPANY_ITEMS } from './constants';
import { makeSelectIsSidebarOpen } from './selectors';
import { sidebarClickAction } from './actions';
import reducer from './reducer';
import saga from './saga';

import MenuItem from './components/MenuItem';

import './style.less';
import CompanyIcon from './icons/CompanyIcon';
import { makeSelectAccount } from '../AccountsPage/selectors';

const sidebarTransitionStyles = {
  entering: { width: '0px' },
  entered: { width: 'var(--sidebar-width)' },
  exiting: { width: 'var(--sidebar-width)' },
  exited: { width: '0px' }
};

const duration = 250;
const sidebarStyle = {
  transition: `width ${duration}ms cubic-bezier`
};

export function Sidebar({ isOpen, sidebarClick, goTo, rolesData, accountState }) {
  useInjectReducer({ key: pageKey, reducer });
  useInjectSaga({ key: pageKey, saga });

  const [activeId, setActiveId] = useState();
  const companyName = accountState?.data?.title ?? 'Неизвестно';
  const location = useLocation();
  const { pathname } = location;
  const currentPath = pathname.split('/')[1];

  const handleClick = useCallback(
    ({ id, path }) => {
      goTo(`/${path}`);
      setActiveId(id);
    },
    [goTo]
  );

  const menuItemsGenerator = useCallback(
    (items) =>
      items.map(({ id, name, path, icon, foldedName }) =>
        !computeRestrictionsByPath(rolesData, path) ? (
          <MenuItem
            key={id}
            id={id}
            name={name || companyName}
            path={Array.isArray(path) ? path[0] : path}
            icon={icon}
            foldedName={foldedName}
            isActive={activeId === id}
            onClick={handleClick}
            isOpen={isOpen}
            disabled={computeRestrictionsByPath(rolesData, path)}
          />
        ) : null
      ),
    [activeId, companyName, handleClick, isOpen, rolesData]
  );

  useEffect(() => {
    if (!activeId) {
      if (!currentPath) {
        setActiveId(MENU_IDS[ROUTE_NAMES.ROOT]);
        return;
      }
      const path = ALL_ITEMS.find((item) => {
        if (Array.isArray(item.path)) return item.path.includes(currentPath);
        return item.path === currentPath;
      });
      setActiveId(path ? path.id : null);
    }
  }, []);

  useEffect(() => {
    if (activeId) {
      const currentMenuKey = Object.entries(MENU_IDS).find((item) => item[1] === activeId)[0];
      if (MENU_IDS[currentMenuKey] && currentMenuKey !== currentPath) setActiveId(MENU_IDS[currentPath]);
    }
  }, [activeId, currentPath]);

  const burgerClassNames = classnames({
    'BurgerIcon--open': isOpen
  });

  const logoClassNames = classnames({
    Sidebar__logo: true,
    'Sidebar__logo--open': isOpen,
    'Sidebar__logo--closed': !isOpen
  });

  return (
    <>
      {isOpen && (
        <Portal>
          <div onClick={sidebarClick} className="SidebarPortal" />
        </Portal>
      )}
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <>
            <div
              data-testid="Sidebar"
              className="Sidebar"
              style={{ ...sidebarStyle, ...sidebarTransitionStyles[state] }}
            >
              <div className={logoClassNames}>
                <BurgerIcon className={burgerClassNames} onClick={sidebarClick} fill="#fff" />
                {state !== 'exited' && (
                  <div className="Sidebar__title">
                    <CompanyIcon />
                    {/* <div className="Sidebar__service">Фабрика</div> */}
                  </div>
                )}
              </div>
              <div className="Menu Sidebar__menu Sidebar__menu--company">{menuItemsGenerator(COMPANY_ITEMS)}</div>
              <div className="Sidebar__separator" />
              <div className="Menu Sidebar__menu">{menuItemsGenerator(MENU_ITEMS)}</div>
              <div className="Menu Sidebar__menu Sidebar__menu--bottom">{menuItemsGenerator(UTILS_ITEMS)}</div>
            </div>
            <div className="Sidebar__stab" style={{ ...sidebarStyle, ...sidebarTransitionStyles[state] }} />
          </>
        )}
      </Transition>
    </>
  );
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  sidebarClick: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  rolesData: PropTypes.object,
  accountState: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelectIsSidebarOpen(),
  rolesData: makeSelectRoles(),
  accountState: makeSelectAccount()
});

function mapDispatchToProps(dispatch) {
  return {
    sidebarClick: () => dispatch(sidebarClickAction()),
    goTo: (path) => dispatch(push(path))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Sidebar);
