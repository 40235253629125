/*
 *
 * Sidebar reducer
 *
 */
import produce from 'immer';
import { SIDEBAR_CLICK, sidebarInitialValue } from './constants';

export const initialState = {
  isOpen: sidebarInitialValue
};

/* eslint-disable default-case, no-param-reassign */
const sidebarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SIDEBAR_CLICK:
        draft.isOpen = !state.isOpen;
        break;
    }
  });

export default sidebarReducer;
