import React from 'react';

function Arrow() {
  return (
    <svg
      className="ArrowIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      fill="none"
      viewBox="0 0 10 16"
    >
      <path
        fill="#B8BDCE"
        d="M1.75 15.25a.998.998 0 01-.693-1.72L6.807 8l-5.75-5.53a1 1 0 111.386-1.442l6.5 6.25a.998.998 0 010 1.442l-6.5 6.25a1 1 0 01-.693.28"
      />
    </svg>
  );
}

export default Arrow;
