import { logOut } from 'app/services/oauthservice';

export const responseTransform = async (response: {
  status?: number;
  ok: boolean;
  data?: {
    message?: string;
  };
}) => {
  const { status } = response;
  if (status && status === 401) {
    logOut();
    window.dispatchEvent(event as Event);
    throw new Error('Token не валиден');
  }

  if (!response.ok) {
    throw new Error(response?.data?.message ?? 'Ошибка при выполнении запроса');
  }
};
