/*
 *
 * Roles reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import { SET_ROLES_DATA } from './constants';

export const initialState = {
  roles: createStateStatus()
};

/* eslint-disable default-case, no-param-reassign */
// eslint-disable-next-line default-param-last
const rolesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ROLES_DATA:
        draft.roles = action.payload;
        break;
    }
  });

export default rolesReducer;
