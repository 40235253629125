import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { CONTAINER_KEY } from './constants';

/**
 * Direct selector to the modal state domain
 */

const selectModalDomain = (state) => state[CONTAINER_KEY] || initialState;

const makeSelectModalId = () => createSelector(selectModalDomain, (substate) => substate.modalId);

export default makeSelectModalId;
export { selectModalDomain, makeSelectModalId };
