/*
 *
 * Stub constants
 *
 */

export const CONTAINER_KEY = 'stub';

export const GET_STUB = 'app/stub/GET_STUB';
export const GET_STUB_SUCCESS = 'app/stub/GET_STUB_SUCCESS';
export const GET_STUB_FAIL = 'app/stub/GET_STUB_FAIL';
