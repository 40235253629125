import { GET_STUB, GET_STUB_SUCCESS, GET_STUB_FAIL } from './constants';

export function getStubAction(someId) {
  return {
    type: GET_STUB,
    payload: {
      someId
    }
  };
}

export function getStubSuccessAction(stub) {
  return {
    type: GET_STUB_SUCCESS,
    payload: {
      stub
    }
  };
}

export function getStubErrorAction(error) {
  return {
    type: GET_STUB_FAIL,
    payload: {
      error
    }
  };
}
