import { makeSelectIsBuyer, makeSelectIsProvider } from 'app/containers/Roles/selectors';
import { store } from '../../store/configureStore';

type apiFunc = (...args: any[]) => Promise<unknown> | undefined;

interface ApiObject {
  [key: string]: apiFunc;
}

enum PathKeys {
  buyer = 'buyer',
  supplier = 'supplier',
  provider = 'provider'
}

interface ApisObject {
  [PathKeys.buyer]: ApiObject;
  [PathKeys.supplier]: ApiObject;
  [PathKeys.provider]: ApiObject;
}

interface ConstructorArg {
  commonAndSupplierApis: ApiObject;
  buyerApis?: ApiObject;
  providerApis?: ApiObject;
}

export class ApiManager<K extends string = string> {
  private apisObject: ApisObject;

  private store: typeof store;

  constructor(apiObjects: ConstructorArg) {
    const { commonAndSupplierApis = {}, buyerApis = {}, providerApis = {} } = apiObjects;
    this.apisObject = {
      [PathKeys.buyer]: {
        ...commonAndSupplierApis,
        ...buyerApis
      },
      [PathKeys.supplier]: {
        ...commonAndSupplierApis
      },
      [PathKeys.provider]: {
        ...commonAndSupplierApis,
        ...providerApis
      }
    };
    this.store = store;
  }

  private getApiKeyValue = (isBuyer: boolean, isProvider: boolean): string => {
    if (isBuyer) {
      return PathKeys.buyer;
    }
    if (isProvider) {
      return PathKeys.provider;
    }
    return PathKeys.supplier;
  };

  getApiFunction = (name: K): apiFunc => {
    const isBuyer = true;
    const isProvider = true;
    const func = this.apisObject[this.getApiKeyValue(isBuyer, isProvider)][name];
    return func;
  };
}
