/**
 *
 * Toogler
 *
 */

import React, { memo } from 'react';
import { Switch } from 'theme-ui';
// import PropTypes from 'prop-types';

import './style.less';

interface TogglerProps {
  onChange?: React.ChangeEventHandler;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
  id?: string;
  variant?: string;
}

export function Toggler(props: TogglerProps) {
  return (
    <div data-testid="Toggler" className="Toggler">
      <Switch {...props} />
    </div>
  );
}

export default memo(Toggler);
