import React from 'react';

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#262C40"
        d="M17.558 16.279A9.836 9.836 0 10.156 9.999c0 2.297.81 4.52 2.286 6.28l-.014.011c.05.06.106.11.156.169.064.072.131.14.197.211.197.213.4.419.612.612.064.058.131.113.197.17.225.194.456.377.696.55.03.021.058.048.09.07v-.01a9.774 9.774 0 0011.25 0v.01c.03-.022.058-.05.09-.071a9.81 9.81 0 00.696-.55c.065-.056.132-.11.196-.17a9.86 9.86 0 00.612-.611c.066-.07.133-.139.197-.212.05-.058.107-.108.156-.168l-.015-.011zM10 4.375a3.164 3.164 0 110 6.329 3.164 3.164 0 010-6.33zM4.38 16.279a3.513 3.513 0 013.51-3.467h4.22a3.513 3.513 0 013.51 3.467 8.395 8.395 0 01-11.24 0z"
      />
    </svg>
  );
}

export default UserIcon;
