import React from 'react';
import PropTypes from 'prop-types';

function SearchIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      className="Search__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#525252"
        d="M14.5 13.793l-3.776-3.776a5.509 5.509 0 10-.707.707l3.776 3.776.707-.707zM2 6.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
      ></path>
    </svg>
  );
}

SearchIcon.propTypes = {
  onClick: PropTypes.func
};

export default SearchIcon;
