import { format, parseISO, formatISO, parse } from 'date-fns';
import { ru } from 'date-fns/locale';

// 'dd MMMM yyyy в HH:mm' => 19 февраля 2023 в 12:25
export const backToFrontFormatDate = (date, template = 'dd.MM.yyyy') =>
  date ? format(parseISO(date), template, { locale: ru }) : '';

export const backToFrontFormatDateTime = (date, template = 'dd.MM.yyyy HH:mm') =>
  date ? format(parseISO(date), template, { locale: ru }) : '';

// Дата в формате ISO-8601
export const frontToBackFormatDate = (date) => (date ? formatISO(date, { representation: 'date' }) : null);
export const frontToBackFormatDateTime = (date) => (date ? formatISO(date) : null);

export const parseDate = (date) => (date ? parse(date, 'dd.MM.yyyy', new Date()) : null);
