import {
  maxCharLimitMsg,
  minCategoriesLimitMsg,
  minCharLimitMsg,
  minFileLimitMsg,
  minPositionsLimitMsg
} from './utils';

export const YUP = {
  REQUIRED_FIELD: 'Необходимое поле',
  INCORRECT_DATA: 'Не корректные данные',
  TYPE_ERROR_NUMBER: 'Поле должно содержать только цифры',
  MAX_CHAR_LIMIT: maxCharLimitMsg,
  MIN_CHAR_LIMIT: minCharLimitMsg,
  MIN_FILE_LIMIT: minFileLimitMsg,
  MIN_CATEGORIES_LIMIT: minCategoriesLimitMsg,
  MIN_POSITIONS_LIMIT: minPositionsLimitMsg
};
