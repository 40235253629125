/*
 *
 * LoginPage actions
 *
 */

import {
  LOGIN_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGIN_FAIL_ACTION,
  LOGOUT_ACTION,
  LOGOUT_FAIL_ACTION,
  LOGOUT_SUCCESS_ACTION,
  SET_USER_ACTION,
  SET_USER_SUCCESS_ACTION,
  SET_USER_FAIL_ACTION,
  CLEAN_LOGIN_ERROR_ACTION
} from './constants';

export function loginAction(username, password) {
  return {
    type: LOGIN_ACTION,
    payload: {
      username,
      password
    }
  };
}

export function loginSuccessAction(user) {
  return {
    type: LOGIN_SUCCESS_ACTION,
    payload: {
      user
    }
  };
}

export function loginErrorAction(error) {
  return {
    type: LOGIN_FAIL_ACTION,
    payload: {
      error
    }
  };
}

export function logoutAction() {
  return {
    type: LOGOUT_ACTION
  };
}

export function logoutSuccessAction() {
  return {
    type: LOGOUT_SUCCESS_ACTION
  };
}

export function logoutErrorAction(error) {
  return {
    type: LOGOUT_FAIL_ACTION,
    payload: {
      error
    }
  };
}

export function setUserAction(user) {
  return {
    type: SET_USER_ACTION,
    payload: { user }
  };
}

export function setUserSuccessAction(user) {
  return {
    type: SET_USER_SUCCESS_ACTION,
    payload: { user }
  };
}

export function setUserFailAction(error) {
  return {
    type: SET_USER_FAIL_ACTION,
    payload: {
      error
    }
  };
}

export function cleanLoginErrorAction() {
  return {
    type: CLEAN_LOGIN_ERROR_ACTION
  };
}
