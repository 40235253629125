import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { showToastAction } from 'app/containers/Toast/actions';
import { logOut } from 'app/services/oauthservice';
import { getSettingsApi, patchPasswordApi, putBaseRoleApi } from './api';
import {
  getSettingsAction,
  getSettingsErrorAction,
  getSettingsSuccessAction,
  patchPasswordSuccessAction,
  patchPasswordErrorAction,
  updateBaseRoleSuccessAction,
  updateBaseRoleErrorAction
} from './actions';
import { GET_SETTINGS, PATCH_PASSWORD, UPDATE_BASE_ROLE } from './constants';

export function* getSettingsSaga({ payload = {} } = {}) {
  const { something } = payload;
  try {
    const result = yield call(getSettingsApi, something);
    yield put(getSettingsSuccessAction(result));
  } catch (err) {
    yield put(getSettingsErrorAction(err));
  }
}

export function* patchPasswordSaga({ payload = {} } = {}) {
  try {
    // const user = yield select(makeSelectUser());
    const result = yield call(patchPasswordApi, payload);
    yield put(patchPasswordSuccessAction(result));
    yield put(
      showToastAction({
        type: toast.TYPE.SUCCESS,
        message: 'Пароль успешно изменен'
      })
    );
    yield logOut();
  } catch (err) {
    yield put(patchPasswordErrorAction(err));
    yield put(
      showToastAction({
        type: toast.TYPE.ERROR,
        message: 'Не удалось сменить пароль'
      })
    );
  }
}

export function* updateBaseRoleSaga({ payload = {} } = {}) {
  const { baseRole } = payload;
  try {
    const result = yield call(putBaseRoleApi, baseRole);
    yield put(updateBaseRoleSuccessAction(result));
    yield put(getSettingsAction());
  } catch (err) {
    yield put(updateBaseRoleErrorAction(err));
  }
}

export default function* settingsSaga() {
  yield takeLatest(UPDATE_BASE_ROLE, updateBaseRoleSaga);
  yield takeLatest(PATCH_PASSWORD, patchPasswordSaga);
  yield takeLatest(GET_SETTINGS, getSettingsSaga);
}
