/*
 *
 * Events constants
 *
 */

export const CONTAINER_KEY = 'events';

export const EVENT_TYPES = {
  PAYMENTS: 'PAYMENTS',
  SHIPMENTS: 'SHIPMENTS'
};

export const EVENTS_PAGE_SWITCH_ITEMS = [
  {
    name: 'Платежи',
    value: EVENT_TYPES.PAYMENTS
  },
  {
    name: 'Поставки',
    value: EVENT_TYPES.SHIPMENTS
  }
];
