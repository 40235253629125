/*
 *
 * Settings reducer
 *
 */
import produce from 'immer';
import { createStateStatus } from 'utils/stateStatus';
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  RESET_SETTINGS_STATUS,
  PATCH_PASSWORD,
  PATCH_PASSWORD_SUCCESS,
  PATCH_PASSWORD_FAIL,
  RESET_PATCH_PASSWORD_STATUS,
  UPDATE_BASE_ROLE,
  UPDATE_BASE_ROLE_SUCCESS,
  UPDATE_BASE_ROLE_FAIL,
  RESET_UPDATE_BASE_ROLE_STATUS
} from './constants';

export const initialState = {
  baseRole: {
    ...createStateStatus()
  },
  password: {
    ...createStateStatus()
  },
  settings: {
    ...createStateStatus(),
    data: undefined
  }
};

/* eslint-disable default-case, no-param-reassign */
const settingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_BASE_ROLE:
        draft.baseRole.isFetching = true;
        draft.baseRole.isError = false;
        break;
      case UPDATE_BASE_ROLE_SUCCESS:
        draft.baseRole.isFetching = false;
        draft.baseRole.isDone = true;
        break;
      case UPDATE_BASE_ROLE_FAIL:
        draft.baseRole.isFetching = false;
        draft.baseRole.isDone = true;
        draft.baseRole.isError = action.payload.error;
        break;
      case RESET_UPDATE_BASE_ROLE_STATUS:
        draft.baseRole.isDone = false;
        draft.baseRole.isFetching = false;
        draft.baseRole.isError = null;
        break;

      case PATCH_PASSWORD:
        draft.password.isFetching = true;
        draft.password.isError = false;
        break;
      case PATCH_PASSWORD_SUCCESS:
        draft.password.isFetching = false;
        draft.password.isDone = true;
        break;
      case PATCH_PASSWORD_FAIL:
        draft.password.isFetching = false;
        draft.password.isDone = true;
        draft.password.isError = action.payload.error;
        break;
      case RESET_PATCH_PASSWORD_STATUS:
        draft.password.isDone = false;
        draft.password.isFetching = false;
        draft.password.isError = null;
        break;

      case GET_SETTINGS:
        draft.settings.isFetching = true;
        draft.settings.isError = false;
        break;
      case GET_SETTINGS_SUCCESS:
        draft.settings.isFetching = false;
        draft.settings.isDone = true;
        draft.settings.data = action.payload.settings;
        break;
      case GET_SETTINGS_FAIL:
        draft.settings.isFetching = false;
        draft.settings.isDone = true;
        draft.settings.isError = action.payload.error;
        break;
      case RESET_SETTINGS_STATUS:
        draft.settings.isFetching = false;
        draft.settings.isDone = false;
        draft.settings.isError = null;
        draft.settings.data = undefined;
        break;
    }
  });

export default settingsReducer;
