import React from 'react';

function AgreementIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3794_146157)" opacity="0.6">
        <mask id="path-1-inside-1_3794_146157" fill="#fff">
          <path
            fillRule="evenodd"
            d="M12.85 4.65l-3.5-3.5C9.25 1.05 9.15 1 9 1H4c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V5c0-.15-.05-.25-.15-.35zM9 2.2L11.8 5H9V2.2zm2 8.8H5v1h6v-1zM5 8h6v1H5V8z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#525252"
          fillRule="evenodd"
          d="M12.85 4.65l-3.5-3.5C9.25 1.05 9.15 1 9 1H4c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V5c0-.15-.05-.25-.15-.35zM9 2.2L11.8 5H9V2.2zm2 8.8H5v1h6v-1zM5 8h6v1H5V8z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#525252"
          d="M9 2.2l.236-.236-.57-.569V2.2H9zM11.8 5v.333h.805l-.57-.569L11.8 5zM8.764 2.436l2.8 2.8.472-.472-2.8-2.8-.472.472zm3.036 2.23v.667-.666zM9.333 5V2.2h-.666V5h.666zm.017-3.85l-.471.471.471-.471zm3.5 3.5l-.471.471.471-.471zM11.8 5v.667h1.61L12.27 4.529 11.8 5zM9 2.2l.471-.471L8.333.59V2.2H9zM9 5h-.667v.667H9V5zm-4 6v-.667h-.667V11H5zm6 0h.667v-.667H11V11zm-6 1h-.667v.667H5V12zm6 0v.667h.667V12H11zm0-4h.667v-.667H11V8zM5 8v-.667h-.667V8H5zm6 1v.667h.667V9H11zM5 9h-.667v.667H5V9zm3.879-7.379l3.5 3.5.942-.942-3.5-3.5-.942.942zM9 1.667l-.024-.003a.252.252 0 01-.053-.017c-.04-.018-.055-.036-.044-.026L9.82.68a1.183 1.183 0 00-.356-.25A1.131 1.131 0 009 .332v1.334zm-5 0h5V.333H4v1.334zM3.667 2c0-.182.151-.333.333-.333V.333A1.67 1.67 0 002.333 2h1.334zm0 12V2H2.333v12h1.334zm.333.333A.336.336 0 013.667 14H2.333A1.67 1.67 0 004 15.667v-1.334zm8 0H4v1.334h8v-1.334zm.333-.333a.336.336 0 01-.333.333v1.334A1.67 1.67 0 0013.667 14h-1.334zm0-9v9h1.334V5h-1.334zm.046.121c.01.011-.008-.005-.026-.044a.252.252 0 01-.017-.053L12.333 5h1.334c0-.153-.027-.31-.095-.465a1.182 1.182 0 00-.25-.356l-.943.942zm-.108-.592l-2.8-2.8-.942.943 2.8 2.8.942-.943zM9 5.667h2.8V4.334H9v1.333zM8.333 2.2V5h1.334V2.2H8.333zM5 11.667h6v-1.334H5v1.334zm.667.333v-1H4.333v1h1.334zM11 11.333H5v1.334h6v-1.334zM10.333 11v1h1.334v-1h-1.334zM11 7.333H5v1.334h6V7.333zM11.667 9V8h-1.334v1h1.334zM5 9.667h6V8.333H5v1.334zM4.333 8v1h1.334V8H4.333z"
          mask="url(#path-1-inside-1_3794_146157)"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3794_146157">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default React.memo(AgreementIcon);
