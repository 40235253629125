export interface ReduxStateStatusWithoutName<T = unknown> {
  isDone: boolean;
  isFetching: boolean;
  isError: Error | null;
  data?: T;
}

export interface ReduxStateStatusWithName<T = unknown> {
  [key: string]: ReduxStateStatusWithoutName<T>;
}

export type ReduxStateStatus<T = unknown> = ReduxStateStatusWithoutName<T> | ReduxStateStatusWithName<T>;

export function createStateStatus<T>(name?: string): ReduxStateStatus<T> {
  if (name) {
    return {
      [`${name}FetchingStatus`]: {
        isDone: false,
        isFetching: false,
        isError: null
      }
    };
  }
  return {
    isDone: false,
    isFetching: false,
    isError: null
  };
}
