import { ApiResponse, create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import paramsSerializer from 'utils/paramsSerializer';
import { ServerResponseWithError } from 'types/global';
import { Events, PaymentEvent } from './types';
import { APP_CONFIG } from '../../config';
import { getEventsActionPayloadType, getPaymentEventsActionPayloadType } from './slice/types';

const eventsApi = create({
  baseURL: APP_CONFIG.baseApiNsiURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});
const eventsUssApi = create({
  baseURL: APP_CONFIG.baseApiUssURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getEventsApi = async (data: getEventsActionPayloadType) => {
  console.log('first')
  debugger
  const response: ApiResponse<Events[], ServerResponseWithError> = await eventsApi.get('/events', data);
  if (response.ok) {
    return response.data;
  }
  throw new Error(
    response?.data?.message ||
      response?.data?.exceptionMessage ||
      response?.data?.error?.problem ||
      'Some problem with api'
  );
};

export const getPaymentEventsBuyerApi = async (data: getPaymentEventsActionPayloadType) => {
  const response: ApiResponse<PaymentEvent[], ServerResponseWithError> = await eventsUssApi.get(
    `buyer/orders/dashboards/client-calendar-movement-funds`,
    data
  );
  return response.data;
};
export const getPaymentEventsProviderApi = async (data: getPaymentEventsActionPayloadType) => {
  const response: ApiResponse<PaymentEvent[], ServerResponseWithError> = await eventsUssApi.get(
    `provider/orders/dashboards/client-calendar-movement-funds`,
    data
  );
  return response.data;
};

eventsApi.addAsyncRequestTransform(requestTransform);
eventsApi.addAsyncResponseTransform(responseTransform);
eventsUssApi.addAsyncRequestTransform(requestTransform);
eventsUssApi.addAsyncResponseTransform(responseTransform);
