/*
 *
 * Roles actions
 *
 */

import { SET_ROLES_DATA } from './constants';

export function setRolesDataAction(rolesData) {
  return {
    type: SET_ROLES_DATA,
    payload: rolesData
  };
}
