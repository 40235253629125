/**
 *
 * Scroll
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import './style.less';

export function Scroll({ children, maxHeight, ...restProps }) {
  return (
    <div data-testid="Scroll" className="Scroll">
      <SimpleBar style={{ maxHeight: maxHeight || 300 }} {...restProps}>
        {children}
      </SimpleBar>
    </div>
  );
}

Scroll.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(Scroll);
