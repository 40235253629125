import { create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import { ServerResponseWithError } from 'types/global';
import { ROLE_NAMES_ON_SERVER } from 'containers/Roles/constants';
import paramsSerializer from 'utils/paramsSerializer';
import { APP_CONFIG } from '../../config';
import { ChangePasswordArgs } from './types';

export const settingsApi = create({
  baseURL: APP_CONFIG.baseApiNsiURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const customSettingsApi = create({
  baseURL: APP_CONFIG.baseApiUssURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getSettingsApi = async () => {
  const response = await settingsApi.get<unknown, ServerResponseWithError>('/settings');
  if (response.ok) {
    return response.data;
  }
  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

export const patchPasswordApi = async (data: ChangePasswordArgs) => {
  const response = await customSettingsApi.put<unknown, ServerResponseWithError>(`/common/users`, data);
  if (response.ok) {
    return response.data;
  }
  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

export const putBaseRoleApi = async (baseRole: ROLE_NAMES_ON_SERVER) => {
  const response = await customSettingsApi.put<unknown, ServerResponseWithError>(
    `/common/clients/base-role/${baseRole}`
  );
  if (response.ok) {
    return response.data;
  }
  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

settingsApi.addAsyncRequestTransform(requestTransform);
settingsApi.addAsyncResponseTransform(responseTransform);

customSettingsApi.addAsyncRequestTransform(requestTransform);
customSettingsApi.addAsyncResponseTransform(responseTransform);
