import { create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import paramsSerializer from 'utils/paramsSerializer';
import { APP_CONFIG } from '../../config';

export const accountsApi = create({
  baseURL: APP_CONFIG.baseApiNsiURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});
export const accountsCustomApi = create({
  baseURL: APP_CONFIG.baseApiUssURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

const getElementApi = async (elementId) => {
  const response = await accountsApi.get(`/elements/${elementId}`);
  return response.data;
};

export const getAccountsApi = async (accountIds = []) => {
  const responses = await Promise.all(accountIds?.map(getElementApi));
  return responses;
};

export const getAccountApi = async (accountId) => {
  const response = await getElementApi(accountId);
  return response;
};

export const patchAccountBaseRoleApi = async (accountBaseRole) => {
  const response = await accountsCustomApi.put(`/common/clients/base-role/${accountBaseRole}`);
  if (response.ok) {
    return response.data;
  }
  throw new Error(
    response?.data?.message || response?.data?.exceptionMessage || response?.data?.error?.problem || response.problem
  );
};

accountsApi.addAsyncRequestTransform(requestTransform);
accountsApi.addAsyncResponseTransform(responseTransform);
accountsCustomApi.addAsyncRequestTransform(requestTransform);
accountsCustomApi.addAsyncResponseTransform(responseTransform);
