import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.less';

const AuthRoute = ({ component: Component, isAuthorized, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthorized ? <Component {...rest} {...props} isAuthorized={isAuthorized} /> : <Redirect to="/" />
    }
  />
);

AuthRoute.propTypes = {
  component: PropTypes.any,
  isAuthorized: PropTypes.bool
};

export default AuthRoute;
