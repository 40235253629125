/* eslint-disable no-param-reassign */

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { createStateStatus } from 'utils/stateStatus';
import { ProductsState, getProductsActionPayloadType } from './types';
import { Products } from '../types';

export const initialState: ProductsState = {
  products: {
    ...createStateStatus<Products[]>()
  }
  // [INSERT NEW ENTITY IN INITIAL STATE KEY ABOVE] < Needed for generating actions seamlessly
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProductsAction(state, action: PayloadAction<getProductsActionPayloadType>) {
      state.products.isFetching = true;
      state.products.isError = null;
    },
    getProductsSuccessAction(state, action: PayloadAction<Products[]>) {
      state.products.isFetching = false;
      state.products.isDone = true;
      state.products.data = action.payload;
    },
    getProductsErrorAction(state, action: PayloadAction<Error>) {
      state.products.isFetching = false;
      state.products.isDone = true;
      state.products.isError = action.payload;
    },
    resetProductsStatusAction(state) {
      state.products.isFetching = false;
      state.products.isDone = false;
      state.products.isError = null;
      state.products.data = undefined;
    }
    // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating actions seamlessly
  }
});

export const { actions: productsActions, reducer, name } = slice;
