import { create } from 'apisauce';
import { requestTransform } from 'utils/requestTransform';
import { responseTransform } from 'utils/responseTransform';
import paramsSerializer from 'utils/paramsSerializer';
import { APP_CONFIG } from '../../config';

export const statusesApi = create({
  baseURL: APP_CONFIG.baseApiNsiURL,
  headers: { Accept: 'application/vnd.github.v3+json' },
  paramsSerializer
});

export const getStatusesApi = async () => {
  const response = await statusesApi.get('/elements', { categories: 'status' });
  return response.data.content.reduce((acc, next) => {
    const { id } = next;
    const values = next.payload?.status;
    return {
      ...acc,
      [id]: values
    };
  }, {});
};

statusesApi.addAsyncRequestTransform(requestTransform);
statusesApi.addAsyncResponseTransform(responseTransform);
