import React from 'react';
import PropTypes from 'prop-types';

function BurgerIcon({ className, onClick, fill = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
      className={className ? `BurgerIcon ${className}` : 'BurgerIcon'}
    >
      <path
        fill={fill}
        d="M18 13H6a1 1 0 010-2h12a1 1 0 010 2zm0-6H6a1 1 0 010-2h12a1 1 0 110 2zm0 12H6a1 1 0 010-2h12a1 1 0 010 2z"
      />
    </svg>
  );
}

BurgerIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func
};

export default React.memo(BurgerIcon);
