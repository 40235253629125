import React, { useEffect, useState } from 'react';

import { downloadImg } from 'utils/attachment';
import './style.less';
import classNames from 'classnames';
export function ProductImage({ id, type }) {
  const [imgSrc, setImgSrc] = useState('');

  const fetchImage = async () => {
    const url = await downloadImg(id, 'picture.png');

    setImgSrc(url);
  };

  useEffect(() => {
    id ? fetchImage() : setImgSrc('');
  }, [id]);

  const abscentCN = classNames({
    'ProductCard__logo-abscent': type === 'icon',
    'ProductCard__picture-abscent': type === 'picture'
  });

  const imgCN = classNames({
    'ProductCard__logo-img': type === 'icon',
    'ProductCard__picture-img': type === 'picture'
  });

  return (
    <div className="ProductIcon">
      <div className="ProductIcon__box">
        {imgSrc ? <img key={imgSrc} className={imgCN} src={imgSrc} /> : <div className={abscentCN}></div>}
      </div>
    </div>
  );
}
