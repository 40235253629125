/**
 *
 * Settings
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Box, Flex, Text } from 'theme-ui';
import Input from 'app/components/Input';
import WithLoader from 'app/components/WithLoader';
import { YUP } from 'app/components/YupMessages/constants';
import Button from 'app/components/Button';
import { StateItem } from 'types/redux/state';
import { SettingsFormNames } from './constants';
import { makeSelectSettings } from './selectors';
import { patchPasswordAction, updateBaseRoleAction } from './actions';
import { ChangePasswordArgs } from './types';
import './style.less';

const initialValues = {
  [SettingsFormNames.NewPassword]: '',
  [SettingsFormNames.Confirmation]: ''
};

const validationSchema = Yup.object({
  [SettingsFormNames.NewPassword]: Yup.string().required(YUP.REQUIRED_FIELD).min(6, YUP.MIN_CHAR_LIMIT),
  [SettingsFormNames.Confirmation]: Yup.string()
    .min(6, YUP.MIN_CHAR_LIMIT)
    .required(YUP.REQUIRED_FIELD)
    .oneOf([Yup.ref(SettingsFormNames.NewPassword), null], 'Пароли должны совпадать')
});

interface SettingsPageProps {
  settingsState: StateItem;
  patchPassword: (values: ChangePasswordArgs) => void;
}

export function SettingsPage(props: SettingsPageProps) {
  const { settingsState, patchPassword } = props;
  return (
    <div data-testid="SettingsPage" className="SettingsPage">
      <WithLoader states={[settingsState]}>
        <Box mb={7}>
          <Text variant="pageTitle">Настройки пользователя</Text>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            resetForm();
            console.log(values);
            return patchPassword({ password: values.newPassword });
          }}
        >
          {() => (
            <Form className="PasswordForm" autoComplete="off">
              <Box mb={7}>
                <Field
                  name={SettingsFormNames.NewPassword}
                  id={SettingsFormNames.NewPassword}
                  component={Input}
                  // type="password"
                  label="Новый пароль"
                />
              </Box>
              <Box mb={7}>
                <Field
                  name={SettingsFormNames.Confirmation}
                  id={SettingsFormNames.Confirmation}
                  component={Input}
                  // type="password"
                  label="Подтвердите новый пароль"
                />
              </Box>
              <Flex mt={7}>
                <Button text="Изменить пароль" type="submit" data-testid="submit" />
              </Flex>
            </Form>
          )}
        </Formik>
      </WithLoader>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  settingsState: makeSelectSettings()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    patchPassword: (values) => dispatch(patchPasswordAction(values)),
    updateBaseRole: (baseRole) => dispatch(updateBaseRoleAction(baseRole))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SettingsPage);
