import { create } from 'apisauce';

import { APP_CONFIG } from 'app/config';
import paramsSerializer from 'utils/paramsSerializer';
import { toast } from 'react-toastify';
import { requestTransform } from './requestTransform';
import { responseTransform } from './responseTransform';

const attachmentApi = create({
  baseURL: APP_CONFIG.baseApiNsiURL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

export const attachmentUssApi = create({
  baseURL: APP_CONFIG.baseApiUssURL,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  paramsSerializer
});

// Получить индексы ргуппы файлов через регистрацию на сервере
export const getFilesIndexes = async (files) => {
  if (!Array.isArray(files)) {
    throw new Error(`Wrong type. Expected an array`);
  }
  const filesPromises = files.map((file) => {
    const form = new FormData();
    form.append('file', file);
    return attachmentApi.post('/files', form);
  });
  const response = await Promise.all(filesPromises);
  return response.map((el) => el.data.key);
};

export const uploadApi = async (file) => {
  const form = new FormData();
  form.append('file', file);
  const response = await attachmentApi.post('/attachments', form);
  if (response.problem) throw new Error(response.data.exceptionMessage || response.data.trace);
  return response.data;
};

export const startDownload = (response, name) => {
  const contentDispositionHeader = decodeURIComponent(response.headers['content-disposition']);
  let headerLine;

  if (contentDispositionHeader !== 'undefined') {
    if (contentDispositionHeader.includes('UTF-8')) {
      // eslint-disable-next-line prefer-destructuring
      headerLine = contentDispositionHeader?.split(`''`)[1];
    } else {
      headerLine = contentDispositionHeader?.split('filename=')[1].split(';')[0].replaceAll('"', '');
    }
  }

  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', headerLine || name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const extractImageUrl = (response, name) => {
  const contentDispositionHeader = decodeURIComponent(response.headers['content-disposition']);
  let headerLine;

  if (contentDispositionHeader !== 'undefined') {
    if (contentDispositionHeader.includes('UTF-8')) {
      // eslint-disable-next-line prefer-destructuring
      headerLine = contentDispositionHeader?.split(`''`)[1];
    } else {
      headerLine = contentDispositionHeader?.split('filename=')[1].split(';')[0].replaceAll('"', '');
    }
  }

  const url = window.URL.createObjectURL(response.data);

  return url;
};

export const downloadImg = async (key, name = 'test.txt') => {
  if (!key) {
    toast.error('Файл не найден');
    return;
  }
  return attachmentApi
    .get(`/files/${key}`, undefined, { responseType: 'blob' })
    .then((response) => extractImageUrl(response, name))
    .catch((error) => {
      toast.error(error.message);
    });
};

export const downloadApi = async (id, name = 'test.txt') =>
  attachmentApi
    .get(`/attachments/${id}`, undefined, { responseType: 'blob' })
    .then((response) => startDownload(response, name));

export const downloadFile = async (key, name = 'test.txt') => {
  if (!key) {
    toast.error('Файл не найден');
    return;
  }
  return attachmentApi
    .get(`/files/${key}`, undefined, { responseType: 'blob' })
    .then((response) => startDownload(response, name))
    .catch((error) => {
      toast.error(error.message);
    });
};

export const downloadAsArrayBuffer = async (id) =>
  attachmentApi
    .get(`/attachments/${id}`, undefined, { responseType: 'blob' })
    .then((response) => response.data.arrayBuffer());

export const downloadReports = async ({
  reportFormat = 'xlsx',
  reportType = 'specification',
  subjectIds,
  name = 'test.txt'
}) => {
  if (!subjectIds?.length) {
    return;
  }
  toast.success('Файлы формируются. Это может занять некоторое время, просьба подождать', { autoClose: 5000 });
  attachmentUssApi
    .get(
      `/common/reports`,
      {
        reportFormat,
        reportType,
        subjectIds
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      startDownload(response, name);
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

attachmentApi.addAsyncRequestTransform(requestTransform);
attachmentApi.addAsyncResponseTransform(responseTransform);

attachmentUssApi.addAsyncRequestTransform(requestTransform);
attachmentUssApi.addAsyncResponseTransform(responseTransform);
