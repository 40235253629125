/**
 *
 * Input
 *
 */

import classnames from 'classnames';
import React, { useCallback } from 'react';
import { Box, Label, Input as NativeInput } from 'theme-ui';
import PropTypes from 'prop-types';

import './style.less';
import { get } from 'lodash';
import ControlFieldError from '../ControlFieldError';

function Input(props) {
  const { name, label, large, error, onChange, testid, innerRef, value, bgWhite, customChangeHandler, ...rest } = props;
  const { form, field } = props; // Formik props
  const isFormixField = !!(form && field);

  const actualName = isFormixField ? field.name : name;
  const formixError = isFormixField ? get(form.touched, actualName) && get(form.errors, actualName) : undefined;
  const actualError = isFormixField ? formixError : error;
  const actualValue = isFormixField ? get(form.values, actualName) : value;

  const inputClasses = classnames({
    Input__input: true,
    large: !!large,
    error: !!error,
    bgWhite: !!bgWhite
  });

  const handleChange = useCallback(
    (e) => {
      if (isFormixField) {
        form.setFieldValue(field.name, e.target.value);
        customChangeHandler && customChangeHandler(e.target.value);
      } else {
        onChange(e);
      }
    },
    [form, field, onChange]
  );

  return (
    <Box className="Input" data-testid="Input">
      {label && (
        <Label htmlFor={actualName} className="Input__label">
          {label}
        </Label>
      )}
      <NativeInput
        {...rest}
        id={testid}
        value={actualValue}
        className={inputClasses}
        ref={innerRef}
        onChange={handleChange}
        autoComplete="off"
      />
      {actualError && (
        <div className="Input__error">
          <ControlFieldError text={actualError} />
        </div>
      )}
    </Box>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  testid: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.node,
  large: PropTypes.bool,
  bgWhite: PropTypes.bool,
  onChange: PropTypes.func,
  customChangeHandler: PropTypes.func,
  onBlur: PropTypes.func,
  form: PropTypes.object,
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool
};

export default Input;
