import { configureStore, getDefaultMiddleware, StoreEnhancer } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import history from 'utils/history';

import { createReducer } from './reducers';

export let store;
export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga
    })
  ] as StoreEnhancer[];
  const innerStore = configureStore({
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware({ serializableCheck: false }), ...middlewares],
    devTools:
      /* istanbul ignore next line */
      import.meta.env.MODE !== 'production',
    enhancers
  });

  store = innerStore;

  return store;
}
