import React from 'react';

function ArrowDownBlueIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#0F62FE"
        d="M8 11.499a.663.663 0 01-.48-.205L3.353 6.961a.667.667 0 11.96-.924L8 9.87l3.685-3.833a.667.667 0 01.962.924L8.48 11.294A.667.667 0 018 11.5z"
      />
    </svg>
  );
}

export default ArrowDownBlueIcon;
