/* eslint-disable default-param-last */
import { createSelector } from 'reselect';
import { memoize } from 'lodash';
import { CONTAINER_KEY as loginPageContainerKey } from 'app/containers/LoginPage/constants';
import { CONTAINER_KEY as accountsPageContainerKey } from 'app/containers/AccountsPage/constants';
import {
  CONTAINER_KEY as rolesPageContainerKey,
  ROLE_NAMES,
  // ROLES_CODES,
  PERMISSION_CODES,
  PERMISSION_NAMES
} from 'app/containers/Roles/constants';
import { initialState } from './reducer';

const selectRolesDomain = (state) => state || initialState;

// const findRole = (data, code) => data?.find((client) => client?.roles?.find((role) => role?.code === code));
const findPermission = (data, code) =>
  data?.find((client) =>
    client?.roles?.find((role) => role?.permissions?.find((permission) => permission.code === code))
  );

export const computePermissions = (rolesInfo, userPermissions = [], accountId) => {
  const result = [...userPermissions];
  // if (isTestEnv) {
  //   result.push(ROLE_NAMES.TEST);
  // }
  const rolesData = rolesInfo?.roles?.rolesData?.filter((role) => role.client.externalId === accountId);

  // if (findRole(rolesData, ROLES_CODES.UM_ADMIN)) {
  //   result.push(ROLE_NAMES.UM_ADMIN);
  // }

  return result;
};

// const canWorkWithTenders = (permissions) => permissions?.includes(PERMISSION_NAMES.TENDER);

const findRoleByProduct = (role) => role.products.find((product) => product.code);
const createResultFunc = (
  rolesInfo = { roles: { rolesData: null, productsData: {} } },
  permissions = [],
  user,
  account
) => {
  const rolesData = rolesInfo?.roles?.rolesData;
  const roles = rolesData && rolesData?.map((data) => data.roles)?.flat();
  const isUserSuperAdmin = !roles?.length && !!user?.clientIds?.length;
  const isAppRestrictedByRoles = false;
  const noAccounts = !roles?.length && !user?.clientIds?.length;
  const isPlatformOwner = account?.payload?.client?.isPlatformOwner;
  const rolesForAccount = rolesData
    ?.filter((role) => role?.client?.externalId === account?.id)
    ?.map((data) => data?.roles)
    ?.flat()
    ?.filter(findRoleByProduct);
  const result = {
    roles: rolesData,
    rolesForAccount,
    isPlatformOwner,
    isAppRestrictedByRoles,
    isUserSuperAdmin,
    noAccounts,
    rights: {
      // canSeeTenderList: isUserSuperAdmin || canWorkWithTenders(permissions),
    }
  };
  return result;
};

const createResult = memoize(
  createResultFunc,
  (data, __, user, account) =>
    `${user?.email} ${data?.roles?.rolesData?.map((role) => role.roles.map((role) => role.id).join(' ')).join(' ')} ${
      account?.id
    }`
);
const createRole = memoize(
  computePermissions,
  (data, _, accountId) =>
    `${data?.roles?.rolesData?.map((role) => role.roles.map((role) => role.id).join(' ')).join(' ')} ${accountId}`
);

const makeSelectRoles = () =>
  createSelector(
    (state) => state[loginPageContainerKey] || {},
    (state) => state[accountsPageContainerKey] || {},
    (state) => state[rolesPageContainerKey] || {},
    (userSubstate, accountSubstate, rolesSubstate) => {
      const { user = {} } = userSubstate;
      const { account: { data: account } = {} } = accountSubstate;
      const rolesFromData = createRole(rolesSubstate, [], account?.id);
      const result = createResult(rolesSubstate, rolesFromData, user, account);
      return result;
    }
  );

const createUserSelector = (role) => () =>
  createSelector(
    (state) => state[rolesPageContainerKey] || {},
    () => {
      if (role === ROLE_NAMES.PROVIDER) {
        return true;
      }
    }
  );

const makeSelectIsBuyer = createUserSelector(ROLE_NAMES.BUYER);
const makeSelectIsProvider = createUserSelector(ROLE_NAMES.PROVIDER);
const makeSelectIsSupplier = createUserSelector(ROLE_NAMES.SUPPLIER);

export default makeSelectRoles;
export { selectRolesDomain, makeSelectIsBuyer, makeSelectIsProvider, makeSelectIsSupplier };
