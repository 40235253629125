import React, { useCallback, useRef } from 'react';
import { logOut } from 'app/services/oauthservice';
import './style.less';
import { useInterval } from 'utils/hooks/useInterval';
import { getAccountId } from 'utils/accountId/accountIdManager';
import { keycloakManager } from 'app/services/keycloak';

export const useProtect = () => {
  const redirectToLogin = useCallback(() => {
    logOut();
  }, []);

  const fetching = useRef(false);
  const accountId = useRef(undefined);

  let interval;
  const [isAuthorized, setIsAuthorized] = React.useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTokenIfNotFetching = useCallback(async () => !fetching.current && checkToken(), []);

  useInterval(checkTokenIfNotFetching, 1000);
  React.useEffect(() => {
    checkTokenIfNotFetching();
    window.addEventListener('logout', redirectToLogin, false);
    return () => {
      window.removeEventListener('logout', redirectToLogin);
      clearInterval(interval);
    };
  }, [checkTokenIfNotFetching, interval, redirectToLogin]);

  const checkToken = useCallback(async () => {
    try {
      fetching.current = true;
      accountId.current = await getAccountId();
    } catch (error) {
      setIsAuthorized(false);
      fetching.current = false;
    }
    if (!accountId.current || !keycloakManager.authenticated) {
      setIsAuthorized(false);
    } else {
      setIsAuthorized(true);
    }
    fetching.current = false;
  }, []);

  if (!keycloakManager.authenticated) {
    return false;
  }

  return isAuthorized;
};
