/*
 *
 * BackNavigator reducer
 *
 */
import produce from 'immer';
import {
  SET_BACK_NAVIGATOR_NAME,
  SET_BACK_NAVIGATOR_URL,
  RESET_BACK_NAVIGATOR
} from './constants';

export const initialState = {
  name: '',
  url: ''
};

/* eslint-disable default-case, no-param-reassign */
const backNavigatorReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_BACK_NAVIGATOR_NAME:
        draft.name = action.payload.name;
        break;
      case SET_BACK_NAVIGATOR_URL:
        draft.url = action.payload.url;
        break;
      case RESET_BACK_NAVIGATOR:
        draft.name = '';
        draft.url = '';
        break;
    }
  });

export default backNavigatorReducer;
